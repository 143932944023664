import React from "react";

interface IMenuMapSVG {
  color: string;
}

const MenuMapSVG = ({ color }: IMenuMapSVG) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.5 13.125V12.1469C27.5 9.72234 27.5 8.51009 26.7677 7.75688C26.0355 7.00368 24.857 7.00368 22.5 7.00368H19.9018C18.755 7.00368 18.7455 7.00145 17.7144 6.48543L13.5499 4.40142C11.8111 3.5313 10.9416 3.09624 10.0155 3.12647C9.08929 3.15671 8.24846 3.6476 6.56681 4.62939L5.03197 5.52546C3.79674 6.24661 3.17911 6.6072 2.83955 7.20705C2.5 7.80691 2.5 8.53741 2.5 9.99841V20.2696C2.5 22.1893 2.5 23.1491 2.92782 23.6834C3.21251 24.0387 3.61145 24.2777 4.0525 24.357C4.71533 24.476 5.52685 24.0023 7.14984 23.0546C8.25195 22.4113 9.31264 21.743 10.6311 21.9243C11.7359 22.076 12.7625 22.7731 13.75 23.2673"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 3.125V21.875" stroke={color} strokeWidth="1.5" strokeLinejoin="round" />
      <path d="M18.75 6.875V11.875" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M21.875 15C24.9191 15 27.5 17.5206 27.5 20.5786C27.5 23.6853 24.8771 25.8655 22.4544 27.3479C22.2779 27.4476 22.0781 27.5 21.875 27.5C21.6719 27.5 21.4721 27.4476 21.2956 27.3479C18.8774 25.851 16.25 23.696 16.25 20.5786C16.25 17.5206 18.8309 15 21.875 15Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M21.875 20.625H21.8858" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default MenuMapSVG;

import React from 'react';
import { CONFIG } from '../config';

const ContactsData = () => {
  return (
    <ul className="">
      <li>
        {/* {"Адрес: "} */}
        <a href={CONFIG.CONTACTS.ADDRESS.LINK} className="" target="_blank">
          {CONFIG.CONTACTS.ADDRESS.TEXT}
        </a>
      </li>
      <li>
        {/* {"Телефон: "} */}
        <a href={CONFIG.CONTACTS.PHONE.LINK} className="" target="_blank">
          {CONFIG.CONTACTS.PHONE.TEXT}
        </a>
      </li>
      <li className="pt-3">
        {/* {"Email: "} */}
        <a href={CONFIG.CONTACTS.EMAIL.LINK} className="pt-3" target="_blank">
          {CONFIG.CONTACTS.EMAIL.TEXT}
        </a>
      </li>
      {/* <li>
        <a href={CONFIG.CONTACTS.WEBSITE.LINK} className="" target="_blank">
          {CONFIG.CONTACTS.WEBSITE.TEXT}
        </a>
      </li> */}
    </ul>
  );
};

export default ContactsData;

import React from "react";

const PersonsSVG = () => {
  return (
    <svg width="116" height="158" viewBox="0 0 116 158" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M89.2755 34.3731C89.2755 51.7 75.229 65.7462 57.9024 65.7462C40.5755 65.7462 26.5293 51.7 26.5293 34.3731C26.5293 17.0462 40.5755 3 57.9024 3C75.229 3 89.2755 17.0462 89.2755 34.3731Z"
        fill="white"
        stroke="#122A1D"
        strokeWidth="6"
      />
      <path
        d="M73.6651 81.0898H42.2584C20.5766 81.0898 3 104.726 3 133.883C3 145.546 10.0306 155 18.7034 155H97.2202C105.893 155 112.924 145.546 112.924 133.883C112.924 104.726 95.3468 81.0898 73.6651 81.0898Z"
        fill="white"
        stroke="#122A1D"
        strokeWidth="6"
        strokeLinejoin="round"
      />
      <path
        d="M42.8928 104.732C45.0286 102.594 47.9258 101.392 50.9477 101.39C53.9696 101.387 56.8689 102.584 59.0084 104.718C60.0591 103.627 61.3167 102.757 62.7077 102.157C64.0986 101.558 65.5951 101.242 67.1096 101.227C68.6242 101.213 70.1265 101.5 71.5287 102.073C72.931 102.645 74.2051 103.491 75.2766 104.562C76.3481 105.632 77.1956 106.906 77.7695 108.307C78.3434 109.709 78.6322 111.211 78.6191 112.725C78.606 114.24 78.2912 115.737 77.6932 117.128C77.0951 118.52 76.2258 119.778 75.1359 120.83L59.0301 136.954L42.9026 120.842C41.8416 119.786 40.9994 118.531 40.4245 117.149C39.8495 115.768 39.5531 114.286 39.5522 112.789C39.5513 111.292 39.8459 109.81 40.4192 108.428C40.9924 107.045 41.833 105.789 42.8928 104.732Z"
        fill="#CEF0D8"
      />
    </svg>
  );
};

export default PersonsSVG;

import React from "react";
import { useData } from "../../../hooks/useContext";
import { IUseData } from "../../../interfaces";

interface IMenuOrganizationsSVG {
  color: string;
}

const MenuOrganizationsSVG = ({ color }: IMenuOrganizationsSVG) => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.916 27.5L11.6027 23.1139C11.4342 20.7558 13.3019 18.75 15.6659 18.75C18.03 18.75 19.8977 20.7558 19.7293 23.1139L19.4159 27.5"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M3.60599 16.5169C3.16471 13.6452 2.94407 12.2095 3.48696 10.9367C4.02984 9.6639 5.23429 8.79304 7.64317 7.05132L9.44299 5.75C12.4396 3.58334 13.938 2.5 15.6667 2.5C17.3956 2.5 18.8938 3.58334 21.8905 5.75L23.6904 7.05132C26.0992 8.79304 27.3037 9.6639 27.8466 10.9367C28.3895 12.2095 28.1689 13.6452 27.7275 16.5169L27.3512 18.9655C26.7257 23.0361 26.4129 25.0715 24.953 26.2858C23.4931 27.5 21.3588 27.5 17.0902 27.5H14.2432C9.97467 27.5 7.84039 27.5 6.3805 26.2858C4.92061 25.0715 4.60784 23.0361 3.98229 18.9655L3.60599 16.5169Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MenuOrganizationsSVG;

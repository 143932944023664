import React, { useState, useEffect } from 'react';
import MainLayout from './mainLayout';
import PointerItem from '../components/pointerItem';
import { useData } from '../hooks/useContext';
import Pagination from '../components/pagination';
import { paginate } from '../utils/paginate';
import Loader from '../components/loader';
import { ILocation, IUseData } from '../interfaces';
import PageCard from '../components/pageCard';

const LocationsList = () => {
  const { setPage, locations, theme } = useData() as IUseData;
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const pageSize: number = 16;

  useEffect(() => {
    setPage('locations');
    document.title = 'Адреса';
  }, []);

  return (
    <MainLayout>
      {/* <div className="m-auto w-full 2xl:w-4/5 h-100 md:px-12 flex flex-col px-2"> */}
      <PageCard />
      <main className={'grow flex flex-col rounded-[40px] p-[40px] my-5' + theme.active}>
        {locations ? (
          <>
            <h2 className={'text-2xl font-medium mb-2 border-b-2' + theme.redBorder} autoFocus={true}>
              {'Адреса (' + locations.length + ')'}
            </h2>
            <ul>
              {paginate<ILocation>(locations, paginationPage, pageSize).map((item: ILocation) => (
                <PointerItem
                  key={item._id}
                  name={item.adress}
                  link={'locations/' + item._id}
                  num={locations.indexOf(item) + 1}
                />
              ))}
            </ul>
            <Pagination
              productCount={locations.length}
              pageSize={pageSize}
              currentPage={paginationPage}
              onPageChange={(page: number) => setPaginationPage(page)}
            />
          </>
        ) : (
          <Loader title={'Загрузка списка адресов...'} />
        )}
      </main>
      {/* </div> */}
    </MainLayout>
  );
};

export default LocationsList;

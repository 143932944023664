import React from 'react';
import NavbarMenu from './navbarMenu';
import StylesMenu from '../stylesMenu';
import Logo from '../SVG/logo';
import { useHistory } from 'react-router-dom';
import { CONFIG } from '../../config';

const Header = () => {
  const history = useHistory();
  return (
    <header className="sm:m-auto p-14 flex flex-col 2xl:w-4/5  bg-[#E8E8E8] rounded-b-[40px]">
      {/* <StylesMenu /> */}
      <div className="flex justify-center md:justify-between">
        <div
          className="flex flex-col md:text-5xl text-4xl self-start text-start hover:cursor-pointer wrap"
          onClick={() => history.push('/')}
        >
          <div>{CONFIG.MAIN_PAGE.HEADER1}</div>
          <div>{CONFIG.MAIN_PAGE.HEADER2}</div>
        </div>
        {/* <StylesMenu /> */}
        <div className="md:block hidden w-1/4 hover:cursor-pointer self-center" onClick={() => history.push('/')}>
          <Logo />
        </div>
      </div>
      <NavbarMenu />
    </header>
  );
};

export default Header;

import React from 'react';
import ContactsData from './contactsData';
import { Link } from 'react-router-dom';
import { useData } from '../hooks/useContext';
import { CONFIG } from '../config';
import { IUseData } from '../interfaces';

const Footer = () => {
  const { setPage, theme } = useData() as IUseData;

  return (
    <footer className="mt-6 p-14 2xl:mx-auto 2xl:flex-row 2xl:w-4/5 bg-[#E8E8E8] rounded-t-[40px]">
      <div className={'flex flex-col'}>
        <div className="flex flex-row justify-between flex-wrap">
          {/* <nav className="flex flex-col me-10">
            <h4 className={"text-lg font-semibold border-b-2 w-fit" + theme.redBorder}>Разделы:</h4>
            <Link to="/map" onClick={() => setPage("map")}>
              Карта
            </Link>
            <Link to="/organizations" onClick={() => setPage("organizations")}>
              Организации
            </Link>
            {CONFIG.SHOW_PERSONS ? (
              <Link to="/persons" onClick={() => setPage("persons")}>
                Люди
              </Link>
            ) : null}
            <Link to="/about" onClick={() => setPage("about")}>
              О проекте
            </Link>
          </nav> */}
          <div className="flex flex-col">
            <h4 className={'text-3xl font-medium w-fit pb-5'}>Контакты:</h4>
            <ContactsData />
          </div>
          <div className="flex items-center self-end mt-5">
            <div className="">{'© СПб ГБУК ГСЦБС, 2012-' + new Date().getFullYear() + ' гг.'} </div>
            <div className="ml-6 border border-black w-12 h-12 text-center text-xl rounded-full p-2">12+</div>
          </div>
        </div>

        {/* <div className="flex w-full mt-5">{"© СПб ГБУК ГСЦБС, 2012-" + new Date().getFullYear() + " гг."}</div> */}
      </div>
    </footer>
  );
};

export default Footer;

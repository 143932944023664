import React from "react";

const AboutPersonsSVG = () => {
  return (
    <svg width="146" height="169" viewBox="0 0 146 169" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15_3368)">
        <path
          d="M109.157 5.63308V146.604C109.155 147.593 108.762 148.541 108.063 149.24C107.364 149.939 106.416 150.332 105.428 150.334H17.8152C16.305 150.341 14.8031 150.11 13.3649 149.649C12.9086 149.497 12.4128 149.306 11.9565 149.117C11.9199 149.154 11.9199 149.154 11.9199 149.117C8.27144 147.563 5.36553 144.658 3.81073 141.011C3.77411 141.011 3.77411 141.011 3.81073 140.971C3.62201 140.515 3.43048 140.019 3.27838 139.563C2.81742 138.124 2.58643 136.621 2.59393 135.11V14.3112C2.59393 11.0211 3.90075 7.86561 6.22698 5.53885C8.55321 3.21209 11.7084 1.90456 14.9985 1.90381H105.428C106.416 1.9053 107.364 2.29868 108.063 2.99773C108.762 3.69678 109.155 4.64447 109.157 5.63308Z"
          fill="white"
        />
        <path
          d="M105.427 152.238H17.8149C16.1048 152.246 14.4042 151.983 12.7759 151.461C12.6576 151.421 12.5421 151.382 12.4238 151.337L12.4013 151.359L10.0156 150.323C6.8339 148.685 4.24312 146.094 2.60494 142.912H2.53734L1.53461 140.552L1.58531 140.498C1.54588 140.383 1.50363 140.27 1.46701 140.157C0.944417 138.526 0.682068 136.823 0.689611 135.11V14.3087C0.694084 10.5151 2.20304 6.87828 4.88546 4.19585C7.56789 1.51342 11.2048 0.00447262 14.9983 0L105.427 0C106.921 0 108.354 0.59351 109.411 1.64997C110.467 2.70642 111.061 4.13928 111.061 5.63333V146.602C111.061 147.342 110.916 148.075 110.633 148.759C110.35 149.442 109.935 150.064 109.412 150.587C108.889 151.11 108.267 151.526 107.584 151.809C106.9 152.092 106.167 152.238 105.427 152.238ZM12.1957 147.168L12.6999 147.376C13.1139 147.551 13.559 147.72 13.9702 147.858C15.2131 148.253 16.5108 148.449 17.8149 148.438H105.427C105.912 148.438 106.377 148.244 106.72 147.901C107.063 147.558 107.255 147.093 107.255 146.607V5.63333C107.255 5.14874 107.062 4.68421 106.719 4.34155C106.376 3.99889 105.912 3.80606 105.427 3.80532H14.9983C12.2135 3.8083 9.5437 4.91586 7.57458 6.88497C5.60547 8.85408 4.49791 11.5239 4.49493 14.3087V135.11C4.487 136.422 4.68664 137.727 5.08643 138.977C5.22163 139.377 5.39626 139.822 5.56526 140.245L5.76243 140.715C7.09057 143.544 9.36363 145.821 12.1901 147.154L12.1957 147.168Z"
          fill="#122A1D"
        />
        <path
          d="M27.3296 1.90381V135.11H2.59082V14.3112C2.59082 11.0206 3.89803 7.8647 6.22487 5.53786C8.55171 3.21101 11.7076 1.90381 14.9982 1.90381H27.3296Z"
          fill="white"
        />
        <path
          d="M29.2336 137.014H0.689453V14.3087C0.693926 10.5151 2.20288 6.87828 4.8853 4.19585C7.56773 1.51342 11.2046 0.00447262 14.9981 0L29.2336 0V137.014ZM4.49477 133.209H25.4282V3.80532H15.0066C12.2204 3.80606 9.54835 4.91263 7.57741 6.88199C5.60647 8.85134 4.49776 11.5225 4.49477 14.3087V133.209Z"
          fill="#122A1D"
        />
        <path
          d="M109.157 122.702V146.604C109.155 147.593 108.762 148.541 108.063 149.24C107.364 149.939 106.416 150.332 105.428 150.334H17.8152C16.305 150.341 14.8031 150.11 13.3649 149.649C12.9086 149.497 12.4128 149.306 11.9565 149.117C11.9199 149.153 11.9199 149.153 11.9199 149.117C8.25669 147.585 5.34361 144.673 3.81073 141.011C3.77411 141.011 3.77411 141.01 3.81073 140.971C3.62201 140.515 3.43048 140.019 3.27838 139.563C2.81742 138.124 2.58643 136.621 2.59393 135.11V134.997C2.61997 130.978 4.23549 127.133 7.08753 124.302C9.93958 121.47 13.7963 119.882 17.8152 119.886H106.34C107.087 119.886 107.804 120.182 108.332 120.71C108.86 121.239 109.157 121.955 109.157 122.702Z"
          fill="white"
        />
        <path
          d="M105.427 152.238H17.8149C16.1048 152.246 14.4042 151.983 12.7759 151.461C12.6576 151.421 12.5421 151.382 12.4238 151.337L12.4013 151.359L10.0156 150.331C6.82664 148.695 4.23092 146.1 2.59368 142.912H2.53734L1.53461 140.552L1.58531 140.498C1.54588 140.383 1.50363 140.27 1.46701 140.157C0.944417 138.526 0.682068 136.823 0.689611 135.11C0.685902 132.86 1.12633 130.631 1.98564 128.552C2.84496 126.473 4.10626 124.583 5.69723 122.992C7.28821 121.401 9.17756 120.14 11.257 119.281C13.3364 118.421 15.565 117.981 17.8149 117.985H106.343C107.594 117.987 108.793 118.485 109.677 119.37C110.562 120.255 111.059 121.454 111.061 122.705V146.602C111.061 147.342 110.916 148.075 110.633 148.759C110.35 149.442 109.935 150.064 109.412 150.587C108.889 151.111 108.267 151.526 107.584 151.809C106.9 152.092 106.167 152.238 105.427 152.238ZM12.0887 147.112L12.6942 147.362C13.1083 147.537 13.5533 147.706 13.9646 147.844C15.2085 148.245 16.5081 148.445 17.8149 148.438H105.427C105.912 148.438 106.377 148.244 106.72 147.901C107.063 147.558 107.255 147.093 107.255 146.608V122.705C107.257 122.585 107.234 122.465 107.189 122.354C107.144 122.242 107.077 122.141 106.992 122.056C106.907 121.97 106.805 121.903 106.694 121.857C106.583 121.812 106.463 121.789 106.343 121.79H17.8149C14.2972 121.785 10.9206 123.173 8.42427 125.652C5.92789 128.13 4.51504 131.497 4.49493 135.014C4.47981 136.358 4.67949 137.696 5.08643 138.977C5.22163 139.377 5.39626 139.822 5.56526 140.245L5.79904 140.808C7.10651 143.571 9.32834 145.798 12.0887 147.112Z"
          fill="#122A1D"
        />
        <path d="M88.2352 18.5674H48.0498V33.2817H88.2352V18.5674Z" fill="white" />
        <path d="M90.1352 35.1861H46.1445V16.6665H90.1352V35.1861Z" fill="#CEF0D8" />
        <path d="M51.9902 135.152V167.096L63.0428 156.043L74.0954 167.096V135.152H51.9902Z" fill="#CEF0D8" />
        <path
          d="M109.16 137.054H24.2117C23.7198 137.035 23.2543 136.826 22.9129 136.471C22.5715 136.116 22.3809 135.643 22.3809 135.151C22.3809 134.659 22.5715 134.185 22.9129 133.831C23.2543 133.476 23.7198 133.267 24.2117 133.248H109.16C109.652 133.267 110.117 133.476 110.458 133.831C110.8 134.185 110.99 134.659 110.99 135.151C110.99 135.643 110.8 136.116 110.458 136.471C110.117 136.826 109.652 137.035 109.16 137.054Z"
          fill="#122A1D"
        />
        <path
          d="M143.585 140.518L134.197 154.776L133.552 155.753L132.909 154.776L123.521 140.518L129.445 127.254H137.661L143.585 140.518Z"
          fill="#CEF0D8"
        />
        <path
          d="M133.549 157.964L121.899 140.27L128.519 125.449H138.588L145.205 140.27L133.549 157.964ZM125.141 140.008L133.552 152.779L141.963 140.008L136.735 128.299H130.369L125.141 140.008Z"
          fill="#122A1D"
        />
        <path
          d="M134.199 141.284V154.396L133.554 155.373L132.912 154.396V141.284C132.923 141.121 132.995 140.968 133.115 140.856C133.234 140.744 133.392 140.682 133.556 140.682C133.719 140.682 133.877 140.744 133.996 140.856C134.116 140.968 134.189 141.121 134.199 141.284Z"
          fill="#122A1D"
        />
        <path
          d="M133.552 141.864C134.627 141.864 135.498 140.993 135.498 139.918C135.498 138.843 134.627 137.971 133.552 137.971C132.477 137.971 131.605 138.843 131.605 139.918C131.605 140.993 132.477 141.864 133.552 141.864Z"
          fill="#122A1D"
        />
        <path
          d="M128.046 14.9453H139.062C139.939 14.9475 140.781 15.2978 141.4 15.9193C142.02 16.5408 142.368 17.3827 142.368 18.2605V20.2068H124.73V18.2605C124.73 17.3813 125.08 16.538 125.701 15.9163C126.323 15.2946 127.166 14.9453 128.046 14.9453Z"
          fill="white"
        />
        <path
          d="M139.132 127.068H127.973C126.734 127.067 125.547 126.574 124.671 125.698C123.796 124.822 123.303 123.634 123.303 122.396V18.7817H143.802V122.396C143.802 123.634 143.31 124.822 142.434 125.698C141.558 126.574 140.371 127.067 139.132 127.068Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.801 21.6348H143.802V18.2633C143.802 17.0058 143.302 15.7997 142.413 14.9103C141.524 14.0208 140.319 13.5208 139.061 13.52H128.042C126.786 13.5208 125.582 14.0197 124.693 14.9073C123.805 15.7949 123.304 16.9988 123.302 18.2548V18.7817H123.302V122.396C123.303 123.634 123.795 124.822 124.67 125.698C125.546 126.574 126.733 127.067 127.972 127.068H139.131C140.37 127.067 141.557 126.574 142.433 125.698C143.309 124.822 143.801 123.634 143.801 122.396V21.6348ZM140.96 18.7816H126.155V18.2605C126.156 17.7602 126.355 17.2806 126.709 16.9269C127.063 16.5731 127.542 16.374 128.042 16.3733H139.072C139.573 16.374 140.052 16.5731 140.406 16.9269C140.76 17.2806 140.959 17.7602 140.96 18.2605V18.7816ZM126.155 122.396V21.635H140.948V122.396C140.948 122.877 140.757 123.34 140.416 123.68C140.075 124.021 139.613 124.212 139.131 124.212H127.972C127.49 124.212 127.028 124.021 126.687 123.68C126.346 123.34 126.155 122.877 126.155 122.396Z"
          fill="#122A1D"
        />
        <path
          d="M133.513 1.90381H133.594C134.266 1.90344 134.93 2.03533 135.551 2.29193C136.171 2.54854 136.734 2.92484 137.209 3.39933C137.684 3.87382 138.061 4.43721 138.317 5.0573C138.574 5.67739 138.707 6.34202 138.707 7.01324V14.7394H128.403V7.01324C128.403 5.65814 128.942 4.35853 129.9 3.40033C130.858 2.44212 132.158 1.90381 133.513 1.90381Z"
          fill="white"
        />
        <path
          d="M140.131 16.1677H126.972V7.01356C126.974 5.2804 127.663 3.61886 128.889 2.39333C130.114 1.1678 131.776 0.47831 133.509 0.476074H133.594C135.327 0.47831 136.988 1.1678 138.214 2.39333C139.439 3.61886 140.129 5.2804 140.131 7.01356V16.1677ZM129.828 13.3144H137.275V7.01356C137.274 6.03739 136.885 5.10159 136.195 4.41107C135.505 3.72055 134.57 3.33159 133.594 3.32936H133.509C132.533 3.33159 131.597 3.72055 130.908 4.41107C130.218 5.10159 129.829 6.03739 129.828 7.01356V13.3144Z"
          fill="#122A1D"
        />
        <path
          d="M136.923 28.9467C136.923 27.0847 135.414 25.5752 133.553 25.5752C131.691 25.5752 130.183 27.0847 130.183 28.9467V57.3585C130.183 59.2205 131.691 60.73 133.553 60.73C135.414 60.73 136.923 59.2205 136.923 57.3585V28.9467Z"
          fill="white"
        />
        <path
          d="M133.552 62.1581C132.283 62.1544 131.067 61.6487 130.17 60.7516C129.273 59.8544 128.767 58.6386 128.764 57.3698V28.9468C128.764 27.6739 129.269 26.4531 130.169 25.553C131.07 24.6529 132.29 24.1472 133.563 24.1472C134.836 24.1472 136.057 24.6529 136.957 25.553C137.857 26.4531 138.363 27.6739 138.363 28.9468V57.3613C138.362 57.9922 138.237 58.6168 137.995 59.1994C137.753 59.7819 137.398 60.3111 136.951 60.7565C136.505 61.202 135.974 61.555 135.391 61.7955C134.808 62.036 134.183 62.1592 133.552 62.1581ZM133.552 27.0033C133.037 27.0041 132.543 27.2091 132.179 27.5734C131.814 27.9377 131.609 28.4316 131.608 28.9468V57.3613C131.608 57.8768 131.813 58.3711 132.178 58.7356C132.542 59.1001 133.037 59.3049 133.552 59.3049C134.067 59.3049 134.562 59.1001 134.926 58.7356C135.291 58.3711 135.496 57.8768 135.496 57.3613V28.9468C135.495 28.4316 135.29 27.9377 134.925 27.5734C134.561 27.2091 134.067 27.0041 133.552 27.0033Z"
          fill="#122A1D"
        />
      </g>
      <defs>
        <clipPath id="clip0_15_3368">
          <rect x="0.689453" width="144.515" height="169" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AboutPersonsSVG;

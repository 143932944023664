import React, { useEffect, useState } from 'react';
import MainLayout from './mainLayout';
import API from '../api';
import Loader from '../components/loader';
import { useData } from '../hooks/useContext';
import { RouteComponentProps, Link } from 'react-router-dom';
import { IPerson, IUseData } from '../interfaces';
import { CONFIG } from '../config';
import personsService from '../services/persons.service';
import ArrowRightSVG from '../components/SVG/arrowRightSVG';
import ArrowLeftSVG from '../components/SVG/arrowLeftSVG';
import MenuPersonsSVG from '../components/SVG/menuSVG/menuPersons';

const PersonPage = ({ match }: RouteComponentProps<{ personId: string }>) => {
  const [person, setPerson] = useState<IPerson>();
  const [activeImg, setActiveImg] = useState<string>();
  const { setPage, persons, theme } = useData() as IUseData;

  async function getOrg(id: string) {
    const persData = await personsService.getById(id);
    setPerson(persData);
    if (persData?.gallery && persData?.gallery.length !== 0) {
      setActiveImg(persData.gallery[0]);
    } else {
      setActiveImg('нет изображения$/IMG/no_img.jpg');
    }
    document.title = persData.name;
  }

  function getPerson(person: string): IPerson | undefined {
    for (let i in persons) {
      if (persons[i]._id === person) {
        return persons[i];
      }
    }
  }

  function changeImg(order: string) {
    if (order === 'asc' && person?.gallery?.indexOf(activeImg!)! < person?.gallery?.length! - 1) {
      setActiveImg(person?.gallery![person?.gallery?.indexOf(activeImg!)! + 1]);
    }
    if (order === 'desc' && person?.gallery?.indexOf(activeImg!)! > 0) {
      setActiveImg(person?.gallery![person?.gallery?.indexOf(activeImg!)! - 1]);
    }
  }

  useEffect(() => {
    setPerson(undefined);
    getOrg(match.params.personId);
  }, [match.params.personId]);

  useEffect(() => {
    setPage('persons');
  }, []);

  return (
    <MainLayout>
      {/* <div className="m-auto w-full 2xl:w-4/5 h-100 md:px-12 flex flex-col px-2"> */}
      {person ? (
        <>
          <div className="flex flex-col lg:flex-row my-10">
            {person.gallery ? (
              <div
                className={
                  'w-full sm:w-4/5 md:w-3/5 lg:w-1/3 md:me-10 flex flex-col h-fit self-center lg:self-start p-5 rounded-[40px]' +
                  theme.secondary
                }
              >
                <img
                  src={activeImg?.includes('$') ? activeImg.split('$')[1] : activeImg}
                  className=" w-full top-0 bottom-0 m-auto rounded-[20px]"
                  alt={activeImg?.includes('$') ? activeImg.split('$')[0] : 'Изображение ' + person.name}
                />
                {person.gallery.length !== 0 ? (
                  <div className="text-center rounded-[20px] bg-white mt-5 px-[10px] py-5">
                    {activeImg?.includes('$') ? activeImg.split('$')[0] : 'Изображение ' + person.name}
                  </div>
                ) : null}
                {person.gallery.length > 1 ? (
                  <div className="flex flex-row overflow-auto lg:justify-between h-fit self-center mt-5">
                    <button
                      className={
                        activeImg === person.gallery[0]
                          ? 'text-xl h-8 w-8 me-2 p-2 rounded self-center cursor-not-allowed' + theme.active
                          : 'text-xl h-8 w-8 me-2 p-2 rounded self-center' + theme.active
                      }
                      onClick={() => changeImg('desc')}
                      title="Предыдущее изображение"
                    >
                      <ArrowLeftSVG />
                    </button>
                    <div className="flex flex-row overflow-auto lg:justify-between h-fit self-center">
                      {person.gallery.map((img: string) => (
                        <img
                          key={img}
                          src={img?.includes('$') ? img.split('$')[1] : img}
                          className={
                            activeImg === img
                              ? 'h-28 w-36 mx-1 my-2 border-2 rounded-[10px]' + theme.redBorder
                              : 'h-28 w-36 mx-1 my-2  rounded-[10px]'
                          }
                          onClick={() => setActiveImg(img)}
                          alt={
                            img?.includes('$')
                              ? img.split('$')[0]
                              : 'Изображение ' + person.gallery?.indexOf(img) + ' ' + person.name
                          }
                        />
                      ))}
                    </div>
                    <button
                      className={
                        activeImg === person.gallery[person.gallery.length - 1]
                          ? 'text-xl h-8 w-8 ms-2 p-2 rounded bg-[#4765DA] self-center cursor-not-allowed'
                          : 'text-xl h-8 w-8 ms-2 p-2 rounded bg-[#4765DA] self-center'
                      }
                      onClick={() => changeImg('asc')}
                      title="Следующее изображение"
                    >
                      <ArrowRightSVG />
                    </button>
                  </div>
                ) : null}
              </div>
            ) : null}

            <main
              className={
                person.gallery
                  ? 'w-full lg:w-2/3 flex flex-col md:p-10 p-5 mt-5 md:mt-0 border-2 rounded-[40px] h-fit' +
                    theme.redBorder
                  : 'w-full flex flex-col border-2 mt-5 md:mt-0 rounded-[40px] h-fit' + theme.redBorder
              }
            >
              <h2 className={'w-fit text-3xl font-semibold' + theme.redBorder}>{person.name}</h2>
              {person.info ? (
                <div className="mt-10">
                  {CONFIG.PERSON_PAGE.INFO !== '' ? (
                    <h3 className={'w-fit border-b-2' + theme.redBorder2}>{CONFIG.PERSON_PAGE.INFO}</h3>
                  ) : null}
                  <div className="mt-3">
                    {person.info.split('\n').map((subStr, i) => (
                      <span key={i}>
                        {subStr}
                        <br />
                      </span>
                    ))}
                  </div>
                </div>
              ) : null}

              {person.persons && person.persons.length !== 0 ? (
                <div className="mt-10">
                  {CONFIG.PERSON_PAGE.PERSONS !== '' ? (
                    <h3 className={'w-fit pb-1 border-b-2 mb-3 flex flex-row' + theme.redBorder2}>
                      <MenuPersonsSVG color="#141B34" />
                      <span className="me-1"></span>
                      {CONFIG.PERSON_PAGE.PERSONS}
                    </h3>
                  ) : null}
                  {person.persons.map((person: string) =>
                    CONFIG.SHOW_PERSONS ? (
                      <Link key={person} to={'/persons/' + person} className="block w-fit">
                        {getPerson(person)?.name}
                      </Link>
                    ) : (
                      <div className="" key={person}>
                        {getPerson(person)?.name}
                      </div>
                    )
                  )}
                </div>
              ) : null}

              {/* {person.organizations && CONFIG.PERSON_PAGE.ORGANIZATIONS !== "" ? (
                  <div className="lg:px-3 pb-3">
                    <h3
                      className={
                        "w-fit font-semibold border-b-2 px-1" + theme.redBorder2
                      }
                    >
                      {CONFIG.PERSON_PAGE.ORGANIZATIONS}
                    </h3>
                    {person.organizations.map((org: string) => (
                      <Link
                        key={org}
                        to={"/organizations/" + getOrganization(org)?._id}
                        className="block underline px-1"
                      >
                        {getOrganization(org)?.name}
                      </Link>
                    ))}
                  </div>
                ) : null} */}

              {person.links && person.links.length !== 0 ? (
                <div className="mt-10">
                  {CONFIG.PERSON_PAGE.LINKS !== '' ? (
                    <h3 className={'w-fit border-b-2' + theme.redBorder2}>{CONFIG.PERSON_PAGE.LINKS}</h3>
                  ) : null}
                  {person.links.map((link: string) =>
                    link.includes('$') ? (
                      <a key={link} href={link.split('$')[1]} className="mt-3 block" target="_blank">
                        {link.split('$')[0] !== 'mt-3' ? link.split('$')[0] : link.split('$')[1]}
                      </a>
                    ) : (
                      <div className="mt-3" key={link}>
                        {link}
                      </div>
                    )
                  )}
                </div>
              ) : null}
            </main>
          </div>
        </>
      ) : (
        <Loader title={'Загрузка информации о человеке...'} />
      )}
      {/* </div> */}
    </MainLayout>
  );
};

export default PersonPage;

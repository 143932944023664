import React from 'react';
import { YMaps, Map, Placemark, Clusterer, GeoObject } from '@pbe/react-yandex-maps';
import { useData } from '../hooks/useContext';
import { ICategory, ILocation, IPartialOrganization, IPerson } from '../interfaces';
import { CONFIG } from '../config';
import MenuPersonsSVG from './SVG/menuSVG/menuPersons';

class YandexMap extends React.Component<any> {
  state = { balloonContentTemplate: null };

  createTemplateLayoutFactory = (ymaps: any) => {
    const { balloonContentTemplate } = this.state;

    if (ymaps && !balloonContentTemplate) {
      this.setState({
        balloonContentTemplate: ymaps.templateLayoutFactory.createClass(
          [
            //header
            "<div class='max-w-[500px] w-fit flex flex-col'>",
            CONFIG.SHOW_LOCATIONS
              ? "<a class='text-xl' href='/locations/{{properties.geoObjects[0].properties.location._id|raw}}'>{{properties.geoObjects[0].properties.location.adress|raw}}</a>"
              : "<div class='text-xl'>{{properties.geoObjects[0].properties.location.adress|raw}}</div>",
            //header
            //body
            '<div>',
            // "<div class='me-3 mt-2'>",
            '{{properties.geoObjects[0].properties.image|raw}}',
            // "</div>",
            "<div class='text-base'>",
            '{{properties.geoObjects[0].properties.locationDates|raw}}',
            '{{properties.geoObjects[0].properties.locationPersons|raw}}',
            '{{properties.geoObjects[0].properties.locationInfo|raw}}',
            '</div>',
            '</div>',
            //body
            //footer
            "<div class='flex w-fit text-black text-base mt-3 border-b-2 {{properties.geoObjects[0].properties.theme.redBorder2|raw}}'>Организации</div>",
            "<div class='max-h-32 overflow-auto'>", // list
            '{% for geoObject in properties.geoObjects %}',

            "<div class='my-1 flex flex-row justify-between rounded-[20px] {{geoObject.properties.theme.secondary|raw}}'>",
            "<div class='flex flex-col px-2 text-black text-base justify-center'>{{ geoObject.properties.orgName|raw }}</div>",
            "<a class='m-2 py-1 px-2  flex justify-center items-center text-center rounded-[15px] {{geoObject.properties.theme.active|raw}}' href={{geoObject.properties.link|raw}}>Подробнее</a>",
            '</div>',
            '</div>',
            '{% endfor %}',
            '</div>', //list
            '</div>',
          ].join('')
        ),
      });
    }
  };

  render() {
    const { balloonContentTemplate } = this.state;
    const { locs, cats, orgs, pers, theme } = this.props;

    function getColor(categories: { [key: string]: string[] }): string | undefined {
      let color = '#FD00FE';
      Object.keys(categories).forEach((group: string) => {
        categories[group].forEach((category: string) => {
          for (let group in cats) {
            for (let cat in cats[group]) {
              if (cats[group][cat]._id === category && cats[group][cat].color) {
                color = cats[group][cat].color;
              }
            }
          }
        });
      });

      return color;
    }

    function getLocation(location: string): ILocation | undefined {
      for (let i in locs) {
        if (locs[i]._id === location) {
          return locs[i];
        }
      }
    }

    function getPerson(person: string): IPerson | undefined {
      for (let i in pers) {
        if (pers[i]._id === person) {
          return pers[i];
        }
      }
    }

    return (
      <div className={'w-full overflow-hidden h-[88vh] rounded-[40px]' + theme.primary} tabIndex={-1}>
        <YMaps>
          <Map
            onLoad={this.createTemplateLayoutFactory}
            state={{ center: [59.93, 30.31], zoom: 5 }}
            width={'100%'}
            height={'100%'}
            modules={[
              'layout.PieChart',
              'geoObject.addon.balloon',
              'geoObject.addon.hint',
              'clusterer.addon.balloon',
              'templateLayoutFactory',
            ]}
            options={{
              minZoom: 5,
              maxZoom: 17,
              restrictMapArea: [
                [59.4, 29.6],
                [60.5, 30.9],
              ],
              suppressMapOpenBlock: true,
            }}
          >
            <Clusterer
              options={{
                preset: 'islands#geolocationIcon',
                clusterIconLayout: 'default#pieChart',
                groupByCoordinates: CONFIG.MAP_INDIVIDUAL_PLACEMARKS,
                clusterBalloonContentLayout: balloonContentTemplate,
                gridSize: 128,
                balloonPanelMaxMapArea: window.screen.width < 768 ? Infinity : 0,
              }}
            >
              {orgs.length > 0 && locs.length > 0
                ? orgs.map((organization: IPartialOrganization) =>
                    organization.locations.map((orgLocation: string) => (
                      <Placemark
                        key={organization._id + orgLocation}
                        geometry={getLocation(orgLocation)?.geo.split(', ')}
                        options={{
                          preset: 'islands#dotIcon',
                          iconColor: getColor(organization.categories),
                          balloonMaxWidth: 500,
                          balloonPanelMaxMapArea: window.screen.width < 768 ? Infinity : 0,
                        }}
                        properties={{
                          balloonContent: [
                            //header
                            "<div class='max-w-[500px] w-fit flex flex-col'>",
                            CONFIG.SHOW_LOCATIONS
                              ? "<a class='text-xl " +
                                "' href='/locations/" +
                                getLocation(orgLocation)?._id +
                                "'>" +
                                getLocation(orgLocation)?.adress +
                                '</a>'
                              : "<div class='text-xl border-b-2" +
                                theme.redBorder2 +
                                "'>" +
                                getLocation(orgLocation)?.adress +
                                '</div>',
                            //body
                            "<div class=''>",
                            // "<div class='me-3 mt-2 w-1/2'>", //image
                            getLocation(orgLocation)?.image //image
                              ? "<div class='me-3 mt-2 w-1/2 h-fit float-left' >" +
                                "<img class='w-full rounded-[20px]' src='" +
                                (getLocation(orgLocation)?.image?.includes('$')
                                  ? getLocation(orgLocation)?.image?.split('$')[1]
                                  : getLocation(orgLocation)?.image) +
                                "' alt='" +
                                (getLocation(orgLocation)?.image?.includes('$')
                                  ? getLocation(orgLocation)?.image?.split('$')[0]
                                  : 'Изображение ' + getLocation(orgLocation)?.adress) +
                                "'>" +
                                '</div>'
                              : '',

                            // "</div>",
                            "<div class='text-base'>", //info
                            getLocation(orgLocation)?.dates
                              ? "<div class='mt-2'><span class='border-b-2" +
                                theme.redBorder2 +
                                "'>" +
                                (CONFIG.LOCATION_PAGE.DATES ? CONFIG.LOCATION_PAGE.DATES + ':' : '') +
                                '</span><span> </span><span>' +
                                getLocation(orgLocation)?.dates +
                                '</span></div>'
                              : '',
                            getLocation(orgLocation)?.persons && getLocation(orgLocation)?.persons?.length !== 0
                              ? "<div class='mt-2" +
                                "'><span class=' border-b-2" +
                                theme.redBorder2 +
                                "'>" +
                                (CONFIG.LOCATION_PAGE.PERSONS ? CONFIG.LOCATION_PAGE.PERSONS + ':' : '') +
                                '</span><span> </span><span>' +
                                getLocation(orgLocation)
                                  ?.persons?.map((person) => '<span>' + getPerson(person)?.name + '</span>')
                                  .join(', ') +
                                '</span></div>'
                              : '',

                            "<div class='mt-2" +
                              "'><span class=' border-b-2" +
                              theme.redBorder2 +
                              "'>" +
                              (CONFIG.LOCATION_PAGE.INFO ? CONFIG.LOCATION_PAGE.INFO + ':' : '') +
                              '</span><span> </span><span>' +
                              getLocation(orgLocation)?.info?.replaceAll('\n', '<br>') +
                              '</span></div>',

                            '</div>',
                            '</div>',
                            //footer
                            "<div class='flex w-fit text-black text-base my-3 border-b-2" +
                              theme.redBorder2 +
                              "'>Организации</div>",
                            "<div class='flex flex-row justify-between rounded-[20px]" + theme.secondary + "'>",
                            "<div class='flex flex-col px-2 text-black text-base justify-center'>" +
                              organization.name +
                              '</div>',
                            "<a class='m-2 py-1 px-2  flex justify-center items-center text-center rounded-[15px]" +
                              theme.active +
                              "' href='./organizations/" +
                              organization._id +
                              "'>Подробнее</a>",
                            '</div>',
                          ].join(''),

                          location: getLocation(orgLocation),
                          locationInfo:
                            "<div class='mt-2" +
                            "'><span class=' border-b-2" +
                            theme.redBorder2 +
                            "'>" +
                            (CONFIG.LOCATION_PAGE.INFO ? CONFIG.LOCATION_PAGE.INFO + ':' : '') +
                            '</span><span> </span><span>' +
                            getLocation(orgLocation)?.info?.replaceAll('\n', '<br>') +
                            '</span></div>',
                          locationDates: getLocation(orgLocation)?.dates
                            ? "<div class='mt-2'><span class=' border-b-2" +
                              theme.redBorder2 +
                              "'>" +
                              (CONFIG.LOCATION_PAGE.DATES ? CONFIG.LOCATION_PAGE.DATES + ':' : '') +
                              '</span><span> </span><span>' +
                              getLocation(orgLocation)?.dates +
                              '</span></div>'
                            : '',
                          locationPersons:
                            getLocation(orgLocation)?.persons && getLocation(orgLocation)?.persons?.length !== 0
                              ? "<div class='mt-2" +
                                "'><span class=' border-b-2" +
                                theme.redBorder2 +
                                "'>" +
                                (CONFIG.LOCATION_PAGE.PERSONS ? CONFIG.LOCATION_PAGE.PERSONS + ':' : '') +
                                '</span><span> </span><span>' +
                                getLocation(orgLocation)
                                  ?.persons?.map((person) => '<span>' + getPerson(person)?.name + '</span>')
                                  .join(', ') +
                                '</span></div>'
                              : '',
                          // adress: getLocation(orgLocation)?.adress,
                          orgName: organization.name,
                          image: getLocation(orgLocation)?.image //image
                            ? "<div class='me-3 mt-2 w-1/2 h-fit float-left' >" +
                              "<img class='w-full rounded-[20px]' src='" +
                              (getLocation(orgLocation)?.image?.includes('$')
                                ? getLocation(orgLocation)?.image?.split('$')[1]
                                : getLocation(orgLocation)?.image) +
                              "' alt='" +
                              (getLocation(orgLocation)?.image?.includes('$')
                                ? getLocation(orgLocation)?.image?.split('$')[0]
                                : 'Изображение ' + getLocation(orgLocation)?.adress) +
                              "'>" +
                              '</div>'
                            : '',
                          // geoId: getLocation(orgLocation)?._id,
                          // info: getLocation(orgLocation)?.info,
                          link: '/organizations/' + organization._id,
                          theme: theme,
                          hintContent: organization.name,
                        }}
                      />
                    ))
                  )
                : null}
            </Clusterer>
          </Map>
        </YMaps>
      </div>
    );
  }
}

export default YandexMap;

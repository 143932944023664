import React from "react";

const AboutSVG = () => {
  return (
    <svg width="284" height="200" viewBox="0 0 284 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_791)">
        <path
          d="M171.756 219.194L249.774 189.921L250.337 183.683L172.892 212.95L171.756 219.194Z"
          fill="#4765DA"
          stroke="#141B34"
          strokeWidth="0.694053"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M158.619 115.716C157.248 115.705 155.923 115.222 154.868 114.347C153.541 113.253 152.67 111.704 152.424 110.003C152.393 109.699 152.48 109.395 152.667 109.153C152.854 108.911 153.127 108.751 153.429 108.705C153.731 108.658 154.039 108.73 154.29 108.904C154.54 109.079 154.715 109.343 154.776 109.642C154.936 110.758 155.504 111.775 156.371 112.496C156.748 112.821 157.191 113.06 157.67 113.197C158.148 113.335 158.651 113.366 159.143 113.29C159.635 113.214 160.105 113.032 160.52 112.757C160.935 112.481 161.285 112.119 161.546 111.695C161.726 111.446 161.995 111.275 162.298 111.22C162.601 111.165 162.913 111.229 163.17 111.399C163.426 111.568 163.607 111.831 163.675 112.131C163.742 112.431 163.691 112.746 163.532 113.009C163.086 113.708 162.496 114.305 161.802 114.759C161.108 115.213 160.325 115.514 159.505 115.642C159.212 115.691 158.916 115.716 158.619 115.716Z"
          fill="#141B34"
        />
        <path
          d="M147.359 119.192C144.346 119.192 141.652 116.797 141.145 113.485C141.12 113.331 141.127 113.174 141.163 113.022C141.2 112.87 141.266 112.727 141.358 112.601C141.45 112.475 141.566 112.369 141.699 112.288C141.832 112.206 141.98 112.152 142.135 112.129C142.446 112.088 142.762 112.168 143.015 112.354C143.269 112.539 143.442 112.815 143.497 113.125C143.851 115.452 145.813 117.09 147.86 116.791C148.351 116.711 148.82 116.527 149.235 116.252C149.65 115.977 150.002 115.617 150.267 115.196C150.447 114.946 150.716 114.776 151.019 114.721C151.322 114.666 151.634 114.73 151.891 114.899C152.147 115.069 152.328 115.331 152.396 115.632C152.463 115.932 152.412 116.246 152.253 116.51C151.807 117.209 151.217 117.805 150.523 118.26C149.829 118.714 149.046 119.015 148.226 119.143C147.939 119.183 147.649 119.199 147.359 119.192Z"
          fill="#141B34"
        />
        <path
          d="M137.057 123.146C135.687 123.137 134.362 122.655 133.306 121.783C131.981 120.688 131.11 119.14 130.862 117.439C130.831 117.135 130.918 116.831 131.105 116.59C131.292 116.348 131.565 116.187 131.866 116.141C132.168 116.095 132.477 116.166 132.727 116.341C132.978 116.515 133.152 116.779 133.214 117.079C133.373 118.195 133.942 119.212 134.809 119.932C135.19 120.251 135.633 120.487 136.111 120.624C136.589 120.761 137.09 120.796 137.583 120.726C138.073 120.646 138.541 120.463 138.955 120.188C139.369 119.913 139.72 119.553 139.984 119.132C140.184 118.938 140.443 118.819 140.72 118.793C140.997 118.767 141.275 118.836 141.507 118.989C141.739 119.142 141.912 119.37 141.997 119.635C142.083 119.9 142.075 120.185 141.976 120.445C141.528 121.144 140.937 121.741 140.242 122.195C139.547 122.648 138.763 122.95 137.943 123.079C137.65 123.126 137.354 123.148 137.057 123.146Z"
          fill="#141B34"
        />
        <path
          d="M125.796 126.371C122.784 126.371 120.09 123.97 119.583 120.665C119.551 120.507 119.552 120.345 119.585 120.187C119.618 120.03 119.682 119.881 119.774 119.749C119.866 119.617 119.984 119.506 120.12 119.42C120.257 119.335 120.409 119.279 120.568 119.254C120.726 119.229 120.889 119.237 121.044 119.276C121.2 119.316 121.346 119.387 121.474 119.484C121.602 119.582 121.708 119.704 121.788 119.844C121.867 119.984 121.917 120.138 121.935 120.298C122.295 122.626 124.251 124.263 126.297 123.964C126.788 123.884 127.256 123.701 127.671 123.427C128.086 123.153 128.438 122.795 128.705 122.375C128.785 122.233 128.894 122.109 129.024 122.011C129.155 121.913 129.304 121.843 129.462 121.804C129.621 121.766 129.786 121.761 129.946 121.789C130.107 121.817 130.26 121.878 130.397 121.968C130.533 122.057 130.649 122.174 130.739 122.311C130.828 122.447 130.888 122.601 130.916 122.761C130.943 122.922 130.938 123.087 130.899 123.246C130.86 123.404 130.789 123.553 130.691 123.683C130.244 124.382 129.654 124.979 128.96 125.434C128.266 125.889 127.484 126.192 126.664 126.322C126.376 126.36 126.086 126.376 125.796 126.371Z"
          fill="#141B34"
        />
        <path
          d="M114.267 130.954C111.261 130.954 108.566 128.559 108.053 125.247C108.072 124.97 108.184 124.707 108.372 124.502C108.56 124.297 108.813 124.163 109.088 124.121C109.362 124.079 109.643 124.132 109.884 124.271C110.125 124.41 110.311 124.628 110.411 124.887C110.766 127.215 112.727 128.846 114.774 128.553C115.265 128.472 115.733 128.288 116.148 128.013C116.563 127.738 116.915 127.378 117.181 126.958C117.361 126.709 117.631 126.538 117.933 126.483C118.236 126.428 118.548 126.492 118.805 126.661C119.061 126.831 119.242 127.094 119.31 127.394C119.377 127.694 119.326 128.008 119.167 128.272C118.72 128.97 118.13 129.566 117.436 130.02C116.742 130.474 115.96 130.776 115.141 130.905C114.851 130.942 114.559 130.959 114.267 130.954Z"
          fill="#141B34"
        />
        <path
          d="M103.104 135.042C101.734 135.034 100.408 134.552 99.3523 133.679C98.0273 132.584 97.1564 131.036 96.9083 129.335C96.8774 129.031 96.9645 128.727 97.1515 128.486C97.3385 128.244 97.6112 128.083 97.9131 128.037C98.2151 127.991 98.5233 128.062 98.7741 128.237C99.0248 128.411 99.199 128.675 99.2606 128.975C99.42 130.091 99.9883 131.108 100.855 131.828C101.232 132.153 101.676 132.393 102.154 132.53C102.633 132.667 103.136 132.699 103.628 132.623C104.12 132.546 104.589 132.364 105.004 132.089C105.419 131.814 105.769 131.452 106.03 131.028C106.23 130.834 106.49 130.715 106.767 130.689C107.044 130.663 107.321 130.732 107.554 130.885C107.786 131.038 107.959 131.266 108.044 131.531C108.129 131.796 108.122 132.081 108.022 132.341C107.575 133.04 106.984 133.637 106.288 134.091C105.593 134.544 104.81 134.846 103.99 134.975C103.697 135.022 103.4 135.044 103.104 135.042Z"
          fill="#141B34"
        />
        <path d="M173.894 215.388L238.459 191.602" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M94.0183 132.298V139.673L171.756 219.195L172.892 212.95L94.0183 132.298Z"
          fill="#4765DA"
          stroke="#141B34"
          strokeWidth="0.694053"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M95.1731 138.084L141.334 184.239" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M116.98 157.905L165.114 207.176" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M94.0183 132.298L172.892 212.95L250.337 183.683L170.07 106.758L94.0183 132.298Z"
          fill="white"
          stroke="#141B34"
          strokeWidth="0.694053"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.053 131.027L20.0018 62.8458L94.831 39.5117L169.495 105.542L93.053 131.027Z"
          fill="#D2DAFA"
          stroke="#141B34"
          strokeWidth="0.694053"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M31.1831 63.2986L52.5681 56.6387" stroke="#141B34" strokeWidth="0.739628" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M34.3419 65.7541L55.7269 59.0942" stroke="#141B34" strokeWidth="0.739628" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M107.02 132.873C106.788 132.874 106.56 132.806 106.367 132.677C106.109 132.499 105.929 132.228 105.866 131.921C105.802 131.614 105.859 131.294 106.024 131.028C106.635 130.079 106.87 128.938 106.684 127.826C106.581 126.983 106.228 126.19 105.672 125.548C105.116 124.906 104.382 124.444 103.562 124.221C103.266 124.14 103.013 123.948 102.855 123.685C102.697 123.422 102.647 123.108 102.715 122.809C102.782 122.51 102.963 122.248 103.219 122.079C103.474 121.909 103.786 121.845 104.088 121.899C105.374 122.229 106.532 122.934 107.415 123.926C108.297 124.918 108.864 126.15 109.043 127.465C109.322 129.166 108.956 130.909 108.016 132.353C107.904 132.513 107.755 132.644 107.582 132.734C107.408 132.825 107.216 132.872 107.02 132.873Z"
          fill="#141B34"
        />
        <path
          d="M118.171 128.786C117.939 128.787 117.711 128.719 117.517 128.59C117.386 128.504 117.274 128.394 117.186 128.264C117.098 128.134 117.037 127.989 117.006 127.835C116.974 127.682 116.974 127.524 117.004 127.37C117.034 127.217 117.094 127.071 117.181 126.941C117.789 125.991 118.024 124.851 117.841 123.739C117.738 122.896 117.385 122.103 116.829 121.461C116.273 120.819 115.539 120.357 114.719 120.134C114.559 120.108 114.406 120.049 114.27 119.961C114.134 119.874 114.016 119.759 113.926 119.625C113.835 119.49 113.773 119.339 113.743 119.18C113.713 119.02 113.716 118.857 113.751 118.698C113.786 118.54 113.854 118.391 113.949 118.26C114.044 118.128 114.165 118.018 114.304 117.935C114.443 117.852 114.598 117.798 114.758 117.777C114.919 117.756 115.082 117.768 115.238 117.812C116.524 118.142 117.683 118.847 118.566 119.839C119.448 120.831 120.015 122.063 120.194 123.378C120.473 125.079 120.107 126.822 119.167 128.266C119.055 128.426 118.906 128.557 118.732 128.647C118.559 128.738 118.367 128.785 118.171 128.786Z"
          fill="#141B34"
        />
        <path
          d="M162.536 113.547C162.327 113.536 162.124 113.472 161.947 113.36C161.769 113.249 161.624 113.094 161.524 112.91C161.424 112.726 161.373 112.519 161.376 112.31C161.378 112.1 161.435 111.895 161.54 111.714C162.151 110.768 162.387 109.628 162.2 108.518C162.097 107.674 161.746 106.88 161.189 106.237C160.633 105.593 159.898 105.131 159.078 104.907C158.925 104.874 158.781 104.811 158.653 104.721C158.525 104.632 158.416 104.518 158.332 104.386C158.249 104.254 158.192 104.107 158.166 103.953C158.14 103.799 158.144 103.642 158.18 103.49C158.255 103.185 158.445 102.921 158.71 102.753C158.975 102.584 159.295 102.524 159.603 102.585C160.889 102.915 162.048 103.621 162.93 104.612C163.813 105.604 164.38 106.836 164.558 108.152C164.838 109.853 164.47 111.597 163.526 113.04C163.415 113.199 163.267 113.329 163.094 113.417C162.922 113.506 162.73 113.55 162.536 113.547Z"
          fill="#141B34"
        />
        <path
          d="M151.257 117.011C151.024 117.013 150.797 116.945 150.603 116.816C150.472 116.73 150.359 116.619 150.272 116.49C150.184 116.36 150.123 116.214 150.091 116.061C150.06 115.908 150.059 115.75 150.09 115.596C150.12 115.442 150.18 115.296 150.267 115.166C150.875 114.217 151.11 113.077 150.927 111.964C150.823 111.121 150.471 110.328 149.915 109.686C149.359 109.044 148.624 108.583 147.805 108.36C147.645 108.333 147.492 108.274 147.356 108.187C147.219 108.099 147.102 107.985 147.012 107.85C146.921 107.716 146.859 107.564 146.829 107.405C146.799 107.246 146.801 107.082 146.837 106.924C146.872 106.766 146.939 106.617 147.034 106.485C147.129 106.354 147.25 106.244 147.389 106.161C147.529 106.078 147.683 106.024 147.844 106.003C148.005 105.982 148.168 105.994 148.324 106.038C149.611 106.366 150.77 107.071 151.653 108.063C152.536 109.055 153.102 110.288 153.279 111.604C153.558 113.304 153.192 115.048 152.253 116.492C152.14 116.652 151.991 116.782 151.818 116.873C151.645 116.963 151.452 117.011 151.257 117.011Z"
          fill="#141B34"
        />
        <path
          d="M140.955 120.982C140.722 120.981 140.495 120.911 140.302 120.78C140.044 120.602 139.864 120.331 139.801 120.024C139.737 119.717 139.794 119.397 139.959 119.13C140.573 118.184 140.811 117.042 140.625 115.929C140.522 115.086 140.169 114.293 139.613 113.651C139.057 113.009 138.323 112.547 137.503 112.324C137.343 112.297 137.19 112.239 137.054 112.151C136.918 112.063 136.801 111.949 136.71 111.815C136.619 111.68 136.557 111.529 136.527 111.37C136.497 111.21 136.5 111.047 136.535 110.888C136.571 110.73 136.638 110.581 136.733 110.45C136.828 110.318 136.949 110.208 137.088 110.125C137.227 110.042 137.382 109.988 137.543 109.967C137.703 109.946 137.867 109.958 138.023 110.002C139.309 110.332 140.467 111.037 141.35 112.029C142.232 113.02 142.799 114.253 142.978 115.568C143.257 117.269 142.891 119.012 141.951 120.456C141.84 120.617 141.691 120.749 141.517 120.841C141.344 120.932 141.151 120.98 140.955 120.982Z"
          fill="#141B34"
        />
        <path
          d="M129.695 124.202C129.463 124.2 129.236 124.133 129.041 124.007C128.91 123.921 128.797 123.81 128.709 123.68C128.622 123.551 128.56 123.405 128.529 123.252C128.498 123.098 128.497 122.94 128.527 122.787C128.558 122.633 128.618 122.487 128.705 122.357C129.313 121.408 129.548 120.267 129.365 119.155C129.261 118.312 128.909 117.519 128.353 116.877C127.797 116.235 127.062 115.773 126.242 115.55C126.059 115.551 125.879 115.509 125.713 115.43C125.548 115.351 125.403 115.236 125.289 115.093C125.174 114.95 125.093 114.783 125.052 114.605C125.01 114.426 125.009 114.241 125.049 114.062C125.089 113.884 125.169 113.716 125.282 113.572C125.395 113.428 125.54 113.312 125.704 113.231C125.868 113.151 126.049 113.108 126.232 113.106C126.415 113.105 126.596 113.145 126.762 113.223C128.049 113.55 129.209 114.255 130.092 115.247C130.975 116.239 131.541 117.472 131.717 118.789C131.997 120.489 131.631 122.233 130.691 123.677C130.579 123.838 130.43 123.97 130.257 124.061C130.083 124.152 129.891 124.201 129.695 124.202Z"
          fill="#141B34"
        />
        <path d="M235.428 181.82L238.434 180.714" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M176.082 203.743L232.697 182.834" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M177.67 194.29L231.701 174.335" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M169.348 197.364L174.078 195.617" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M162.615 190.985L224.974 167.956" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M155.888 184.613L218.241 161.584" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M208.892 156.169L211.508 155.204" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M149.155 178.234L205.153 157.551" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M142.422 171.854L204.78 148.826" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M135.688 165.482L198.047 142.454" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M128.961 159.102L191.314 136.074" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M122.228 152.724L184.581 129.696" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M175.642 124.136L177.854 123.317" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M115.495 146.346L173.723 124.845" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M127.379 133.094L171.12 116.945" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M108.768 139.972L120.811 135.524" stroke="#141B34" strokeWidth="0.347026" strokeLinecap="round" strokeLinejoin="round" />
        <g style={{ mixBlendMode: "multiply" }}>
          <path
            d="M130.397 156.463C136.709 157.288 136.153 146.486 134.552 142.862C133.384 144.201 132.514 145.772 131.999 147.472C131.484 149.172 131.336 150.962 131.564 152.724C131.631 153.547 131.918 154.336 132.395 155.009C132.637 155.342 132.972 155.597 133.358 155.74C133.744 155.884 134.163 155.91 134.564 155.816C135.139 155.572 135.625 155.158 135.957 154.63C137.118 153.091 137.888 151.184 139.11 149.773C140.803 150.915 146.815 152.871 145.947 148.667C145.471 146.345 146.625 146.608 147.866 147.744C148.379 148.209 149.185 147.958 149.821 147.677L155.931 144.989C156.708 144.592 157.551 144.338 158.418 144.237C161.35 144.091 163.269 146.816 166.147 144.891C167.369 144.084 167.65 141.897 169.202 141.066C170.308 140.455 171.609 140.418 172.868 140.247C177.194 139.673 179.406 137.388 183.401 135.323"
            stroke="#4765DA"
            strokeWidth="0.694053"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }}>
          <path
            d="M135.297 163.216C137.662 163.619 139.684 161.462 141.774 160.772C144.444 159.879 145.605 158.792 148.171 157.686C151.562 156.232 155.546 155.56 154.837 150.433C154.355 152.266 154.3 154.283 153.927 156.201C156.676 157.564 157.416 155.224 157.697 153.146C158.62 153.123 159.531 152.939 160.391 152.602C160.391 152.987 160.513 153.421 160.507 153.788C161.827 154.319 162.34 153.959 162.615 152.792C164.076 152.517 164.595 151.148 165.829 150.812C166.141 154.24 167.014 157.24 166.813 160.747C164.473 161.358 164.106 157.319 164.699 155.645C165.31 153.873 167.069 152.926 167.717 151.075C167.852 151.301 167.748 151.564 167.821 151.808C170.766 150.048 174.542 150.525 177.463 148.466C178.379 147.818 179.033 146.523 180.004 146.071C182.118 145.093 182.748 146.242 184.324 143.938"
            stroke="#4765DA"
            strokeWidth="0.694053"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }}>
          <path
            d="M156.585 183.348C160.831 182.126 162.695 171.574 161.473 167.7C160.043 163.014 158.076 168.152 157.617 170.669C157.251 172.686 156.664 178.001 158.546 179.535C162.719 182.92 165.267 175.961 165.78 172.814C165.964 174.782 164.778 177.207 165.261 179.327C168.64 179.725 170.314 176.914 170.1 174.012C170.52 174.413 170.804 174.935 170.914 175.506C171.023 176.076 170.952 176.667 170.711 177.195C173.937 176.969 173.406 171.525 173.766 169.331C173.583 170.865 173.723 172.661 174.756 173.902C175.618 174.928 177.261 175.38 178.343 174.586C179.424 173.792 179.21 171.702 177.897 171.427V184.655C177.938 185.038 177.861 185.424 177.677 185.761C177.243 186.372 176.18 186.244 175.685 185.657C175.249 185.017 175.044 184.248 175.104 183.476C175.043 179.144 176.381 173.211 179.736 170.254C179.908 170.675 179.965 171.135 179.901 171.586C180.222 171.461 180.512 171.268 180.751 171.02C180.991 170.772 181.174 170.475 181.288 170.15C181.508 171.122 182.082 172.344 183.066 172.173C183.392 172.087 183.691 171.919 183.933 171.684C185.546 170.352 187.294 169.472 188.375 167.578C188.528 168.054 188.687 168.531 188.846 169.014C189.102 168.091 189.982 167.486 190.844 167.071C192.158 166.453 193.579 166.094 195.029 166.014C196.251 165.953 197.583 166.081 198.695 165.555C199.188 165.292 199.659 164.992 200.106 164.657C201.372 163.823 202.839 163.342 204.353 163.264"
            stroke="#4765DA"
            strokeWidth="0.694053"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M106.244 67.3118C106.244 67.3118 115.99 55.0307 119.289 50.6376C122.589 46.2446 124.978 42.8657 124.978 42.8657C124.978 42.8657 126.737 44.2527 126.2 45.8291C125.662 47.4054 114.071 63.2365 111.493 67.2568C108.915 71.2772 109.275 76.3669 109.464 78.1204C109.654 79.874 104.54 74.1428 106.244 67.3118Z"
          fill="white"
          stroke="#141B34"
          strokeWidth="0.777601"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M124.978 42.8655C124.978 42.8655 131.045 34.4276 135.548 27.4011C140.051 20.3746 146.626 9.82264 148.19 8.41734C149.754 7.01204 150.133 10.678 149.626 13.1343C149.118 15.5905 143.736 22.4459 140.1 28.0671C136.465 33.6883 134.216 37.7331 131.088 41.4236C127.96 45.114 128.143 45.2912 126.2 45.8289C124.257 46.3666 124.636 44.4419 124.978 42.8655Z"
          fill="#141B34"
          stroke="#141B34"
          strokeWidth="0.777601"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M104.149 68.5464C104.821 63.9945 106.342 55.5871 109.819 52.9415C113.295 50.2958 123.817 52.3305 124.691 52.4893C125.564 52.6482 139.41 55.7154 143.632 58.3122C147.854 60.9089 150.689 68.412 151.77 72.7562C152.852 77.1004 155.173 84.4568 155.522 84.9762C155.522 84.9762 159.035 86.522 160.96 86.3387C162.884 86.1554 165.444 77.2104 170.833 71.9252C176.222 66.6401 179.021 65.9191 184.972 66.4262C190.923 66.9334 213.53 73.0617 215.821 75.3163C218.113 77.5709 219.542 82.6483 219.047 86.3143C218.552 89.9803 219.273 94.0129 219.273 94.7094C219.273 95.406 221.394 97.6728 220.88 99.5974C220.367 101.522 220.049 105.707 218.479 106.263C216.909 106.819 205.355 106.526 196.776 105.359C188.198 104.192 176.265 99.017 168.365 95.3999C160.465 91.7827 156.609 90.2247 150.835 90.2675C145.061 90.3102 133.868 90.5546 123.53 88.1717C113.192 85.7888 106.165 81.4507 104.222 79.5505C102.279 77.6503 103.483 73.1045 104.149 68.5464ZM166.055 90.0047C168.884 94.1901 180.286 98.3143 186.42 100.019C192.554 101.724 207.267 104.779 211.104 102.659C214.941 100.538 216.958 87.9212 216.603 83.5465C216.249 79.1717 214.459 76.7399 214.459 76.7399C206.901 72.0658 183.249 67.1472 178.361 69.1085C173.473 71.0698 168.799 77.3937 167.082 82.4772C165.365 87.5607 166.055 90.0047 166.055 90.0047ZM116.668 82.8133C124.22 87.3102 136.129 88.63 140.852 88.7766C145.575 88.9233 150.108 86.4426 150.249 81.0169C150.389 75.5912 147.53 66.5118 146.461 63.9089C145.391 61.3061 141.884 59.7419 141.884 59.7419C137.149 57.4995 117.517 52.5565 114.194 53.1064C110.87 53.6563 107.961 58.7032 107.283 66.078C106.605 73.4527 109.116 78.3163 116.668 82.8133Z"
          fill="#141B34"
          stroke="#141B34"
          strokeWidth="0.777601"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M218.748 94.5561C218.748 94.5561 220.691 97.5195 222.078 95.4115C223.465 93.3036 241.355 73.2383 241.355 73.2383C241.355 73.2383 242.052 73.055 241.887 74.4603C241.722 75.8656 222.994 101.008 222.298 102.236C221.601 103.464 218.632 103.837 217.935 103.318C217.239 102.799 218.748 94.5561 218.748 94.5561Z"
          fill="white"
          stroke="#141B34"
          strokeWidth="0.777601"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M240.133 73.9404C241.155 72.1204 242.077 70.2459 242.895 68.3253C244.789 64.1155 245.95 55.8854 246.255 47.3069C246.561 38.7285 246.866 33.6572 248.449 35.2213C250.031 36.7855 248.675 45.199 248.381 51.8405C248.088 58.4821 246.023 65.1542 245.522 68.6614C245.143 71.307 243.115 75.4985 241.715 75.5046C240.316 75.5107 240.493 75.1624 240.133 73.9404Z"
          fill="#141B34"
          stroke="#141B34"
          strokeWidth="0.777601"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M124.691 183.616L132.383 192.323L120.945 189.744L39.56 139.954L43.3116 133.826L124.691 183.616Z"
          fill="white"
          stroke="#141B34"
          strokeWidth="0.87764"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M124.691 183.616L132.383 192.323L120.945 189.744L39.56 139.954L43.3116 133.826L124.691 183.616Z" fill="#4765DA" />
          <path
            d="M124.691 183.616L132.383 192.323L120.945 189.744L39.56 139.954L43.3116 133.826L124.691 183.616Z"
            stroke="#141B34"
            strokeWidth="0.87764"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M42.4595 135.219L40.4155 138.56L121.799 188.354L123.843 185.013L42.4595 135.219Z"
          stroke="#141B34"
          strokeWidth="0.438819"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M124.709 183.634L120.964 189.75L132.383 192.323L124.709 183.634Z"
          fill="white"
          stroke="#141B34"
          strokeWidth="0.87764"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M126.67 191.033L132.383 192.322L128.546 187.978L126.67 191.033Z"
          fill="#4765DA"
          stroke="#141B34"
          strokeWidth="0.87764"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.3258 131.313C32.079 131.715 31.9139 132.162 31.8399 132.628C31.7659 133.093 31.7845 133.569 31.8946 134.028C32.0047 134.486 32.2042 134.919 32.4816 135.3C32.759 135.681 33.1088 136.004 33.5112 136.25L39.5601 139.953L43.3116 133.843L37.2627 130.14C36.4522 129.642 35.4772 129.486 34.5518 129.706C33.6263 129.926 32.8258 130.504 32.3258 131.313Z"
          fill="white"
          stroke="#141B34"
          strokeWidth="0.87764"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.3258 131.313C32.079 131.715 31.9139 132.162 31.8399 132.628C31.7659 133.093 31.7845 133.569 31.8946 134.028C32.0047 134.486 32.2042 134.919 32.4816 135.3C32.759 135.681 33.1088 136.004 33.5112 136.25L36.4073 138.022L40.1589 131.912L37.2627 130.14C36.4522 129.642 35.4772 129.486 34.5518 129.706C33.6263 129.926 32.8258 130.504 32.3258 131.313Z"
          fill="#141B34"
          stroke="#141B34"
          strokeWidth="0.87764"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M196.556 102.139L194.412 100.629C194.08 100.397 193.7 100.242 193.3 100.176C192.9 100.111 192.491 100.136 192.102 100.251C189.835 100.91 185.558 102.591 184.336 103.917C182.748 105.554 182.247 108.517 183.01 109.911C183.774 111.304 186.909 113.082 186.909 113.082L200.039 105.09L196.556 102.139Z"
          fill="white"
          stroke="#141B34"
          strokeWidth="0.5788"
          strokeMiterlimit="10"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path
            d="M196.556 102.139L194.412 100.629C194.08 100.397 193.7 100.242 193.3 100.176C192.9 100.111 192.491 100.136 192.102 100.251C189.835 100.91 185.558 102.591 184.336 103.917C182.748 105.554 182.247 108.517 183.01 109.911C183.774 111.304 186.909 113.082 186.909 113.082L200.039 105.09L196.556 102.139Z"
            fill="#4765DA"
          />
          <path
            d="M196.556 102.139L194.412 100.629C194.08 100.397 193.7 100.242 193.3 100.176C192.9 100.111 192.491 100.136 192.102 100.251C189.835 100.91 185.558 102.591 184.336 103.917C182.748 105.554 182.247 108.517 183.01 109.911C183.774 111.304 186.909 113.082 186.909 113.082L200.039 105.09L196.556 102.139Z"
            stroke="#141B34"
            strokeWidth="0.5788"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M197.895 101.522L187.508 106.269C187.508 106.269 183.994 108.255 185.106 114.334L245.931 159.121C245.931 159.121 248.045 160.66 251.864 159.359C255.683 158.058 259.593 153.554 260.418 149.748C261.243 145.941 258.585 144.744 258.09 144.322C257.595 143.901 199.948 101.974 199.948 101.974C199.338 101.585 198.611 101.425 197.895 101.522Z"
          fill="white"
          stroke="#141B34"
          strokeWidth="0.5788"
          strokeMiterlimit="10"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path
            d="M197.895 101.522L187.508 106.269C187.508 106.269 183.994 108.255 185.106 114.334L245.931 159.121C245.931 159.121 248.045 160.66 251.864 159.359C255.683 158.058 259.593 153.554 260.418 149.748C261.243 145.941 258.585 144.744 258.09 144.322C257.595 143.901 199.948 101.974 199.948 101.974C199.338 101.585 198.611 101.425 197.895 101.522Z"
            fill="#4765DA"
          />
          <path
            d="M197.895 101.522L187.508 106.269C187.508 106.269 183.994 108.255 185.106 114.334L245.931 159.121C245.931 159.121 248.045 160.66 251.864 159.359C255.683 158.058 259.593 153.554 260.418 149.748C261.243 145.941 258.585 144.744 258.09 144.322C257.595 143.901 199.948 101.974 199.948 101.974C199.338 101.585 198.611 101.425 197.895 101.522Z"
            stroke="#141B34"
            strokeWidth="0.5788"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M256.325 156.201C252.494 159.775 247.856 161.034 245.938 159.005C244.019 156.977 245.601 152.425 249.432 148.844C253.263 145.264 257.901 144.011 259.819 146.04C261.738 148.068 260.149 152.62 256.325 156.201Z"
          fill="white"
          stroke="#141B34"
          strokeWidth="0.5788"
          strokeMiterlimit="10"
        />
        <g style={{ mixBlendMode: "multiply" }}>
          <path
            d="M256.325 156.201C252.494 159.775 247.856 161.034 245.938 159.005C244.019 156.977 245.601 152.425 249.432 148.844C253.263 145.264 257.901 144.011 259.819 146.04C261.738 148.068 260.149 152.62 256.325 156.201Z"
            fill="#4765DA"
          />
          <path
            d="M256.325 156.201C252.494 159.775 247.856 161.034 245.938 159.005C244.019 156.977 245.601 152.425 249.432 148.844C253.263 145.264 257.901 144.011 259.819 146.04C261.738 148.068 260.149 152.62 256.325 156.201Z"
            stroke="#141B34"
            strokeWidth="0.5788"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M199.941 101.973C199.334 101.586 198.609 101.426 197.895 101.521L187.508 106.269C187.508 106.269 183.994 108.254 185.106 114.334L227.168 145.305C226.868 143.876 226.923 141.786 228.53 139.305C230.021 136.99 236.717 133.256 240.335 131.362C223.214 118.904 199.941 101.973 199.941 101.973Z"
          fill="#141B34"
          stroke="#141B34"
          strokeWidth="0.777601"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M248.204 148.734L225.988 132.634C225.656 132.393 225.389 132.073 225.213 131.702C225.037 131.331 224.958 130.922 224.981 130.512C225.005 130.103 225.132 129.706 225.349 129.358C225.567 129.01 225.869 128.722 226.227 128.522L229.666 126.646C230.07 126.427 230.527 126.327 230.986 126.356C231.444 126.385 231.884 126.543 232.257 126.811L255.744 143.821"
          fill="white"
        />
        <path
          d="M248.204 148.734L225.988 132.634C225.656 132.393 225.389 132.073 225.213 131.702C225.037 131.331 224.958 130.922 224.981 130.512C225.005 130.103 225.132 129.706 225.349 129.358C225.567 129.01 225.869 128.722 226.227 128.522L229.666 126.646C230.07 126.427 230.527 126.327 230.986 126.356C231.444 126.385 231.884 126.543 232.257 126.811L255.744 143.821"
          stroke="#141B34"
          strokeWidth="0.5788"
          strokeMiterlimit="10"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path
            d="M248.204 148.734L225.988 132.634C225.656 132.393 225.389 132.073 225.213 131.702C225.037 131.331 224.958 130.922 224.981 130.512C225.005 130.103 225.132 129.706 225.349 129.358C225.567 129.01 225.869 128.722 226.227 128.522L229.666 126.646C230.07 126.427 230.527 126.327 230.986 126.356C231.444 126.385 231.884 126.543 232.257 126.811L255.744 143.821"
            fill="#4765DA"
          />
          <path
            d="M248.204 148.734L225.988 132.634C225.656 132.393 225.389 132.073 225.213 131.702C225.037 131.331 224.958 130.922 224.981 130.512C225.005 130.103 225.132 129.706 225.349 129.358C225.567 129.01 225.869 128.722 226.227 128.522L229.666 126.646C230.07 126.427 230.527 126.327 230.986 126.356C231.444 126.385 231.884 126.543 232.257 126.811L255.744 143.821"
            stroke="#141B34"
            strokeWidth="0.5788"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1_791">
          <rect width="283.799" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AboutSVG;

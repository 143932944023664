import React from "react";

const CheckedSVG = () => {
  return (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.83325 4.42857L6.63325 9L13.8333 1" stroke="#141B34" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default CheckedSVG;

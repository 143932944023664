import React, { useContext, useState, useEffect } from 'react';
import API from '../api';
import { transformCategories } from '../utils/transformData';
import { transformOrganizations } from '../utils/transformData';
import { getActiveFilters } from '../utils/transformData';
import 'react-toastify/dist/ReactToastify.css';
import { IPartialOrganization, ILocation, ICategory, IPerson, IActiveFilters, ITheme } from '../interfaces';
import categoriesService from '../services/categories.service';
import locationsService from '../services/locations.service';
import organizationsService from '../services/organizations.service';
import personsService from '../services/persons.service';
import { CONFIG } from '../config';

interface IContextProvider {
  children: React.ReactNode;
}

const Context = React.createContext({});

export const useData = () => {
  return useContext(Context);
};

const ContextProvider = ({ children }: IContextProvider) => {
  const [page, setPage] = useState<string>(document.URL.split('/')[3] ? document.URL.split('/')[3] : 'map');
  const [organizations, setOrganizations] = useState<IPartialOrganization[]>();
  const [locations, setLocations] = useState<ILocation[]>();
  const [categories, setCategories] = useState<{
    [key: string]: ICategory[];
  }>();
  const [persons, setPersons] = useState<IPerson[]>();
  const [activeFilters, setActiveFilters] = useState<IActiveFilters>({});
  const [filteredOrganizations, setFilteredOrganizations] = useState<IPartialOrganization[]>([]);
  const [fontSize, setFontSize] = useState<number>(
    localStorage.fontSize ? Number(localStorage.getItem('fontSize')) : 16
  );
  const [searchReq, setSearchReq] = useState<string>('');
  const [findedItemsCount, setFindedItemsCount] = useState<number>(0);
  const [searchRes, setSearchRes] = useState<boolean>(false);
  const [theme, setTheme] = useState<ITheme>({
    primary: ' bg-white text-black',
    secondary: CONFIG.BLUE_OR_GREEN_DESIGN
      ? ' bg-[#D2DAFA] text-[#4765DA] placeholder:text-[#4765DA]'
      : ' bg-[#CEF0D8] text-[#207F4B] placeholder:text-[#207F4B]',
    active: CONFIG.BLUE_OR_GREEN_DESIGN ? ' bg-[#4765DA] text-white' : ' bg-[#207F4B] text-white', //////
    hover: CONFIG.BLUE_OR_GREEN_DESIGN ? ' md:hover:bg-[#D2DAFA]' : ' md:hover:bg-[#CEF0D8]', ////////
    // logoColor: "#FFF450",
    logoColor: '#bfbfbf',
    logoText: '#000000',
    styleButton: ' bg-[#e5e7e8] text-black',
    pointerItemBg: ' bg-[#FFFFFF]', /////////
    // pointerItem: " border-[#727272]",
    redBorder: CONFIG.BLUE_OR_GREEN_DESIGN ? ' border-[#4765DA]' : ' border-[#207F4B]', ///////
    redBorder2: CONFIG.BLUE_OR_GREEN_DESIGN ? ' border-[#A3B2EC]' : ' border-[#8FBFA5]',
    searchButton: ' bg-[#FFF450]',
  });
  // const [theme, setTheme] = useState<ITheme>(
  //   localStorage.theme
  //     ? JSON.parse(localStorage.getItem("theme")!)
  //     : {
  // 			primary: " bg-white text-black",
  // 			secondary: CONFIG.BLUE_OR_GREEN_DESIGN ? " bg-[#D2DAFA] text-[#4765DA] placeholder:text-[#4765DA]":" bg-[#CEF0D8] text-[#207F4B] placeholder:text-[#207F4B]",
  // 			active: CONFIG.BLUE_OR_GREEN_DESIGN ? " bg-[#4765DA] text-white": " bg-[#207F4B] text-white", //////
  // 			hover: CONFIG.BLUE_OR_GREEN_DESIGN ? " md:hover:bg-[#D2DAFA]": " md:hover:bg-[#CEF0D8]", ////////
  // 			// logoColor: "#FFF450",
  // 			logoColor: "#bfbfbf",
  // 			logoText: "#000000",
  // 			styleButton: " bg-[#e5e7e8] text-black",
  // 			pointerItemBg: " bg-[#FFFFFF]", /////////
  // 			// pointerItem: " border-[#727272]",
  // 			redBorder: CONFIG.BLUE_OR_GREEN_DESIGN ? " border-[#4765DA]" : " border-[#207F4B]", ///////
  // 			searchButton: " bg-[#FFF450]",
  //       }
  // );

  async function getData() {
    // первым грузим данные для фильтра
    const catsData = await categoriesService.fetchAll();
    setCategories(transformCategories(catsData));
    setActiveFilters(getActiveFilters(catsData));

    // грузим всё для показа карты
    const locsData = await locationsService.fetchAll();
    const orgsData = await organizationsService.fetchAll();
    setLocations(locsData);
    // повторно сбрасываем фильтр после загрузки данных для карты
    // setActiveFilters(getActiveFilters(catsData));
    const newOrgsData = transformOrganizations(orgsData, catsData);
    setOrganizations(newOrgsData);
    setFilteredOrganizations(newOrgsData);

    // персоны последними - они не важны при старте главной
    const persData = await personsService.fetchAll();
    setPersons(persData);
  }

  useEffect(() => {
    getData();
  }, []);

  document.documentElement.style.fontSize = fontSize + 'px';

  return (
    <Context.Provider
      value={{
        page,
        setPage,
        organizations,
        setOrganizations,
        locations,
        setLocations,
        categories,
        setCategories,
        activeFilters,
        setActiveFilters,
        filteredOrganizations,
        setFilteredOrganizations,
        fontSize,
        setFontSize,
        theme,
        setTheme,
        persons,
        setPersons,
        searchReq,
        setSearchReq,
        findedItemsCount,
        setFindedItemsCount,
        searchRes,
        setSearchRes,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;

import React, { useEffect, useState } from 'react';
import MainLayout from './mainLayout';
import Filter from '../components/filter';
import YandexMap from '../components/yandexMap';
import Loader from '../components/loader';
import { useData } from '../hooks/useContext';
import Search from '../components/search';
import PointerItem from '../components/pointerItem';
import Pagination from '../components/pagination';
import { paginate } from '../utils/paginate';
import { IUseData, IActiveFilters, IPartialOrganization, ICategory } from '../interfaces';
import Xsvg from '../components/SVG/Xsvg';
import { declOfNumText } from '../utils/helpers';
import { CONFIG } from '../config';
import PageCard from '../components/pageCard';

const MapPage = () => {
  const {
    setPage,
    organizations,
    locations,
    categories,
    persons,
    activeFilters,
    setActiveFilters,
    filteredOrganizations,
    setFilteredOrganizations,
    theme,
    searchRes,
    setSearchRes,
  } = useData() as IUseData;

  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const pageSize: number = 10;

  useEffect(() => {
    setPaginationPage(1);
  }, [activeFilters, searchRes]);

  useEffect(() => {
    setPage('map');
    document.title = CONFIG.MAIN_PAGE.HEADER1 + ' ' + CONFIG.MAIN_PAGE.HEADER2;
    setTimeout(() => {
      Array.from(document.getElementsByClassName('ymaps-2-1-79-copyright')).forEach((el) => el.remove());
    }, 500);
  }, []);

  return (
    <>
      <div className={showFilter ? 'fixed min-h-full bg-white w-full z-50 p-2' : 'hidden'}>
        <div className="relative">
          <button
            className={
              'text-center font-semibold absolute right-0 top-0 h-9 w-9 m-2 p-1 rounded-[5px] text-white' + theme.active
            }
            onClick={() => {
              setShowFilter(false);
              document.body.style.overflow = '';
            }}
            title="закрыть"
          >
            <Xsvg color="#ffffff" />
          </button>
          {categories && activeFilters ? <Filter /> : <Loader title={'Загрузка фильтра...'} />}
        </div>
      </div>
      <MainLayout>
        {/* <div className="m-auto w-full 2xl:w-4/5 h-100 md:px-12 flex flex-col px-2"> */}
        <PageCard />
        <Search />
        <div className="m-auto flex flex-col w-full">
          <div className="hidden md:flex w-full z-10 mt-5">
            {categories && activeFilters ? <Filter /> : <Loader title={'Загрузка фильтра...'} />}
          </div>
          <div className="w-full min-h-[58vh] mt-5">
            <button
              className={
                'md:hidden text-2xl font-semibold border-2 ' +
                theme.redBorder +
                ' rounded-[40px] p-5 w-full flex flex-row justify-between mb-5'
              }
              onClick={() => {
                setShowFilter(true);
                document.body.style.overflow = 'hidden';
              }}
            >
              <div>Фильтр</div>
              <div className={'w-8 h-8 ' + theme.active + ' text-white rounded-[5px]'}>=</div>
            </button>
            {categories && locations && filteredOrganizations ? (
              <YandexMap locs={locations} cats={categories} orgs={filteredOrganizations} pers={persons} theme={theme} />
            ) : (
              <Loader title={'Загрузка карты...'} />
            )}
            <hr className="h-0 opacity-0" />
            <main className={'flex flex-col rounded-[40px] p-[40px] mb-10 mt-5' + theme.active}>
              {filteredOrganizations.length !== 0 ? (
                <h2 className="mb-5 text-2xl font-medium">
                  {'На карте показаны ' +
                    declOfNumText(filteredOrganizations.length, ['организация', 'организации', 'организаций'], true) +
                    ':'}
                </h2>
              ) : null}
              <ul>
                {filteredOrganizations
                  ? paginate(filteredOrganizations, paginationPage, pageSize).map((org: IPartialOrganization) => (
                      <PointerItem
                        key={org._id}
                        name={org.name}
                        link={'organizations/' + org._id}
                        num={filteredOrganizations.indexOf(org) + 1}
                      />
                    ))
                  : null}
              </ul>
              <Pagination
                productCount={filteredOrganizations.length}
                pageSize={pageSize}
                currentPage={paginationPage}
                onPageChange={(page: number) => setPaginationPage(page)}
              />
            </main>
          </div>
        </div>
        {/* </div> */}
      </MainLayout>
    </>
  );
};

export default MapPage;

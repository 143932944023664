import React, { useEffect, useState } from 'react';
import MainLayout from './mainLayout';
import API from '../api';
import Loader from '../components/loader';
import { useData } from '../hooks/useContext';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { CONFIG } from '../config';
import { IUseData, IPerson, ILocation, IPartialLocation, IPartialOrganization } from '../interfaces';
import locationsService from '../services/locations.service';
import MenuOrganizationsSVG from '../components/SVG/menuSVG/menuOrganizations';
import MenuPersonsSVG from '../components/SVG/menuSVG/menuPersons';

const LocationPage = ({ match }: RouteComponentProps<{ locationId: string }>) => {
  const [location, setLocation] = useState<ILocation>();
  const { setPage, organizations, locations, persons, theme } = useData() as IUseData;

  async function getLoc(id: string) {
    const locData = await locationsService.getById(id);
    setLocation(locData);
    document.title = locData.adress;
  }

  function getPerson(person: string): IPerson | undefined {
    for (let i in persons) {
      if (persons[i]._id === person) {
        return persons[i];
      }
    }
  }

  function getOrganizations() {
    let newOrgs: IPartialOrganization[] = [];
    organizations.forEach((org: IPartialOrganization) => {
      if (org.locations.includes(location!._id) && !newOrgs.includes(org)) {
        newOrgs.push(org);
      }
    });
    return newOrgs;
  }

  useEffect(() => {
    setLocation(undefined);
    getLoc(match.params.locationId);
  }, [match.params.locationId]);

  useEffect(() => {
    setPage('locations');
  }, []);

  return (
    <MainLayout>
      {/* <div className="m-auto w-full 2xl:w-4/5 h-100 md:px-12 flex flex-col px-2"> */}
      {location && locations && persons ? (
        <>
          <div className="flex flex-col lg:flex-row mt-10">
            <div
              className={
                'w-full sm:w-4/5 md:w-3/5 lg:w-1/3 md:me-10 flex flex-col h-fit self-center lg:self-start p-5 rounded-[40px]' +
                theme.secondary
              }
            >
              <img
                src={
                  location.image
                    ? location.image?.includes('$')
                      ? location.image.split('$')[1]
                      : location.image
                    : '/IMG/no_img.jpg'
                }
                className=" w-full top-0 bottom-0 m-auto rounded-[20px] "
                alt={
                  location.image
                    ? location.image?.includes('$')
                      ? location.image.split('$')[0]
                      : 'Изображение ' + location.adress
                    : 'нет изображения'
                }
              />
              {location.image ? (
                <div className="text-center rounded-[20px] bg-white mt-5 px-[10px] py-5">
                  {location.image?.includes('$') ? location.image.split('$')[0] : 'Изображение ' + location.adress}
                </div>
              ) : null}
            </div>

            <main
              className={
                location.image
                  ? 'w-full lg:w-2/3 flex flex-col md:p-10 p-5 mt-5 md:mt-0 border-2 rounded-[40px] h-fit' +
                    theme.redBorder
                  : 'w-full flex flex-col border-2 mt-5 md:mt-0 rounded-[40px] h-fit' + theme.redBorder
              }
            >
              {/* <div className="flex flex-row"> */}
              <h2 className={'w-fit text-3xl pb-1 font-semibold' + theme.redBorder}>{location.adress}</h2>

              {location.alt_names && location.alt_names.length !== 0 ? (
                <div className="mt-10">
                  {CONFIG.LOCATION_PAGE.ALT_NAMES !== '' ? (
                    <h3 className={'w-fit border-b-2' + theme.redBorder2}>{CONFIG.LOCATION_PAGE.ALT_NAMES}</h3>
                  ) : null}
                  {location.alt_names.map((altName: string) => (
                    <div className="mt-3" key={altName}>
                      {altName}
                    </div>
                  ))}
                </div>
              ) : null}

              {location.dates ? (
                <div className="mt-10">
                  {CONFIG.LOCATION_PAGE.DATES !== '' ? (
                    <h3 className={'w-fit border-b-2' + theme.redBorder2}>{CONFIG.LOCATION_PAGE.DATES}</h3>
                  ) : null}
                  <div className="mt-3">{location.dates}</div>
                </div>
              ) : null}
              {/* </div> */}

              {location.persons && location.persons.length !== 0 ? (
                <div className="mt-10">
                  {CONFIG.LOCATION_PAGE.PERSONS !== '' ? (
                    <h3 className={'w-fit pb-1 border-b-2 flex flex-row' + theme.redBorder2}>
                      <MenuPersonsSVG color="#141B34" />
                      <span className="me-1"></span>
                      {CONFIG.LOCATION_PAGE.PERSONS}
                    </h3>
                  ) : null}
                  {location.persons.map((person: string) =>
                    CONFIG.SHOW_PERSONS ? (
                      <Link key={person} to={'/persons/' + person} className="block mt-3 w-fit">
                        {getPerson(person)?.name}
                      </Link>
                    ) : (
                      <div className="mt-3" key={person}>
                        {getPerson(person)?.name}
                      </div>
                    )
                  )}
                </div>
              ) : null}

              {location.info ? (
                <div className="mt-10">
                  {CONFIG.LOCATION_PAGE.INFO !== '' ? (
                    <h3 className={'w-fit border-b-2' + theme.redBorder2}>{CONFIG.LOCATION_PAGE.INFO}</h3>
                  ) : null}
                  <div className="mt-3">
                    {location.info.split('\n').map((subStr, i) => (
                      <span key={i}>
                        {subStr}
                        <br />
                      </span>
                    ))}
                  </div>
                </div>
              ) : null}

              {location ? (
                <div className="mt-10">
                  {CONFIG.LOCATION_PAGE.ORGANIZATIONS !== '' ? (
                    <h3 className={'w-fit pb-1 border-b-2 flex flex-row' + theme.redBorder2}>
                      <MenuOrganizationsSVG color="#141B34" />
                      <span className="me-1"></span>
                      {CONFIG.LOCATION_PAGE.ORGANIZATIONS}
                    </h3>
                  ) : null}
                  {getOrganizations().map((org: IPartialOrganization) => (
                    <Link key={org._id} to={'/organizations/' + org._id} className="block mt-3 w-fit">
                      {org.name}
                    </Link>
                  ))}
                </div>
              ) : null}

              {location.links && location.links.length !== 0 ? (
                <div className="mt-10">
                  {CONFIG.LOCATION_PAGE.LINKS !== '' ? (
                    <h3 className={'w-fit border-b-2' + theme.redBorder2}>{CONFIG.LOCATION_PAGE.LINKS}</h3>
                  ) : null}
                  {location.links.map((link: string) =>
                    link.includes('$') ? (
                      <a key={link} href={link.split('$')[1]} className=" block mt-3" target="_blank">
                        {link.split('$')[0] !== '' ? link.split('$')[0] : link.split('$')[1]}
                      </a>
                    ) : (
                      <div className="mt-3" key={link}>
                        {link}
                      </div>
                    )
                  )}
                </div>
              ) : null}
            </main>
          </div>
        </>
      ) : (
        <Loader title={'Загрузка информации об организации...'} />
      )}
      {/* </div> */}
    </MainLayout>
  );
};

export default LocationPage;

import React, { useState, useEffect } from 'react';
import MainLayout from './mainLayout';
import PointerItem from '../components/pointerItem';
import { useData } from '../hooks/useContext';
import Pagination from '../components/pagination';
import { paginate } from '../utils/paginate';
import Loader from '../components/loader';
import { IPerson, IUseData } from '../interfaces';
import PageCard from '../components/pageCard';

const PersonsList = () => {
  const { setPage, persons, theme } = useData() as IUseData;
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const pageSize: number = 16;

  useEffect(() => {
    setPage('persons');
    document.title = 'Люди';
  }, []);

  return (
    <MainLayout>
      {/* <div className="m-auto w-full 2xl:w-4/5 h-100 md:px-12 flex flex-col px-2"> */}
      <PageCard />
      <main className={'grow flex flex-col rounded-[40px] p-[40px] my-5' + theme.active}>
        {persons ? (
          <>
            <h2 className={'text-2xl font-medium mb-2 border-b-2' + theme.redBorder} autoFocus={true}>
              {'Люди (' + persons.length + ')'}
            </h2>
            <ul>
              {paginate<IPerson>(persons, paginationPage, pageSize).map((item: IPerson) => (
                <PointerItem
                  key={item._id}
                  name={item.name}
                  link={'persons/' + item._id}
                  num={persons.indexOf(item) + 1}
                />
              ))}
            </ul>
            <Pagination
              productCount={persons.length}
              pageSize={pageSize}
              currentPage={paginationPage}
              onPageChange={(page: number) => setPaginationPage(page)}
            />
          </>
        ) : (
          <Loader title={'Загрузка списка людей...'} />
        )}
      </main>
      {/* </div> */}
    </MainLayout>
  );
};

export default PersonsList;

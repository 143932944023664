import { personsAsObject as pers } from './persons.api';
import { delay } from '../../utils/helpers';

export const locationsAsObject = {
	"loc0": {
			"_id": "66704707396129a2d7bf1d61",
			"__v": 0,
			"adress": "13-я линия Васильевского острова, д. 20",
			"alt_names": [],
			"geo": "59.938112, 30.273180",
			"image": "Фасад здания$https://access.lbspb.ru/media/5f70115b-1354-4f17-8e49-f973e36989e9.jpeg",
			"info": "Кнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc1": {
			"_id": "66704707396129a2d7bf1d26",
			"__v": 0,
			"adress": "14-я линия Васильевского острова, д.39, лит. А",
			"alt_names": [],
			"geo": "59.939848, 30.269398",
			"image": "Фасад здания$https://access.lbspb.ru/media/b9b03d8e-e396-44cf-b89b-300fe059414a.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc2": {
			"_id": "66704707396129a2d7bf1d43",
			"__v": 0,
			"adress": "16-я линия Васильевского острова, д.65",
			"alt_names": [],
			"geo": "59.942349, 30.262876",
			"image": "Фасад здания$https://access.lbspb.ru/media/56624085-9fb9-40b5-ae90-3c6111e3df00.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc3": {
			"_id": "66704707396129a2d7bf1d05",
			"__v": 0,
			"adress": "17-я линия Васильевского острова, д. 14",
			"alt_names": [],
			"geo": "59.936174, 30.269246",
			"image": "Главный вход$https://access.lbspb.ru/media/693c3b01-af50-485f-ad3b-5f8b45692a30.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc4": {
			"_id": "66704707396129a2d7bf1d21",
			"__v": 0,
			"adress": "18-я линия Васильевского острова , д.1, лит. А",
			"alt_names": [],
			"geo": "59.931688, 30.271985",
			"image": "Фасад здания$https://access.lbspb.ru/media/c8e59ace-08a3-4a13-bdd5-275624d41f6c.jpeg",
			"info": "Кнопка вызова\nВизуальная поддержка.",
			"links": [],
			"persons": []
	},
	"loc5": {
			"_id": "667582b2396129a2d7c1ae25",
			"__v": 0,
			"adress": "4-я Красноармейская улица, д. 13",
			"alt_names": [],
			"geo": "59.913983, 30.312589",
			"image": "Фасад здания$https://access.lbspb.ru/media/9fb22301-6aa9-42aa-9d68-9c9ab67c8506.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc6": {
			"_id": "66704707396129a2d7bf1cd5",
			"__v": 0,
			"adress": "6-я линия Васильевского острова, д. 17",
			"alt_names": [],
			"geo": "59.941096, 30.281409",
			"info": "Здание закрыто на ремонт с 2023 года.",
			"links": [],
			"persons": []
	},
	"loc7": {
			"_id": "66743134396129a2d7c10d34",
			"__v": 0,
			"adress": "7-ая Красноармейская улица, д. 30",
			"alt_names": [],
			"geo": "59.911448, 30.308816",
			"image": "Фасад здания$https://access.lbspb.ru/media/af7779c2-09f0-4a4f-9b7a-cc7d2993c573.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc8": {
			"_id": "667e84f3396129a2d7c5c560",
			"__v": 0,
			"adress": "Александровский парк, д. 1, Лит. А",
			"alt_names": [],
			"geo": "59.953615, 30.309221",
			"image": "Главный вход$https://access.lbspb.ru/media/ca698f61-2b16-488a-95b4-c0dbcad47bad.jpeg",
			"info": "Доступные входные группы.",
			"links": [],
			"persons": []
	},
	"loc9": {
			"_id": "667ad212396129a2d7c408f3",
			"__v": 0,
			"adress": "Александровский парк, д. 4. Лит. М",
			"alt_names": [],
			"geo": "59.954939, 30.310496",
			"info": "Здание закрыто на ремонт.",
			"links": [],
			"persons": []
	},
	"loc10": {
			"_id": "667acd62396129a2d7c4062a",
			"__v": 0,
			"adress": "Александровский парк, д. 4. Лит.Т",
			"alt_names": [],
			"geo": "59.955683, 30.312733",
			"image": "Главный вход$https://access.lbspb.ru/media/1f659c00-bfd6-4906-a2e8-4e7dd76b9ffa.jpeg",
			"info": "Доступные входные группы\nСоциальная история.",
			"links": [],
			"persons": []
	},
	"loc11": {
			"_id": "6682b40a396129a2d7c79c7e",
			"__v": 0,
			"adress": "Английская набережная, д. 44",
			"alt_names": [],
			"geo": "59.932770, 30.289440",
			"image": "Фасад здания$https://access.lbspb.ru/media/15e99d6a-8fb9-440a-b7d9-3c7079e2681b.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc12": {
			"_id": "66704707396129a2d7bf1d6f",
			"__v": 0,
			"adress": "Бассейная улица, д. 17",
			"alt_names": [],
			"geo": "59.864408, 30.306669",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc13": {
			"_id": "66704707396129a2d7bf1d64",
			"__v": 0,
			"adress": "Бассейная улица, д. 45",
			"alt_names": [],
			"geo": "59.864602, 30.324474",
			"image": "Фасад здания$https://access.lbspb.ru/media/bf8c1527-8fe8-466a-b776-5909f1c66ae9.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc14": {
			"_id": "66704707396129a2d7bf1d67",
			"__v": 0,
			"adress": "Благодатная улица, д. 20",
			"alt_names": [],
			"geo": "59.876265, 30.316183",
			"image": "Фасад здания$https://access.lbspb.ru/media/6a8b0fc9-0fc4-4ace-825d-c31e4caaec7f.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc15": {
			"_id": "66729962396129a2d7c0403b",
			"__v": 0,
			"adress": "Богатырский проспект, д. 8",
			"alt_names": [],
			"geo": "59.998733, 30.284741",
			"image": "Фасад здания$https://access.lbspb.ru/media/5b1d52b5-b7fe-45ff-900d-e6d305a1d8da.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc16": {
			"_id": "66704707396129a2d7bf1dc9",
			"__v": 0,
			"adress": "Богатырский проспект, д. 9",
			"alt_names": [],
			"geo": "59.999422, 30.280322",
			"image": "Фасад здания$https://access.lbspb.ru/media/9bd1932f-9a7a-42cc-b1b3-471322ab775b.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc17": {
			"_id": "6672970b396129a2d7c03eb6",
			"__v": 0,
			"adress": "Богатырский проспект, д.36, к.1",
			"alt_names": [],
			"geo": "60.002778, 30.238227",
			"image": "Фасад здания$https://access.lbspb.ru/media/13282079-829c-4bb1-a038-f0c9cf8a16f8.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc18": {
			"_id": "667929db396129a2d7c33672",
			"__v": 0,
			"adress": "Большая Морская улица, д. 33, Лит А",
			"alt_names": [],
			"geo": "59.933438, 30.310873",
			"image": "Фасад здания$https://access.lbspb.ru/media/67ce67f0-0f5d-4a25-a446-fa76dcac1d70.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc19": {
			"_id": "66798093396129a2d7c368f1",
			"__v": 0,
			"adress": "Большеохтинский проспект, д. 8, Лит. А",
			"alt_names": [],
			"geo": "59.951146, 30.409212",
			"image": "Фасад здания$https://access.lbspb.ru/media/e2dc52ab-fe97-4349-9742-80c200c0e92d.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc20": {
			"_id": "66704707396129a2d7bf1d12",
			"__v": 0,
			"adress": "Большой Казачий переулок, д.7, кв.13",
			"alt_names": [],
			"geo": "59.923441, 30.327510",
			"image": "Фасад здания$https://access.lbspb.ru/media/f7ce1647-67af-4ad3-90d9-f9763199c4c9.jpeg",
			"info": "Визуальная поддержка.",
			"links": [],
			"persons": []
	},
	"loc21": {
			"_id": "66704707396129a2d7bf1de3",
			"__v": 0,
			"adress": "Большой Сампсониевский проспект, д.79",
			"alt_names": [],
			"geo": "59.988644, 30.334769",
			"info": "Доступные входные группы\nКнопка вызова\nСоциальная история\nВизуальная поддержка.",
			"links": [],
			"persons": [],
			"image": "Фасад здания$https://access.lbspb.ru/media/5e6e849a-2c66-4afc-8247-063fce38c16d.jpeg"
	},
	"loc22": {
			"_id": "66704707396129a2d7bf1da0",
			"__v": 0,
			"adress": "Большой проспект Петроградской стороны, д. 18",
			"alt_names": [],
			"geo": "59.955399, 30.294758",
			"image": "Фасад здания$https://access.lbspb.ru/media/9335c7ca-0933-49a7-8ef3-cefdebc04f7f.jpeg",
			"info": "Индукционные петли.",
			"links": [],
			"persons": []
	},
	"loc23": {
			"_id": "66704707396129a2d7bf1daf",
			"__v": 0,
			"adress": "Большой проспект Петроградской стороны, д. 65",
			"alt_names": [],
			"geo": "59.963773, 30.309257",
			"image": "Фасад здания$https://access.lbspb.ru/media/e305c830-6831-4c6f-a3b6-29963ce0a104.jpeg",
			"info": "Индукционные петли.",
			"links": [],
			"persons": []
	},
	"loc24": {
			"_id": "66757e03396129a2d7c1ab36",
			"__v": 0,
			"adress": "Бронницкая, д. 16",
			"alt_names": [],
			"geo": "59.915143, 30.322174",
			"image": "Фасад здания$https://access.lbspb.ru/media/638f49f3-d439-4229-9fb6-6074e3f103d7.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc25": {
			"_id": "66704707396129a2d7bf1d90",
			"__v": 0,
			"adress": "Бульвар Красных Зорь, д. 1, Лит. А",
			"alt_names": [],
			"geo": "59.872855, 30.445773",
			"image": "Фасад здания$https://access.lbspb.ru/media/e40fbbc8-8d59-496c-ae17-369eef611930.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc26": {
			"_id": "6673d375396129a2d7c0d3a7",
			"__v": 0,
			"adress": "Бухарестская улица, д. 122",
			"alt_names": [],
			"geo": "59.844927, 30.409338",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc27": {
			"_id": "6673d376396129a2d7c0d3b1",
			"__v": 0,
			"adress": "Бухарестская улица, д. 23, к.1",
			"alt_names": [],
			"geo": "59.873997, 30.376154",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc28": {
			"_id": "66704707396129a2d7bf1d53",
			"__v": 0,
			"adress": "Варшавская улица, д.37, к. 1",
			"alt_names": [],
			"geo": "59.859818, 30.313541",
			"image": "Фасад здания$https://access.lbspb.ru/media/009cf116-1e7c-41a4-a730-6f2cb60fc072.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc29": {
			"_id": "66704707396129a2d7bf1ddc",
			"__v": 0,
			"adress": "Владимирский проспект, д.12",
			"alt_names": [],
			"geo": "59.930056, 30.348189",
			"image": "Фасад здания$https://access.lbspb.ru/media/b4ea2179-2e2a-4562-bf3f-721b7fa93d07.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc30": {
			"_id": "66704707396129a2d7bf1df2",
			"__v": 0,
			"adress": "Выборгское шоссе, д. 369 к.3",
			"alt_names": [],
			"geo": "60.076906, 30.276648",
			"image": "Главный вход$https://access.lbspb.ru/media/a94fee8e-66cf-4205-be92-b9f26036fac1.jpeg",
			"info": "Индукционные петли.",
			"links": [],
			"persons": []
	},
	"loc31": {
			"_id": "667e76e3396129a2d7c5bcfc",
			"__v": 0,
			"adress": "Галерная улица, д. 33, Лит. А",
			"alt_names": [],
			"geo": "59.932951, 30.292674",
			"image": "Фасад здания$https://access.lbspb.ru/media/d74d0e19-1a3d-4fca-8f21-602ef7d42e6e.jpeg",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc32": {
			"_id": "667d5442396129a2d7c5364b",
			"__v": 0,
			"adress": "Гатчина, Красноармейский проспект, д.1",
			"alt_names": [],
			"geo": "59.563943, 30.108097",
			"info": "Кнопка вызова\nИндукционные петли\nСоциальная история\nВизуальная поддержка.",
			"links": [],
			"persons": []
	},
	"loc33": {
			"_id": "667d58f2396129a2d7c53928",
			"__v": 0,
			"adress": "Гатчина, улица Чкалова, д. 22 Лит. А",
			"alt_names": [],
			"geo": "59.558281, 30.121401",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли\nВизуальная поддержка.",
			"links": [],
			"persons": []
	},
	"loc34": {
			"_id": "66704707396129a2d7bf1e1d",
			"__v": 0,
			"adress": "Гражданский проспект, д. 104, к. 1",
			"alt_names": [],
			"geo": "60.027849, 30.411485",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc35": {
			"_id": "667982eb396129a2d7c36a74",
			"__v": 0,
			"adress": "Гражданский проспект, д. 121/100, Лит. А",
			"alt_names": [],
			"geo": "60.036016, 30.422265",
			"image": "Фасад здания$https://access.lbspb.ru/media/03e7f5bb-ff91-4141-9d30-8d63106206cc.jpeg",
			"info": "Здание закрыто на ремонт.",
			"links": [],
			"persons": []
	},
	"loc36": {
			"_id": "66704707396129a2d7bf1e07",
			"__v": 0,
			"adress": "Гражданский проспект, д. 83, к. 1",
			"alt_names": [],
			"geo": "60.019140, 30.404981",
			"image": "Главный вход$https://access.lbspb.ru/media/cb7d0167-7188-4a37-a9d1-cbf2f2c35aad.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc37": {
			"_id": "66704707396129a2d7bf1d8b",
			"__v": 0,
			"adress": "Дальневосточный проспект, д. 6 к. 1, Лит. А",
			"alt_names": [],
			"geo": "59.916987, 30.431526",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc38": {
			"_id": "66704707396129a2d7bf1e23",
			"__v": 0,
			"adress": "Дачный проспект, д. 16/7",
			"alt_names": [],
			"geo": "59.843620, 30.257307",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc39": {
			"_id": "667d2ec2396129a2d7c51f79",
			"__v": 0,
			"adress": "Елагин остров, д. 4, Лит. Б",
			"alt_names": [],
			"geo": "59.980267, 30.268509",
			"image": "Елагин дворец$https://access.lbspb.ru/media/f0137089-2450-4ef4-8119-77f955b9b254.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли\nСоциальная история.",
			"links": [],
			"persons": []
	},
	"loc40": {
			"_id": "66704707396129a2d7bf1dde",
			"__v": 0,
			"adress": "Загородный проспект, д. 27/21",
			"alt_names": [],
			"geo": "59.924217, 30.337086",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": [],
			"image": "Фасад здания$https://access.lbspb.ru/media/5067b17b-20c3-4e74-9eba-99d0cf521c73.jpeg"
	},
	"loc41": {
			"_id": "667bf003396129a2d7c48a64",
			"__v": 0,
			"adress": "Загородный проспект, д. 28",
			"alt_names": [],
			"geo": "59.925002, 30.340069",
			"image": "Фасад здания$https://access.lbspb.ru/media/481c5c08-7966-421c-80a0-acbd42b1bb8d.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc42": {
			"_id": "6673d375396129a2d7c0d3a9",
			"__v": 0,
			"adress": "Загребский бульвар, д. 21",
			"alt_names": [],
			"geo": "59.841527, 30.402026",
			"info": "Здание не адаптировано.",
			"links": [
					"$https://p3.citywalls.ru/photo_371-380431.jpg?mt=1548702789"
			],
			"persons": []
	},
	"loc43": {
			"_id": "66704707396129a2d7bf1cd0",
			"__v": 0,
			"adress": "Заневский проспект, д. 28-32",
			"alt_names": [],
			"geo": "59.931404, 30.422139",
			"image": "Главный вход$https://access.lbspb.ru/media/5eb3237b-f363-428e-aac1-59d5120b88d0.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc44": {
			"_id": "66704707396129a2d7bf1d3a",
			"__v": 0,
			"adress": "Зеленогорск, проспект Ленина, д. 25",
			"alt_names": [],
			"geo": "60.200796, 29.706828",
			"image": "Фасад здания$https://access.lbspb.ru/media/2fe58625-cf3f-48cd-804d-0b28527e33fa.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc45": {
			"_id": "66704707396129a2d7bf1d4c",
			"__v": 0,
			"adress": "Зеленогорск, проспект Ленина, д.12",
			"alt_names": [],
			"geo": "60.194374, 29.704457",
			"image": "Главный вход$https://access.lbspb.ru/media/5996ac00-cd65-4f4e-bc2e-52f1f0dcdf14.jpeg",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc46": {
			"_id": "66704707396129a2d7bf1d79",
			"__v": 0,
			"adress": "Ивановская улица, д. 14, Лит А",
			"alt_names": [],
			"geo": "59.874553, 30.442423",
			"image": "Фасад здания$https://access.lbspb.ru/media/a2818bc2-c54e-4cfc-a9c0-9ad885f49b64.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc47": {
			"_id": "66742edb396129a2d7c10b82",
			"__v": 0,
			"adress": "Измайловский проспект,  д. 18",
			"alt_names": [],
			"geo": "59.910654, 30.307828",
			"image": "Фасад здания$https://access.lbspb.ru/media/656da1bd-8829-4a35-9f1b-4b4ce719e586.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc48": {
			"_id": "667aac92396129a2d7c3f2bb",
			"__v": 0,
			"adress": "Измайловский проспект, д.8, Лит. А",
			"alt_names": [],
			"geo": "59.914110, 30.308295",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc49": {
			"_id": "66704707396129a2d7bf1cde",
			"__v": 0,
			"adress": "Индустриальный проспект, д. 15",
			"alt_names": [],
			"geo": "59.941953, 30.478355",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc50": {
			"_id": "66704707396129a2d7bf1ccd",
			"__v": 0,
			"adress": "Индустриальный проспект, д. 35  к. 1",
			"alt_names": [],
			"geo": "59.954232, 30.469866",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc51": {
			"_id": "66829ef3396129a2d7c79000",
			"__v": 0,
			"adress": "Исаакиевская площадь, д 4, лит А",
			"alt_names": [],
			"geo": "59.934073, 30.306274",
			"image": "https://p1.citywalls.ru/photo_522-534841.jpg?mt=1637157619$Фасад здания",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc52": {
			"_id": "66704707396129a2d7bf1d0c",
			"__v": 0,
			"adress": "Исаакиевская площадь, д. 1",
			"alt_names": [],
			"geo": "59.933988, 30.302474",
			"image": "Главный вход$https://access.lbspb.ru/media/f54ffb1b-7e84-4c64-8059-2f2bcfc0072e.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nСоциальная история.",
			"links": [],
			"persons": []
	},
	"loc53": {
			"_id": "6682852a396129a2d7c78197",
			"__v": 0,
			"adress": "Исаакиевский площадь, д 4, лит А",
			"alt_names": [],
			"geo": "59.934073, 30.306274",
			"image": "https://p1.citywalls.ru/photo_522-534841.jpg?mt=1637157619$Фасад здания",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc54": {
			"_id": "66704707396129a2d7bf1d87",
			"__v": 0,
			"adress": "Искровский проспект, д. 6, к. 4, Лит. Б",
			"alt_names": [],
			"geo": "59.913239, 30.461548",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc55": {
			"_id": "66704707396129a2d7bf1d9a",
			"__v": 0,
			"adress": "Каменноостровский проспект, д. 36/73",
			"alt_names": [],
			"geo": "59.964962, 30.311754",
			"image": "Фасад здания$https://access.lbspb.ru/media/728039a1-d237-4b59-9778-ee0dba6e9c31.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc56": {
			"_id": "66704707396129a2d7bf1da3",
			"__v": 0,
			"adress": "Кемская улица, д.8/3",
			"alt_names": [],
			"geo": "59.973523, 30.260918",
			"image": "Фасад здания$https://access.lbspb.ru/media/5051566d-7d7d-40a4-ab42-c6137ca5a8d2.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc57": {
			"_id": "667d51eb396129a2d7c534c6",
			"__v": 0,
			"adress": "Кирочная улица, д. 43, Лит. Б",
			"alt_names": [],
			"geo": "59.943070, 30.375480",
			"image": "Фасад здания$https://access.lbspb.ru/media/1f348cb1-8914-41e3-8019-80f307557e11.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc58": {
			"_id": "66704707396129a2d7bf1de6",
			"__v": 0,
			"adress": "Коломенская улица, д. 1/15",
			"alt_names": [],
			"geo": "59.926616, 30.355008",
			"image": "Фасад здания$https://access.lbspb.ru/media/fc7f1209-35ed-42ee-a60a-e1470c4a832d.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc59": {
			"_id": "66704707396129a2d7bf1e0b",
			"__v": 0,
			"adress": "Кондратьевский проспект, д. 51, к. 1",
			"alt_names": [],
			"geo": "59.973325, 30.389584",
			"info": "Здание не адаптировано.",
			"links": [
					"$https://p3.citywalls.ru/photo_128-131479.jpg?mt=1341448193"
			],
			"persons": []
	},
	"loc60": {
			"_id": "66704707396129a2d7bf1e0e",
			"__v": 0,
			"adress": "Кондратьевский проспект, д. 83, к. 1",
			"alt_names": [],
			"geo": "59.982257, 30.398315",
			"image": "Фасад здания$https://access.lbspb.ru/media/5ca11bb2-5e9d-4b0a-9f44-7698417540f7.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc61": {
			"_id": "66704707396129a2d7bf1d07",
			"__v": 0,
			"adress": "Красное Село, Проспект Ленина, д. 49/8",
			"alt_names": [],
			"geo": "59.741853, 30.090957",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc62": {
			"_id": "66704707396129a2d7bf1cf5",
			"__v": 0,
			"adress": "Красное Село, п. Дудергоф,Театральная улица, д. 15, лит А",
			"alt_names": [],
			"geo": "59.697015, 30.125344",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc63": {
			"_id": "66704707396129a2d7bf1cf1",
			"__v": 0,
			"adress": "Красное Село, улица Лермонтова, д. 26",
			"alt_names": [],
			"geo": "59.732883, 30.081866",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc64": {
			"_id": "66704707396129a2d7bf1cf9",
			"__v": 0,
			"adress": "Красное село, Кингисеппское шоссе, д. 2",
			"alt_names": [],
			"geo": "59.723285, 30.077410",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc65": {
			"_id": "66704707396129a2d7bf1d1b",
			"__v": 0,
			"adress": "Кронштадт, Флотская улица, д. 5",
			"alt_names": [],
			"geo": "59.996632, 29.776645",
			"image": "Фасад здания$https://access.lbspb.ru/media/ee5f189a-ff0f-4074-bd92-b3aa21964909.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc66": {
			"_id": "66704707396129a2d7bf1d18",
			"__v": 0,
			"adress": "Кронштадт, улица Аммермана, д. 27",
			"alt_names": [],
			"geo": "59.987339, 29.785161",
			"image": "Фасад здания$https://access.lbspb.ru/media/dbbc7876-52b4-415e-9206-177d95881540.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc67": {
			"_id": "66704707396129a2d7bf1d1e",
			"__v": 0,
			"adress": "Кронштадт, улица Гидростроителей, д. 6",
			"alt_names": [],
			"geo": "60.011509, 29.720025",
			"image": "Фасад здания$https://access.lbspb.ru/media/36ed905c-c361-4c57-a350-9aaf534129b2.jpeg",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc68": {
			"_id": "66704707396129a2d7bf1d15",
			"__v": 0,
			"adress": "Кронштадт, улица Советская, д. 49",
			"alt_names": [],
			"geo": "59.995809, 29.767536",
			"image": "Фасад здания$https://access.lbspb.ru/media/af1007c0-2878-42ac-b23a-5a9f370f70c0.jpeg",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc69": {
			"_id": "667eaa73396129a2d7c5db57",
			"__v": 0,
			"adress": "Кузнечный переулок, д. 5/2",
			"alt_names": [],
			"geo": "59.927310, 30.350840",
			"image": "Фасад здания$https://access.lbspb.ru/media/9f4f23c8-c45b-4da0-9271-4b6f240dfac8.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc70": {
			"_id": "66704707396129a2d7bf1dd6",
			"__v": 0,
			"adress": "Ланское шоссе, д .24, к. 5",
			"alt_names": [],
			"geo": "59.998301, 30.319875",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc71": {
			"_id": "66704708396129a2d7bf1e2f",
			"__v": 0,
			"adress": "Ленинский проспект, д 135",
			"alt_names": [],
			"geo": "59.851390, 30.275174",
			"image": "Главный вход$https://access.lbspb.ru/media/c02f2f07-1013-4450-bb65-16ce716a1ef8.jpeg",
			"info": "Доступные входные группы\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc72": {
			"_id": "66704707396129a2d7bf1cac",
			"__v": 0,
			"adress": "Ленинский проспект, д. 115",
			"alt_names": [],
			"geo": "59.851227, 30.247551",
			"image": "Главный вход$https://access.lbspb.ru/media/7fbcfee7-8130-4a68-ae62-9c914bee2368.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc73": {
			"_id": "66704707396129a2d7bf1d58",
			"__v": 0,
			"adress": "Ленинский проспект, д. 161",
			"alt_names": [],
			"geo": "59.851801, 30.318913",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc74": {
			"_id": "66704707396129a2d7bf1d00",
			"__v": 0,
			"adress": "Ленинский проспект, д. 92, к. 1",
			"alt_names": [],
			"geo": "59.854847, 30.212113",
			"image": "Фасад здания$https://access.lbspb.ru/media/e449f81b-64d8-4458-94c0-1ea085b7c528.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc75": {
			"_id": "66704707396129a2d7bf1d02",
			"__v": 0,
			"adress": "Ленинский проспект, д. 97, к. 3, лит. А",
			"alt_names": [],
			"geo": "59.851733, 30.213909",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc76": {
			"_id": "667acb0b396129a2d7c404aa",
			"__v": 0,
			"adress": "Лиговский проспект,  д.148",
			"alt_names": [],
			"geo": "59.913194, 30.349896",
			"image": "Фасад здания$https://access.lbspb.ru/media/4d3287a5-48bf-444c-90ce-21bb09deb315.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc77": {
			"_id": "6673d375396129a2d7c0d39f",
			"__v": 0,
			"adress": "Лиговский проспект, д. 215",
			"alt_names": [],
			"geo": "59.910762, 30.347228",
			"image": "Фасад здания$https://access.lbspb.ru/media/a2a6c499-af19-4957-9cab-03aa60b0ad78.jpeg",
			"info": "Здание закрыто на ремонт.",
			"links": [],
			"persons": []
	},
	"loc78": {
			"_id": "667d695b396129a2d7c543c4",
			"__v": 0,
			"adress": "Лиговский проспект, д. 6, Лит. А",
			"alt_names": [],
			"geo": "59.935610, 30.365383",
			"image": "Фасад здания$https://access.lbspb.ru/media/0152e3cb-d151-48d4-bb09-1fc316498cc9.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc79": {
			"_id": "667427d3396129a2d7c106f6",
			"__v": 0,
			"adress": "Лиговский проспект, д. 99",
			"alt_names": [],
			"geo": "59.922512, 30.354774",
			"image": "Фасад здания$https://access.lbspb.ru/media/e945cd7c-57c7-4d9a-895e-e18664ea719f.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc80": {
			"_id": "6674176b396129a2d7c0fcaa",
			"__v": 0,
			"adress": "Литейный проспект, д. 17-19",
			"alt_names": [],
			"geo": "59.943629, 30.348046",
			"image": "Фасад здания$https://access.lbspb.ru/media/a05792a9-31ff-4b77-ba42-93ddf155e640.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc81": {
			"_id": "66704707396129a2d7bf1de1",
			"__v": 0,
			"adress": "Литейный проспект, д. 53",
			"alt_names": [],
			"geo": "59.936332, 30.347713",
			"image": "Портрет у главного входа в музей$https://access.lbspb.ru/media/b8effda3-d508-4d7a-b689-9eea52a736b2.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nСоциальная история.",
			"links": [],
			"persons": []
	},
	"loc82": {
			"_id": "6673d375396129a2d7c0d3ab",
			"__v": 0,
			"adress": "Малая Балканская улица, д. 58",
			"alt_names": [],
			"geo": "59.830431, 30.426280",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc83": {
			"_id": "6682a3a3396129a2d7c792d5",
			"__v": 0,
			"adress": "Малая Конюшенная улица, д. 4/2, лит И",
			"alt_names": [],
			"geo": "59.937941, 30.326289",
			"image": "Главный вход$https://access.lbspb.ru/media/d005f14c-22d4-42b0-8aeb-db32433dc3e3.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc84": {
			"_id": "66704707396129a2d7bf1ced",
			"__v": 0,
			"adress": "Морская набережная, д. 17",
			"alt_names": [],
			"geo": "59.943755, 30.207226",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc85": {
			"_id": "66704707396129a2d7bf1d2f",
			"__v": 0,
			"adress": "Московский проспект, д. 121 Лит. А",
			"alt_names": [],
			"geo": "59.887530, 30.318689",
			"image": "Фасад здания$https://access.lbspb.ru/media/594ff771-e852-4ebd-820b-6e4a2401b737.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc86": {
			"_id": "66704707396129a2d7bf1d6a",
			"__v": 0,
			"adress": "Московский проспект, д. 150",
			"alt_names": [],
			"geo": "59.885070, 30.320575",
			"image": "Фасад здания$https://access.lbspb.ru/media/02f2544c-4464-4197-a66f-7cb04a2c70a8.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc87": {
			"_id": "66743a92396129a2d7c1133e",
			"__v": 0,
			"adress": "Московский проспект, д. 50/42",
			"alt_names": [],
			"geo": "59.910726, 30.318788",
			"image": "Фасад здания$https://access.lbspb.ru/media/9ef9491e-17db-4d30-abb7-ad4d265e7cb0.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc88": {
			"_id": "66798092396129a2d7c368ec",
			"__v": 0,
			"adress": "Московский проспект, д. 86",
			"alt_names": [],
			"geo": "59.903322, 30.319156",
			"image": "Фасад здания$https://access.lbspb.ru/media/3f63d4ae-678b-4047-8d29-4bbc0994e5f7.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc89": {
			"_id": "66704707396129a2d7bf1d5b",
			"__v": 0,
			"adress": "Московское шоссе, д. 2",
			"alt_names": [],
			"geo": "59.841762, 30.325983",
			"image": "Главный вход$https://access.lbspb.ru/media/44111640-4c1c-4882-9a3d-18d951878c94.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc90": {
			"_id": "66704707396129a2d7bf1dd2",
			"__v": 0,
			"adress": "Набережная Черной речки, д.12",
			"alt_names": [],
			"geo": "59.989850, 30.299339",
			"image": "Главный вход$https://access.lbspb.ru/media/bfac50a1-13a0-4e0d-8859-26631d4f4218.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc91": {
			"_id": "66704707396129a2d7bf1d0f",
			"__v": 0,
			"adress": "Набережная канала Грибоедова, д. 103",
			"alt_names": [],
			"geo": "59.926449, 30.300031",
			"image": "Главный вход в музей$https://access.lbspb.ru/media/7f049685-b464-41cc-913a-eca86e36b128.jpeg",
			"info": "Социальная история\nВизуальная поддержка.",
			"links": [],
			"persons": []
	},
	"loc92": {
			"_id": "66704707396129a2d7bf1dac",
			"__v": 0,
			"adress": "Набережная реки Карповки, д. 28",
			"alt_names": [],
			"geo": "59.969421, 30.302510",
			"image": "Фасад здания$https://access.lbspb.ru/media/b269e8e4-14a5-4444-a664-806ad5c850a2.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc93": {
			"_id": "667d64ab396129a2d7c540ab",
			"__v": 0,
			"adress": "Набережная реки Мойки, д. 20",
			"alt_names": [],
			"geo": "59.940019, 30.321069",
			"image": "Главный вход$https://access.lbspb.ru/media/77d19231-7609-48ad-b789-c35450b0e0a9.jpeg",
			"info": "Доступные входные группы.",
			"links": [],
			"persons": []
	},
	"loc94": {
			"_id": "667bf003396129a2d7c48a60",
			"__v": 0,
			"adress": "Набережная реки Фонтанки, д. 34",
			"alt_names": [],
			"geo": "59.936453, 30.345459",
			"image": "Фасад здания$https://access.lbspb.ru/media/c703e13f-643f-47d2-90e0-fa3f67c21a2f.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc95": {
			"_id": "667d6bb2396129a2d7c54547",
			"__v": 0,
			"adress": "Набережная реки Фонтанки, д. 41, Лит. А",
			"alt_names": [],
			"geo": "59.930633, 30.339332",
			"image": "Концертный зал \"Дом Кочневой\"$https://access.lbspb.ru/media/ebf0b9ee-cc7f-4ac6-9045-9e33b829757b.jpeg",
			"info": "Кнопка вызова\nСоциальная история.",
			"links": [],
			"persons": []
	},
	"loc96": {
			"_id": "66797be3396129a2d7c365de",
			"__v": 0,
			"adress": "Набережная реки Фонтанки, д. 44",
			"alt_names": [],
			"geo": "59.932324, 30.343689",
			"image": "Фасад здания$https://access.lbspb.ru/media/d792df55-f1a6-4a76-9726-4815a3ee5e26.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc97": {
			"_id": "6679798a396129a2d7c36457",
			"__v": 0,
			"adress": "Набережная реки Фонтанки, д. 46",
			"alt_names": [],
			"geo": "59.931806, 30.343294",
			"image": "Фасад здания$https://access.lbspb.ru/media/0af8e107-b78c-4d39-a56a-be034ee53fce.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc98": {
			"_id": "667eb17a396129a2d7c5df88",
			"__v": 0,
			"adress": "Невский проспект,  д. 179",
			"alt_names": [],
			"geo": "59.922814, 30.384929",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc99": {
			"_id": "66797e3b396129a2d7c3675e",
			"__v": 0,
			"adress": "Невский проспект, д. 20",
			"alt_names": [],
			"geo": "59.936323, 30.321159",
			"image": "Фасад здания$https://access.lbspb.ru/media/9b16dd05-8b1f-47a2-965b-c47ca9219399.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc100": {
			"_id": "667d7062396129a2d7c54879",
			"__v": 0,
			"adress": "Невский проспект, д. 41",
			"alt_names": [],
			"geo": "59.932802, 30.344731",
			"image": "Фасад здания$https://access.lbspb.ru/media/3c7dfed1-d045-4465-a904-70b269040969.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc101": {
			"_id": "667ab39b396129a2d7c3f6e7",
			"__v": 0,
			"adress": "Невский проспект, д. 52",
			"alt_names": [],
			"geo": "59.934461, 30.335793",
			"image": "Главный вход$https://access.lbspb.ru/media/2bacd81e-db9a-4f9a-a0b7-a686ca4ee12c.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc102": {
			"_id": "66743a92396129a2d7c11339",
			"__v": 0,
			"adress": "Новгородская улица, д. 27",
			"alt_names": [],
			"geo": "59.942268, 30.392297",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc103": {
			"_id": "66704707396129a2d7bf1ccb",
			"__v": 0,
			"adress": "Новочеркасский проспект, д. 49/20",
			"alt_names": [],
			"geo": "59.925191, 30.415204",
			"image": "Главный вход$https://access.lbspb.ru/media/0c4aab4c-a503-43ab-aeea-b7438d4944e7.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc104": {
			"_id": "66704707396129a2d7bf1d93",
			"__v": 0,
			"adress": "Октябрьская набережная, д. 100, к. 1",
			"alt_names": [],
			"geo": "59.870962, 30.476217",
			"image": "Фасад здания$https://access.lbspb.ru/media/78c99d41-be3c-4c33-8428-1eea8c458e45.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc105": {
			"_id": "66704707396129a2d7bf1d76",
			"__v": 0,
			"adress": "Октябрьская набережная, д. 64, к. 1, Лит. В",
			"alt_names": [],
			"geo": "59.880794, 30.454191",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc106": {
			"_id": "66704707396129a2d7bf1d8d",
			"__v": 0,
			"adress": "Октябрьская набережная, д. 70, к. 1, Лит. А",
			"alt_names": [],
			"geo": "59.876255, 30.458637",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc107": {
			"_id": "6673d374396129a2d7c0d383",
			"__v": 0,
			"adress": "Павловск, улица Конюшенная, д.16/13",
			"alt_names": [],
			"geo": "59.683286, 30.438883",
			"image": "Фасад здания$https://access.lbspb.ru/media/dc31d44c-74cb-43bf-bab9-e4ad72002c04.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc108": {
			"_id": "6673d374396129a2d7c0d38f",
			"__v": 0,
			"adress": "Павловск, улица Первого Мая, д. 6",
			"alt_names": [],
			"geo": "59.685853, 30.441075",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc109": {
			"_id": "66704707396129a2d7bf1d7e",
			"__v": 0,
			"adress": "Переулок Каховского, д. 5б",
			"alt_names": [],
			"geo": "59.952916, 30.240499",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc110": {
			"_id": "6682a852396129a2d7c795a9",
			"__v": 0,
			"adress": "Петропавловская крепость, д. 3",
			"alt_names": [],
			"geo": "59.949551, 30.316641",
			"image": "Дирекция музея$https://access.lbspb.ru/media/6a5c34e4-3f5c-464b-a236-10639d3b0c3b.jpeg",
			"info": "Доступные входные группы.",
			"links": [],
			"persons": []
	},
	"loc111": {
			"_id": "66704707396129a2d7bf1e09",
			"__v": 0,
			"adress": "Пискарёвский проспект, д. 10",
			"alt_names": [],
			"geo": "59.964863, 30.408422",
			"info": "Доступные входные группы.",
			"links": [],
			"persons": []
	},
	"loc112": {
			"_id": "66704707396129a2d7bf1e1f",
			"__v": 0,
			"adress": "Пискарёвский проспект, д. 16",
			"alt_names": [],
			"geo": "59.967804, 30.409589",
			"info": "Здание закрыто на ремонт.",
			"links": [],
			"persons": []
	},
	"loc113": {
			"_id": "66704707396129a2d7bf1d6d",
			"__v": 0,
			"adress": "Площадь Чернышевского, д. 6",
			"alt_names": [],
			"geo": "59.868482, 30.317404",
			"image": "Фасад здания$https://access.lbspb.ru/media/de8305b7-9bca-4fa2-93cb-bb94030c3c4d.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc114": {
			"_id": "6674383c396129a2d7c111b7",
			"__v": 0,
			"adress": "Проспект Бакунина, д. 2",
			"alt_names": [],
			"geo": "59.929831, 30.371869",
			"image": "Фасад здания$https://access.lbspb.ru/media/d232b39c-4bfb-4e20-9ac1-d826769b7bc7.jpeg",
			"info": "Здание закрыто на ремонт.",
			"links": [],
			"persons": []
	},
	"loc115": {
			"_id": "66704707396129a2d7bf1d74",
			"__v": 0,
			"adress": "Проспект Большевиков, д. 2, Лит. Б",
			"alt_names": [],
			"geo": "59.919355, 30.472498",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc116": {
			"_id": "66704707396129a2d7bf1d09",
			"__v": 0,
			"adress": "Проспект Ветеранов, д. 118, к.1",
			"alt_names": [],
			"geo": "59.831960, 30.185307",
			"info": "Кнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc117": {
			"_id": "66704707396129a2d7bf1cfd",
			"__v": 0,
			"adress": "Проспект Ветеранов, д. 146/22",
			"alt_names": [],
			"geo": "59.833927, 30.157127",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc118": {
			"_id": "66704707396129a2d7bf1ce7",
			"__v": 0,
			"adress": "Проспект Ветеранов, д. 155",
			"alt_names": [],
			"geo": "59.835853, 30.129342",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc119": {
			"_id": "66704708396129a2d7bf1e29",
			"__v": 0,
			"adress": "Проспект Ветеранов, д. 76",
			"alt_names": [],
			"geo": "59.833909, 30.220943",
			"image": "Фасад здания$https://access.lbspb.ru/media/7c9c0b0e-42db-4f0f-b1c6-fc4583d9287f.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc120": {
			"_id": "66704707396129a2d7bf1cba",
			"__v": 0,
			"adress": "Проспект КИМа, д. 4В",
			"alt_names": [],
			"geo": "59.948005, 30.238784",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc121": {
			"_id": "667a9c2a396129a2d7c3e90d",
			"__v": 0,
			"adress": "Проспект КИМа, д. 6",
			"alt_names": [],
			"geo": "59.950839, 30.246060",
			"image": "Фасад здания$https://access.lbspb.ru/media/6257c45d-0513-444c-a72c-d0e4001568ac.jpeg",
			"info": "Доступные входные группы.",
			"links": [],
			"persons": []
	},
	"loc122": {
			"_id": "66704707396129a2d7bf1ce0",
			"__v": 0,
			"adress": "Проспект Косыгина, д. 28, к. 1",
			"alt_names": [],
			"geo": "59.945833, 30.494741",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc123": {
			"_id": "66704707396129a2d7bf1e15",
			"__v": 0,
			"adress": "Проспект Культуры, д. 21, к. 1",
			"alt_names": [],
			"geo": "60.046046, 30.377475",
			"info": "Доступные входные группы.",
			"links": [],
			"persons": []
	},
	"loc124": {
			"_id": "66704707396129a2d7bf1cdc",
			"__v": 0,
			"adress": "Проспект Маршака, д. 24, к.1",
			"alt_names": [],
			"geo": "60.027004, 30.453077",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc125": {
			"_id": "66704707396129a2d7bf1cda",
			"__v": 0,
			"adress": "Проспект Маршала Тухачевского, д. 31",
			"alt_names": [],
			"geo": "59.971235, 30.423792",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc126": {
			"_id": "66704708396129a2d7bf1e32",
			"__v": 0,
			"adress": "Проспект Науки, д. 44",
			"alt_names": [],
			"geo": "60.006377, 30.426379",
			"image": "Фасад здания$https://access.lbspb.ru/media/e2cf8e77-8676-4e3f-bb94-30b1c9aa7f0d.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc127": {
			"_id": "66704707396129a2d7bf1e18",
			"__v": 0,
			"adress": "Проспект Непокорённых, д. 16, к. 1",
			"alt_names": [],
			"geo": "59.996803, 30.382514",
			"image": "Фасад здания$https://access.lbspb.ru/media/9541c7f2-bca4-4a97-b77d-60fa7c58fc2b.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc128": {
			"_id": "66704707396129a2d7bf1e13",
			"__v": 0,
			"adress": "Проспект Непокорённых, д. 74",
			"alt_names": [],
			"geo": "59.994171, 30.431868",
			"image": "Главный вход$https://access.lbspb.ru/media/bf335992-bb03-4b6c-ae04-8a3b4317ced8.jpeg",
			"info": "Доступные входные группы.",
			"links": [],
			"persons": []
	},
	"loc129": {
			"_id": "66704707396129a2d7bf1e01",
			"__v": 0,
			"adress": "Проспект Пархоменко, д. 18",
			"alt_names": [],
			"geo": "60.000308, 30.336700",
			"image": "Фасад здания$https://access.lbspb.ru/media/854a524f-603d-4cfa-be71-28badd622bd1.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc130": {
			"_id": "667435e3396129a2d7c11023",
			"__v": 0,
			"adress": "Проспект Римского-Корсакова, д. 16/2",
			"alt_names": [],
			"geo": "59.923946, 30.302097",
			"image": "Фасад здания$https://access.lbspb.ru/media/15eb792c-cc07-43e2-9ff6-bcb742039ec5.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc131": {
			"_id": "66704707396129a2d7bf1df9",
			"__v": 0,
			"adress": "Проспект Скобелевский, д. 4",
			"alt_names": [],
			"geo": "60.018151, 30.320117",
			"image": "Фасад здания$https://access.lbspb.ru/media/b69bad8d-553a-422a-9201-d41f4da3f162.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc132": {
			"_id": "66704707396129a2d7bf1dd4",
			"__v": 0,
			"adress": "Проспект Тореза, д.32",
			"alt_names": [],
			"geo": "60.004415, 30.356122",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc133": {
			"_id": "66704707396129a2d7bf1dfe",
			"__v": 0,
			"adress": "Проспект Художников, д. 9",
			"alt_names": [],
			"geo": "60.035405, 30.344497",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc134": {
			"_id": "66704707396129a2d7bf1db6",
			"__v": 0,
			"adress": "Проспект Энгельса, д. 111",
			"alt_names": [],
			"geo": "60.035958, 30.323710",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc135": {
			"_id": "66704707396129a2d7bf1dec",
			"__v": 0,
			"adress": "Проспект Энгельса, д. 53",
			"alt_names": [],
			"geo": "60.015538, 30.323324",
			"image": "Главный вход$https://access.lbspb.ru/media/b33c392c-445a-40cc-9e4e-1d8525f88a38.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc136": {
			"_id": "66704707396129a2d7bf1cd3",
			"__v": 0,
			"adress": "Проспект Энергетиков, д. 30, к. 5",
			"alt_names": [],
			"geo": "59.956471, 30.432469",
			"image": "Главный вход$https://access.lbspb.ru/media/56d77d51-62fe-4361-9ca3-b0218cbce43d.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc137": {
			"_id": "66704707396129a2d7bf1d56",
			"__v": 0,
			"adress": "Проспект Юрия Гагарина, д. 17",
			"alt_names": [],
			"geo": "59.863089, 30.334850",
			"image": "Главный вход$https://access.lbspb.ru/media/36ff4746-56af-42ba-a1a9-6165e25049ad.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc138": {
			"_id": "6673d374396129a2d7c0d385",
			"__v": 0,
			"adress": "Пушкин, Кадетский бульвар, д.22, Лит. А",
			"alt_names": [],
			"geo": "59.705001, 30.407101",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc139": {
			"_id": "6673d374396129a2d7c0d38b",
			"__v": 0,
			"adress": "Пушкин, Красносельское шоссе, д. 29",
			"alt_names": [],
			"geo": "59.705877, 30.367530",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc140": {
			"_id": "6673d374396129a2d7c0d389",
			"__v": 0,
			"adress": "Пушкин, улица Вячеслава Шишкова, д. 32/15",
			"alt_names": [],
			"geo": "59.736720, 30.405646",
			"image": "Фасад здания$https://access.lbspb.ru/media/6d6ac527-b41c-47f9-84c2-fbf45a0b5215.jpeg",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc141": {
			"_id": "6673d373396129a2d7c0d37e",
			"__v": 0,
			"adress": "Пушкин, улица Ленинградская, д. 36",
			"alt_names": [],
			"geo": "59.729345, 30.408529",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc142": {
			"_id": "6672b582396129a2d7c050a5",
			"__v": 0,
			"adress": "Пушкин,Малая улица, д. 20",
			"alt_names": [],
			"geo": "59.719623, 30.402124",
			"image": "Фасад здания$https://access.lbspb.ru/media/3e71ce81-6ca9-4a27-a541-7b5ef053e4e5.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc143": {
			"_id": "6673d376396129a2d7c0d3af",
			"__v": 0,
			"adress": "Расстанная улица, д.16",
			"alt_names": [],
			"geo": "59.906977, 30.349007",
			"image": "Фасад здания$https://access.lbspb.ru/media/140f4a62-203e-44a9-ba7d-3285449c1be7.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc144": {
			"_id": "667aaeea396129a2d7c3f417",
			"__v": 0,
			"adress": "Садовая улица, д. 27",
			"alt_names": [],
			"geo": "59.929290, 30.323171",
			"image": "Главный вход$https://access.lbspb.ru/media/ef0d8af0-1330-42d2-a441-7b95034301b0.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nСоциальная история\nВизуальная поддержка.",
			"links": [],
			"persons": []
	},
	"loc145": {
			"_id": "66742c83396129a2d7c109d4",
			"__v": 0,
			"adress": "Садовая улица, д. 33",
			"alt_names": [],
			"geo": "59.928293, 30.320809",
			"image": "Фасад здания$https://access.lbspb.ru/media/cf5461c3-2680-4cc3-a52f-9d41d31c27d0.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc146": {
			"_id": "66704707396129a2d7bf1e10",
			"__v": 0,
			"adress": "Светлановский проспект, д. 62, к. 1",
			"alt_names": [],
			"geo": "60.038389, 30.396707",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc147": {
			"_id": "66704707396129a2d7bf1d4e",
			"__v": 0,
			"adress": "Сестрорецк, Приморское шоссе, д.282",
			"alt_names": [],
			"geo": "60.094761, 29.974068",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc148": {
			"_id": "66704707396129a2d7bf1d49",
			"__v": 0,
			"adress": "Сестрорецк, улица Красных Командиров, д. 25",
			"alt_names": [],
			"geo": "60.048570, 29.976997",
			"image": "Фасад здания$https://access.lbspb.ru/media/66f9931c-753e-436d-9fbc-d1e37311aad4.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc149": {
			"_id": "66704707396129a2d7bf1d37",
			"__v": 0,
			"adress": "Сестрорецк, улица Токарева, д. 7",
			"alt_names": [],
			"geo": "60.088153, 29.955958",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc150": {
			"_id": "66704707396129a2d7bf1d50",
			"__v": 0,
			"adress": "Сестрорецк, улица Токарева, д.10",
			"alt_names": [],
			"geo": "60.087219, 29.952895",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc151": {
			"_id": "667e8043396129a2d7c5c293",
			"__v": 0,
			"adress": "Смольный проезд, д.1, Лит. Б",
			"alt_names": [],
			"geo": "59.948374, 30.393572",
			"image": "Фасад здания$https://access.lbspb.ru/media/021e967a-6205-4877-a1af-eca709b34319.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc152": {
			"_id": "66704707396129a2d7bf1d29",
			"__v": 0,
			"adress": "Соляной переулок, д. 9",
			"alt_names": [],
			"geo": "59.944440, 30.340868",
			"image": "Главный вход$https://access.lbspb.ru/media/23369572-91cc-4d5a-b261-c525902bdb22.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc153": {
			"_id": "6673d374396129a2d7c0d39b",
			"__v": 0,
			"adress": "Софийская улица, д. 46, к. 2",
			"alt_names": [],
			"geo": "59.870212, 30.415339",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc154": {
			"_id": "66704707396129a2d7bf1cc8",
			"__v": 0,
			"adress": "Среднеохтинский проспект, д. 8",
			"alt_names": [],
			"geo": "59.948037, 30.413093",
			"image": "Главный вход$https://access.lbspb.ru/media/8fe065a2-c384-4695-a40e-8a67e93c6cf3.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc155": {
			"_id": "66704707396129a2d7bf1d97",
			"__v": 0,
			"adress": "Средний проспект Васильевского острова, д. 3/15",
			"alt_names": [],
			"geo": "59.945995, 30.287562",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc156": {
			"_id": "667e68d3396129a2d7c5b4e2",
			"__v": 0,
			"adress": "Средний проспект Васильевского острова, д. 93",
			"alt_names": [],
			"geo": "59.933875, 30.245934",
			"image": "Фасад зданий$https://access.lbspb.ru/media/3586f321-9c37-4748-a4f0-d6fc30faefb3.jpeg",
			"info": "Здание закрыто на ремонт.",
			"links": [],
			"persons": []
	},
	"loc157": {
			"_id": "66704707396129a2d7bf1d23",
			"__v": 0,
			"adress": "Средний проспект Васильевского острова, д. 99/18",
			"alt_names": [],
			"geo": "59.932324, 30.239044",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc158": {
			"_id": "6673d375396129a2d7c0d3a1",
			"__v": 0,
			"adress": "Стрельбищенская улица, д. 26",
			"alt_names": [],
			"geo": "59.885318, 30.360532",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc159": {
			"_id": "6674257b396129a2d7c10574",
			"__v": 0,
			"adress": "Стремянная улица, д. 20",
			"alt_names": [],
			"geo": "59.930890, 30.353516",
			"image": "Фасад здания$https://access.lbspb.ru/media/74dc0490-bd08-4ffc-8b2c-0d5fb8e40be4.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc160": {
			"_id": "667d27ba396129a2d7c51b37",
			"__v": 0,
			"adress": "Стремянная улица, д. 8",
			"alt_names": [],
			"geo": "59.931251, 30.350597",
			"image": "Фасад здания$https://access.lbspb.ru/media/25774250-f135-480a-9658-77d668da0a9f.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc161": {
			"_id": "66704707396129a2d7bf1d9d",
			"__v": 0,
			"adress": "Татарский переулок, д.1",
			"alt_names": [],
			"geo": "59.953209, 30.305843",
			"image": "Фасад здания$https://access.lbspb.ru/media/48282fa2-3bfa-4f7a-8698-8f6e4d00d175.jpeg",
			"info": "Индукционные петли.",
			"links": [],
			"persons": []
	},
	"loc162": {
			"_id": "66704707396129a2d7bf1da6",
			"__v": 0,
			"adress": "Троицкая площадь, д.1",
			"alt_names": [],
			"geo": "59.952921, 30.327852",
			"image": "Фасад здания$https://access.lbspb.ru/media/8bdd7e46-8724-4d79-b956-cf4692d35c21.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc163": {
			"_id": "66704708396129a2d7bf1e26",
			"__v": 0,
			"adress": "Улица  Корнеева, д. 6",
			"alt_names": [],
			"geo": "59.885336, 30.270126",
			"image": "Фасад здания$https://access.lbspb.ru/media/45749136-5f20-4218-aff1-9b163da9e86c.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc164": {
			"_id": "667945fb396129a2d7c34690",
			"__v": 0,
			"adress": "Улица 3-я Советская, д. 8 Лит. А",
			"alt_names": [],
			"geo": "59.933510, 30.366830",
			"image": "Фасад здания$https://access.lbspb.ru/media/07529eee-883e-45a3-be6c-f43a47415045.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc165": {
			"_id": "66704707396129a2d7bf1c9e",
			"__v": 0,
			"adress": "Улица Автовская, д. 32",
			"alt_names": [],
			"geo": "59.875050, 30.275237",
			"info": "Кнопка вызова\nПандус переносной.",
			"links": [],
			"persons": []
	},
	"loc166": {
			"_id": "66704707396129a2d7bf1d81",
			"__v": 0,
			"adress": "Улица Бабушкина, д. 135",
			"alt_names": [],
			"geo": "59.859203, 30.475481",
			"image": "Главный вход$https://access.lbspb.ru/media/c5a6528d-61e6-4de9-85f5-8b736b757b28.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc167": {
			"_id": "66704707396129a2d7bf1d72",
			"__v": 0,
			"adress": "Улица Бабушкина, д. 64",
			"alt_names": [],
			"geo": "59.865185, 30.454820",
			"image": "Главный вход$https://access.lbspb.ru/media/f2af7331-9556-416a-bb67-6c2b535b9ad8.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc168": {
			"_id": "66704707396129a2d7bf1d2c",
			"__v": 0,
			"adress": "Улица Бассейная, д. 32, стр. 1",
			"alt_names": [],
			"geo": "59.865551, 30.340347",
			"image": "Фасад здания$https://access.lbspb.ru/media/623ed03e-affb-4e10-a8a3-fa21ae93d1ea.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nСоциальная история\nВизуальная поддержка.",
			"links": [],
			"persons": []
	},
	"loc169": {
			"_id": "667ac65b396129a2d7c401d7",
			"__v": 0,
			"adress": "Улица Большая Конюшенная, д. 27",
			"alt_names": [],
			"geo": "59.937017, 30.322309",
			"image": "Фасад здания$https://access.lbspb.ru/media/546dabfc-3af4-4fdd-aa90-0acbdb09f9ca.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc170": {
			"_id": "66704707396129a2d7bf1ce3",
			"__v": 0,
			"adress": "Улица Большая Пороховская, д. 12/34",
			"alt_names": [],
			"geo": "59.953705, 30.414054",
			"image": "Фасад здания$https://access.lbspb.ru/media/abbecf50-bdf5-46e7-9dae-1904ce44e861.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc171": {
			"_id": "66704707396129a2d7bf1e1b",
			"__v": 0,
			"adress": "Улица Васенко, д. 6/10",
			"alt_names": [],
			"geo": "59.970839, 30.390859",
			"image": "Главный вход$https://access.lbspb.ru/media/8d5e2cac-c5f6-4930-a6cc-ecf6032d42c4.jpeg",
			"info": "Доступные входные группы.",
			"links": [],
			"persons": []
	},
	"loc172": {
			"_id": "667294b3396129a2d7c03d36",
			"__v": 0,
			"adress": "Улица Вербная, д.19, к.1",
			"alt_names": [],
			"geo": "60.022670, 30.291182",
			"image": "Фасад здания$https://access.lbspb.ru/media/1778ce0f-ca3f-4170-993f-9825c0d32db8.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc173": {
			"_id": "66704707396129a2d7bf1da9",
			"__v": 0,
			"adress": "Улица Воскова, д. 2",
			"alt_names": [],
			"geo": "59.957534, 30.302950",
			"image": "Фасад здания$https://access.lbspb.ru/media/3739b6d8-b0c1-444f-b82d-f17ad9d898a8.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc174": {
			"_id": "66704707396129a2d7bf1ca3",
			"__v": 0,
			"adress": "Улица Двинская, д. 10",
			"alt_names": [],
			"geo": "59.908958, 30.248953",
			"image": "Фасад здания$https://access.lbspb.ru/media/145a9691-1594-408f-95e3-b3bd23d85f0b.jpeg",
			"info": "Кнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc175": {
			"_id": "6673d376396129a2d7c0d3b7",
			"__v": 0,
			"adress": "Улица Димитрова, д. 12, к.1",
			"alt_names": [],
			"geo": "59.847358, 30.380807",
			"info": "Здание не адаптировано.",
			"links": [
					"$https://p3.citywalls.ru/photo_146-149687.jpg?mt=1358018472"
			],
			"persons": []
	},
	"loc176": {
			"_id": "6673d375396129a2d7c0d3a5",
			"__v": 0,
			"adress": "Улица Димитрова, д. 9, к.1",
			"alt_names": [],
			"geo": "59.846775, 30.383475",
			"image": "Фасад здания$https://access.lbspb.ru/media/aa2c3ad3-840c-4d79-a2a0-154f078fcdb7.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc177": {
			"_id": "66704707396129a2d7bf1d85",
			"__v": 0,
			"adress": "Улица Дмитрия Устинова, д. 3, Лит. А",
			"alt_names": [],
			"geo": "59.839181, 30.499645",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc178": {
			"_id": "66704707396129a2d7bf1dcc",
			"__v": 0,
			"adress": "Улица Долгоозерная, д.12, к. 2",
			"alt_names": [],
			"geo": "60.017310, 30.243904",
			"image": "Фасад здания$https://access.lbspb.ru/media/126ffd39-3da7-4f47-abb3-53acca0c9a7a.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc179": {
			"_id": "66704707396129a2d7bf1dfc",
			"__v": 0,
			"adress": "Улица Елизаветинская, д. 8",
			"alt_names": [],
			"geo": "60.047295, 30.302007",
			"image": "Фасад здания$https://access.lbspb.ru/media/76c609bc-1c97-4ee0-ae51-533661cc5e98.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc180": {
			"_id": "66797732396129a2d7c362d0",
			"__v": 0,
			"adress": "Улица Зодчего Росси, д. 2",
			"alt_names": [],
			"geo": "59.930539, 30.336080",
			"info": "Здание библиотеки:\nКнопка вызова\nНапольная навигация\nИндукционные петли\n\nЗдание музея:\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc181": {
			"_id": "667ab84b396129a2d7c3f9b7",
			"__v": 0,
			"adress": "Улица Зои Космодемьянской, д. 3",
			"alt_names": [],
			"geo": "59.893267, 30.272039",
			"image": "Фасад здания$https://access.lbspb.ru/media/d0b04067-f7c0-4f38-a717-9cee6760c12d.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc182": {
			"_id": "66704707396129a2d7bf1d35",
			"__v": 0,
			"adress": "Улица Итальянская, д. 13",
			"alt_names": [],
			"geo": "59.936030, 30.332954",
			"image": "Фасад здания$https://access.lbspb.ru/media/6cc18e6c-5fa8-4ad0-b7db-90a717b5c5d9.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc183": {
			"_id": "667d3823396129a2d7c52532",
			"__v": 0,
			"adress": "Улица Кирочная, д. 43",
			"alt_names": [],
			"geo": "59.943233, 30.376567",
			"image": "Фасад здания$https://access.lbspb.ru/media/330319e3-0f6c-453e-9f9d-e548aa5e5080.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nВизуальная поддержка.",
			"links": [],
			"persons": []
	},
	"loc184": {
			"_id": "66704707396129a2d7bf1cf3",
			"__v": 0,
			"adress": "Улица Коммунаров, д. 118, к. 1",
			"alt_names": [],
			"geo": "59.786423, 30.150641",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc185": {
			"_id": "66704707396129a2d7bf1ca9",
			"__v": 0,
			"adress": "Улица Краснопутиловская, д. 26",
			"alt_names": [],
			"geo": "59.870709, 30.269695",
			"image": "Фасад здания$https://access.lbspb.ru/media/905885db-d8f5-4734-9f1d-d1363690469f.jpeg",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc186": {
			"_id": "66704708396129a2d7bf1e2c",
			"__v": 0,
			"adress": "Улица Кронштадтская, д. 20",
			"alt_names": [],
			"geo": "59.869146, 30.257532",
			"image": "Фасад здания$https://access.lbspb.ru/media/eb41696a-0bd9-4404-b73a-451ece64423b.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc187": {
			"_id": "66704707396129a2d7bf1d89",
			"__v": 0,
			"adress": "Улица Крупской, д. 37, Лит. А",
			"alt_names": [],
			"geo": "59.889571, 30.423369",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc188": {
			"_id": "66704707396129a2d7bf1ce5",
			"__v": 0,
			"adress": "Улица Лагоды, д. 7, стр. 1",
			"alt_names": [],
			"geo": "59.957440, 30.448882",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc189": {
			"_id": "66704707396129a2d7bf1cd8",
			"__v": 0,
			"adress": "Улица Лазо, д. 8, к. 1",
			"alt_names": [],
			"geo": "59.957426, 30.484706",
			"image": "Главный вход$https://access.lbspb.ru/media/6776a127-2766-466e-a8f0-0f4ad9c76548.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc190": {
			"_id": "66704707396129a2d7bf1e21",
			"__v": 0,
			"adress": "Улица Лени Голикова, д. 31",
			"alt_names": [],
			"geo": "59.841907, 30.229702",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc191": {
			"_id": "66704707396129a2d7bf1db2",
			"__v": 0,
			"adress": "Улица Ленина, д. 20",
			"alt_names": [],
			"geo": "59.962174, 30.303912",
			"image": "Фасад здания$https://access.lbspb.ru/media/9f9f9097-f332-4b21-b88e-67d2269b08d7.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc192": {
			"_id": "6679621b396129a2d7c356a0",
			"__v": 0,
			"adress": "Улица Марата, д. 72 Лит. А",
			"alt_names": [],
			"geo": "59.920492, 30.343096",
			"image": "Фасад здания$https://access.lbspb.ru/media/43cdf395-94bc-437a-943e-8e1452b2fcae.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc193": {
			"_id": "66704707396129a2d7bf1cef",
			"__v": 0,
			"adress": "Улица Маршала Казакова, д. 68, к.1, лит. А",
			"alt_names": [],
			"geo": "59.865822, 30.179037",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc194": {
			"_id": "667943a3396129a2d7c34517",
			"__v": 0,
			"adress": "Улица Маяковского, д. 27 Лит. А",
			"alt_names": [],
			"geo": "59.940294, 30.355628",
			"image": "Фасад здания$https://access.lbspb.ru/media/0e6bee4a-e30a-4265-b0e3-9d3bfc5e846e.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc195": {
			"_id": "667aa7e3396129a2d7c3efd2",
			"__v": 0,
			"adress": "Улица Маяковского, д.15",
			"alt_names": [],
			"geo": "59.937914, 30.355556",
			"image": "Фасад здания$https://access.lbspb.ru/media/1baf9a93-66ef-4fe3-a67e-17efcda51b14.jpeg",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc196": {
			"_id": "66704707396129a2d7bf1c9c",
			"__v": 0,
			"adress": "Улица Нахимова, д.8 к.3",
			"alt_names": [],
			"geo": "59.942737, 30.235550",
			"image": "Главный вход$https://access.lbspb.ru/media/839a0125-6ad4-46fd-a0a6-a2441295ec35.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc197": {
			"_id": "6679621b396129a2d7c3569c",
			"__v": 0,
			"adress": "Улица Новгородская, д. 5 Лит. А",
			"alt_names": [],
			"geo": "59.933005, 30.384517",
			"image": "Фасад здания$https://access.lbspb.ru/media/fc2e8b83-5fda-4886-a620-739b9d3b84ae.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc198": {
			"_id": "66704707396129a2d7bf1ca0",
			"__v": 0,
			"adress": "Улица Оборонная, д. 18",
			"alt_names": [],
			"geo": "59.893340, 30.262607",
			"info": "Кнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc199": {
			"_id": "66704707396129a2d7bf1cf7",
			"__v": 0,
			"adress": "Улица Партизана Германа, д. 22, лит. А",
			"alt_names": [],
			"geo": "59.829427, 30.177563",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc200": {
			"_id": "66704707396129a2d7bf1ceb",
			"__v": 0,
			"adress": "Улица Партизана Германа, д. 5/14, лит. А",
			"alt_names": [],
			"geo": "59.841156, 30.174204",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc201": {
			"_id": "66704707396129a2d7bf1d32",
			"__v": 0,
			"adress": "Улица Рубинштейна, д.13, лит. А",
			"alt_names": [],
			"geo": "59.929407, 30.343949",
			"image": "Фасад здания$https://access.lbspb.ru/media/ad9f7365-28f6-4fc0-9cff-6ecb66ff67f1.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc202": {
			"_id": "66704707396129a2d7bf1def",
			"__v": 0,
			"adress": "Улица Руднева, д. 25",
			"alt_names": [],
			"geo": "60.050179, 30.370153",
			"image": "Фасад здания$https://access.lbspb.ru/media/74d6fc96-ec44-4ef8-a7f5-088eb4cae3c9.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc203": {
			"_id": "667294b3396129a2d7c03d3a",
			"__v": 0,
			"adress": "Улица Савушкина, д.128, к.1",
			"alt_names": [],
			"geo": "59.987857, 30.201575",
			"image": "Фасад здания$https://access.lbspb.ru/media/669a21d1-579f-4873-b17b-31266bfa90e0.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc204": {
			"_id": "66704707396129a2d7bf1d7c",
			"__v": 0,
			"adress": "Улица Седова, д. 21",
			"alt_names": [],
			"geo": "59.892482, 30.415033",
			"image": "Фасад здания$https://access.lbspb.ru/media/6e42f3de-5d2b-481f-8acd-b0e3d44f40a9.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc205": {
			"_id": "66704707396129a2d7bf1ca6",
			"__v": 0,
			"adress": "Улица Стойкости, д. 36/1",
			"alt_names": [],
			"geo": "59.827899, 30.192619",
			"image": "Главный вход$https://access.lbspb.ru/media/4569a108-e0c0-42aa-a06c-b49d3e81e995.jpeg",
			"info": "Доступные входные группы\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc206": {
			"_id": "66704707396129a2d7bf1cfb",
			"__v": 0,
			"adress": "Улица Тамбасова д.1, к.1, стр.1",
			"alt_names": [],
			"geo": "59.840438, 30.157064",
			"info": "Доступные входные группы.",
			"links": [],
			"persons": []
	},
	"loc207": {
			"_id": "66704707396129a2d7bf1d5e",
			"__v": 0,
			"adress": "Улица Типанова, д. 29",
			"alt_names": [],
			"geo": "59.853975, 30.357289",
			"image": "Фасад здания$https://access.lbspb.ru/media/dba135ca-532a-4482-b478-be2c0b78f3bd.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc208": {
			"_id": "66704707396129a2d7bf1dcf",
			"__v": 0,
			"adress": "Улица Торжковская, д.11",
			"alt_names": [],
			"geo": "59.990026, 30.315284",
			"image": "Главный вход$https://access.lbspb.ru/media/cd212338-a059-4a32-aca1-6952b10f0e11.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc209": {
			"_id": "66729962396129a2d7c04037",
			"__v": 0,
			"adress": "Улица Туристская, д.11, к.1",
			"alt_names": [],
			"geo": "59.996875, 30.208798",
			"image": "Фасад здания$https://access.lbspb.ru/media/a9c08964-185f-459d-9a04-f9ba3fb59df7.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc210": {
			"_id": "6673d374396129a2d7c0d399",
			"__v": 0,
			"adress": "Улица Турку, д. 11, к.1",
			"alt_names": [],
			"geo": "59.866780, 30.382460",
			"image": "Фасад здания$https://access.lbspb.ru/media/972a0ffc-7787-4725-9dfc-e03965baec57.jpeg",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc211": {
			"_id": "6673d376396129a2d7c0d3b5",
			"__v": 0,
			"adress": "Улица Турку, д. 17, к.1",
			"alt_names": [],
			"geo": "59.866314, 30.389225",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc212": {
			"_id": "66704707396129a2d7bf1d95",
			"__v": 0,
			"adress": "Улица Фарфоровская, д. 26, Лит. Д",
			"alt_names": [],
			"geo": "59.879918, 30.432972",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc213": {
			"_id": "66704707396129a2d7bf1de9",
			"__v": 0,
			"adress": "Улица Харченко, д. 17",
			"alt_names": [],
			"dates": "Кнопка вызова\nИндукционные петли",
			"geo": "59.983446, 30.350327",
			"image": "Главный вход$https://access.lbspb.ru/media/ecad3bcd-e7a2-4802-ae4a-944119bc790f.jpeg",
			"links": [],
			"persons": [],
			"info": "Кнопка вызова\nИндукционные петли."
	},
	"loc214": {
			"_id": "667420cb396129a2d7c1027a",
			"__v": 0,
			"adress": "Улица Циолковского, д. 7",
			"alt_names": [],
			"geo": "59.911453, 30.286134",
			"image": "Фасад здания$https://access.lbspb.ru/media/a39da26d-1ce6-4709-9b26-0007dc07461c.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc215": {
			"_id": "66704707396129a2d7bf1ce9",
			"__v": 0,
			"adress": "Улица Чекистов, д. 26",
			"alt_names": [],
			"geo": "59.843485, 30.164259",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация.",
			"links": [],
			"persons": []
	},
	"loc216": {
			"_id": "66741c1a396129a2d7c0ff78",
			"__v": 0,
			"adress": "Улица Шкапина, д. 6",
			"alt_names": [],
			"geo": "59.907645, 30.296743",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [
					"$https://p3.citywalls.ru/photo_339-347759.jpg?mt=1526589389"
			],
			"persons": []
	},
	"loc217": {
			"_id": "66704707396129a2d7bf1d83",
			"__v": 0,
			"adress": "Улица Шотмана, д. 7, к. 1, Лит. З",
			"alt_names": [],
			"geo": "59.900628, 30.480952",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc218": {
			"_id": "6673d376396129a2d7c0d3b3",
			"__v": 0,
			"adress": "Улица Ярослава Гашека, д. 26",
			"alt_names": [],
			"geo": "59.839778, 30.416722",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc219": {
			"_id": "6673d374396129a2d7c0d395",
			"__v": 0,
			"adress": "Шушары, Валдайская улица, д. 9",
			"alt_names": [],
			"geo": "59.804142, 30.378041",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc220": {
			"_id": "66704707396129a2d7bf1cb5",
			"__v": 0,
			"adress": "г. Колпино, набережная Комсомольского канала, д. 18",
			"alt_names": [],
			"geo": "59.747752, 30.608315",
			"image": "Фасад здания$https://access.lbspb.ru/media/74002540-8eae-44d9-9f84-f17248e0f2b4.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc221": {
			"_id": "66704707396129a2d7bf1caf",
			"__v": 0,
			"adress": "г. Колпино, улица Веры Слуцкой, д. 32",
			"alt_names": [],
			"geo": "59.744374, 30.582910",
			"image": "Главный вход$https://access.lbspb.ru/media/dff14285-d14f-4ec0-9b4f-f24514ee1bf5.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc222": {
			"_id": "66704707396129a2d7bf1cb2",
			"__v": 0,
			"adress": "г. Колпино, улица Веры Слуцкой, д. 50",
			"alt_names": [],
			"geo": "59.735836, 30.580664",
			"image": "Главный вход$https://access.lbspb.ru/media/5422db8c-b1e5-42b6-bd08-8f4cea88a1d3.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc223": {
			"_id": "66704707396129a2d7bf1cb8",
			"__v": 0,
			"adress": "г. Колпино, улица Загородная, д. 55",
			"alt_names": [],
			"geo": "59.777397, 30.594202",
			"image": "Фасад здания$https://access.lbspb.ru/media/2f17f231-7fa8-460c-83dc-d9fcae0d19c1.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc224": {
			"_id": "66704707396129a2d7bf1cc3",
			"__v": 0,
			"adress": "г. Колпино, улица Тверская, д. 36, к. 3",
			"alt_names": [],
			"geo": "59.740035, 30.611540",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc225": {
			"_id": "66704707396129a2d7bf1db9",
			"__v": 0,
			"adress": "г. Ломоносов, улица Жоры Антоненко, д. 6, к. 1",
			"alt_names": [],
			"geo": "59.900818, 29.819423",
			"image": "Фасад здания$https://access.lbspb.ru/media/46c4cd28-88b0-4e55-8c1f-96aef14aa907.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nПереносные индукционные системы.",
			"links": [],
			"persons": []
	},
	"loc226": {
			"_id": "66704707396129a2d7bf1dbe",
			"__v": 0,
			"adress": "г. Ломоносов, улица Победы, д. 1",
			"alt_names": [],
			"geo": "59.909220, 29.774660",
			"image": "Фасад здания$https://access.lbspb.ru/media/701700c8-4dae-45b1-9a82-d9410170e036.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nПереносные индукционные системы.",
			"links": [],
			"persons": []
	},
	"loc227": {
			"_id": "66704707396129a2d7bf1dc6",
			"__v": 0,
			"adress": "г. Петергоф, Санкт-Петербургский проспект, д.6, Лит. А",
			"alt_names": [],
			"geo": "59.877381, 29.924202",
			"image": "Фасад здания$https://access.lbspb.ru/media/f0c128a7-73b0-45d7-b920-dd51b9287e94.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\nПереносные индукционные системы.",
			"links": [],
			"persons": []
	},
	"loc228": {
			"_id": "66704707396129a2d7bf1db4",
			"__v": 0,
			"adress": "г. Петергоф, Эрлеровский бульвар, д.18",
			"alt_names": [],
			"geo": "59.872715, 29.908626",
			"info": "Доступные входные группы\nКнопка вызова\nПереносные индукционные системы.",
			"links": [],
			"persons": []
	},
	"loc229": {
			"_id": "66704707396129a2d7bf1dbb",
			"__v": 0,
			"adress": "г. Петергоф, улица Володи Дубинина, д.12, Лит. А",
			"alt_names": [],
			"geo": "59.861214, 29.923430",
			"info": "Переносные индукционные системы.",
			"links": [],
			"persons": []
	},
	"loc230": {
			"_id": "66704707396129a2d7bf1dc0",
			"__v": 0,
			"adress": "г. Петергоф, улица Шахматова, д.12 к. 2",
			"alt_names": [],
			"geo": "59.871193, 29.863593",
			"info": "Доступные входные группы\nКнопка вызова\nПереносные индукционные системы.",
			"links": [],
			"persons": []
	},
	"loc231": {
			"_id": "6682aaab396129a2d7c7971b",
			"__v": 0,
			"adress": "остров Ореховый, Шлиссельбург, Кировский район, Ленинградская область",
			"alt_names": [],
			"geo": "59.953745, 31.038275",
			"info": "Доступные входные группы.",
			"links": [],
			"persons": []
	},
	"loc232": {
			"_id": "6673d374396129a2d7c0d391",
			"__v": 0,
			"adress": "п. Александровская, Волхонское шоссе, д. 33",
			"alt_names": [],
			"geo": "59.730425, 30.347686",
			"info": "Кнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc233": {
			"_id": "66704707396129a2d7bf1df6",
			"__v": 0,
			"adress": "п. Левашово, улица Железнодорожная, д.46",
			"alt_names": [],
			"geo": "60.104652, 30.214008",
			"image": "Главный вход$https://access.lbspb.ru/media/c98a9f67-6b47-4ed5-9326-749610342a39.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc234": {
			"_id": "667288fb396129a2d7c0366d",
			"__v": 0,
			"adress": "п. Лисий Нос, Балтийский проспект, д.36",
			"alt_names": [],
			"geo": "60.018835, 30.002140",
			"image": "Главный вход$https://access.lbspb.ru/media/417bc500-9dd5-4091-8c11-52d43a8b3b08.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc235": {
			"_id": "66704707396129a2d7bf1cc1",
			"__v": 0,
			"adress": "п. Металлострой, улица Центральная, д. 14",
			"alt_names": [],
			"geo": "59.808591, 30.571762",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc236": {
			"_id": "66704707396129a2d7bf1dd9",
			"__v": 0,
			"adress": "п. Ольгино, улица Колодезная, д. 30",
			"alt_names": [],
			"geo": "59.996520, 30.135891",
			"image": "Главный вход$https://access.lbspb.ru/media/b1c0d890-2699-4711-bab6-8d99e34f3070.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc237": {
			"_id": "66704707396129a2d7bf1cc5",
			"__v": 0,
			"adress": "п. Понтонный, улица Александра Товпеко, д. 14",
			"alt_names": [],
			"geo": "59.784711, 30.625697",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc238": {
			"_id": "66704707396129a2d7bf1cbf",
			"__v": 0,
			"adress": "п. Понтонный, улица Южная, д. 13",
			"alt_names": [],
			"geo": "59.779050, 30.647364",
			"info": "Доступные входные группы\nКнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc239": {
			"_id": "66704707396129a2d7bf1cbd",
			"__v": 0,
			"adress": "п. Саперный, улица Дорожная, д. 11",
			"alt_names": [],
			"geo": "59.777637, 30.691193",
			"image": "Фасад здания$https://access.lbspb.ru/media/8341257f-5ccf-4829-96f4-ec73e0fd9a51.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc240": {
			"_id": "66704707396129a2d7bf1d46",
			"__v": 0,
			"adress": "п. Солнечное, Приморское шоссе, д. 374",
			"alt_names": [],
			"geo": "60.149748, 29.926817",
			"image": "Фасад здания$https://access.lbspb.ru/media/68ab6c3f-17a6-4674-b031-4935b4e6516e.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc241": {
			"_id": "66704707396129a2d7bf1dc3",
			"__v": 0,
			"adress": "п. Стрельна, улица Орловская, д. 2, Лит. А",
			"alt_names": [],
			"geo": "59.853763, 30.037956",
			"image": "Фасад здания$https://access.lbspb.ru/media/13a5d721-c8c4-4ad6-8354-96b025b02b2a.jpeg",
			"info": "Доступные входные группы\nКнопка вызова\n\nПереносные индукционные системы.",
			"links": [],
			"persons": []
	},
	"loc242": {
			"_id": "66704707396129a2d7bf1d40",
			"__v": 0,
			"adress": "п.Комарово, улица 3-я Дачная, д. 8",
			"alt_names": [],
			"geo": "60.190380, 29.816010",
			"image": "Фасад здания$https://access.lbspb.ru/media/c78111fe-f1e2-4bb1-afd9-cf6d1d1e50c4.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc243": {
			"_id": "66704707396129a2d7bf1d3d",
			"__v": 0,
			"adress": "п.Песочный, улица Ленинградская, д.46 Лит. А",
			"alt_names": [],
			"geo": "60.121547, 30.167799",
			"image": "Фасад здания$https://access.lbspb.ru/media/51c9c69c-fcb4-4c93-8dee-fb722398952e.jpeg",
			"info": "Доступные входные группы\nКнопка вызова.",
			"links": [],
			"persons": []
	},
	"loc244": {
			"_id": "6673d374396129a2d7c0d393",
			"__v": 0,
			"adress": "п.Тярлево, улица Новая, д.1",
			"alt_names": [],
			"geo": "59.703407, 30.440725",
			"info": "Здание не адаптировано.",
			"links": [],
			"persons": []
	},
	"loc245": {
			"_id": "6673d374396129a2d7c0d38d",
			"__v": 0,
			"adress": "п.Шушары, Московское шоссе, д. 258, к.2, стр. 1",
			"alt_names": [],
			"geo": "59.755948, 30.467351",
			"info": "Доступные входные группы\nКнопка вызова\nНапольная навигация\nИндукционные петли.",
			"links": [],
			"persons": []
	},
	"loc246": {
			"_id": "66704707396129a2d7bf1e04",
			"__v": 0,
			"adress": "проспект Просвещения, д. 36/141",
			"alt_names": [],
			"geo": "60.052923, 30.337140",
			"image": "Главный вход$https://access.lbspb.ru/media/7c02ecb0-a289-45d1-983d-0483f28629c1.jpeg",
			"info": "Кнопка вызова\nИндукционные петли.",
			"links": [],
			"persons": []
	}
}

let locations = Object.keys(locationsAsObject).map((key) => ({ ...locationsAsObject[key] }));

// возвращает данные locations для показа на карте (_id, adress, geo)
const fetchAll = async () => {
	// if (CONFIG.IS_SERVER) {
	// 	const {data} = await httpService.get(CONFIG.API_URL + "location")
	// 	return data
	// } else {
  await delay(750);
  let newArr = [];
  // newArr = locations;
  // включить, если реализована поддержка загрузки полной информации об адресе в попапе карты
  locations.forEach((o) => newArr.push({ _id: o._id, adress: o.adress, geo: o.geo, persons: o.persons, info:o.info, image:o.image }));
  return newArr;
	// }
};

// возвращает полные данные об одной локации
const getById = async (id) => {
	// if (CONFIG.IS_SERVER) {
	// 	const {data} = await httpService.get(CONFIG.API_URL + "location/" + id)
	// 	return data
	// } else {
		await delay(50);
		return locations.find((loc) => loc._id === id);
	// }
};

// const add = async (data) => {
//   await delay(50);
//   const newItem = {
//     ...data,
//     created_at: Date.now(),
//     _id: Math.random().toString(36).substr(2, 9),
//   };
//   locations.push(newItem);
//   return newItem;
// };

// const remove = async (id) => {
//   await delay(50);
//   locations = locations.filter((x) => x._id !== id);
//   return id;
// };

export default {
  fetchAll,
  getById,
  // add,
  // remove,
};

import React from "react";

interface IMenuPersonsSVG {
  color: string;
}

const MenuPersonsSVG = ({ color }: IMenuPersonsSVG) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.25 8.75C16.25 11.5114 14.0114 13.75 11.25 13.75C8.48857 13.75 6.25 11.5114 6.25 8.75C6.25 5.98857 8.48857 3.75 11.25 3.75C14.0114 3.75 16.25 5.98857 16.25 8.75Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M18.75 13.75C21.5114 13.75 23.75 11.5114 23.75 8.75C23.75 5.98857 21.5114 3.75 18.75 3.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 17.5H8.75C5.29822 17.5 2.5 20.2983 2.5 23.75C2.5 25.1307 3.61929 26.25 5 26.25H17.5C18.8807 26.25 20 25.1307 20 23.75C20 20.2983 17.2017 17.5 13.75 17.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M21.25 17.5C24.7017 17.5 27.5 20.2983 27.5 23.75C27.5 25.1307 26.3807 26.25 25 26.25H23.125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MenuPersonsSVG;

import React, { useState, useEffect } from 'react';
import MainLayout from './mainLayout';
import PointerItem from '../components/pointerItem';
import { useData } from '../hooks/useContext';
import Pagination from '../components/pagination';
import { paginate } from '../utils/paginate';
import Loader from '../components/loader';
import { IPartialOrganization, IUseData } from '../interfaces';
import PageCard from '../components/pageCard';

const OrganizationsList = () => {
  const { setPage, organizations, theme } = useData() as IUseData;
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const pageSize: number = 16;

  useEffect(() => {
    setPage('organizations');
    document.title = 'Организации';
  }, []);

  return (
    <MainLayout>
      {/* <div className="m-auto w-full 2xl:w-4/5 h-100 md:px-12 flex flex-col px-2"> */}
      <PageCard />
      <main className={'grow flex flex-col rounded-[40px] p-[40px] my-5' + theme.active}>
        {organizations ? (
          <>
            <h2 className={'text-2xl font-medium mb-2 border-b-2' + theme.redBorder} autoFocus={true}>
              {'Организации (' + organizations.length + ')'}
            </h2>
            <ul>
              {paginate<IPartialOrganization>(organizations, paginationPage, pageSize).map(
                (item: IPartialOrganization) => (
                  <PointerItem
                    key={item._id}
                    name={item.name}
                    link={'organizations/' + item._id}
                    num={organizations.indexOf(item) + 1}
                    subnames={item.subnames}
                  />
                )
              )}
            </ul>
            <Pagination
              productCount={organizations.length}
              pageSize={pageSize}
              currentPage={paginationPage}
              onPageChange={(page: number) => setPaginationPage(page)}
            />
          </>
        ) : (
          <Loader title={'Загрузка списка организаций...'} />
        )}
      </main>
      {/* </div> */}
    </MainLayout>
  );
};

export default OrganizationsList;

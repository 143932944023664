import React from 'react';
import { useData } from '../hooks/useContext';
import { IActiveFilters, IUseData, IPartialOrganization } from '../interfaces';
import CheckedSVG from './SVG/checkedSVG';

const Filter = () => {
  const { theme, organizations, categories, activeFilters, setSearchRes, setActiveFilters, setFilteredOrganizations } =
    useData() as IUseData;

  const filterChange = (filterItem?: string, filterGroup?: string, isolate?: boolean) => {
    if (isolate) {
      Object.keys(activeFilters[filterGroup!]).forEach((item: string) => (activeFilters[filterGroup!][item] = false));
    }
    setSearchRes(false);
    let newActiveFilters: IActiveFilters = {};
    Object.keys(activeFilters).forEach((item: string) => {
      newActiveFilters[item] = activeFilters[item];
    });
    if (filterItem && filterGroup) {
      newActiveFilters[filterGroup][filterItem] = !newActiveFilters[filterGroup][filterItem];
    } else {
      Object.keys(newActiveFilters).forEach((group: string) => {
        Object.keys(newActiveFilters[group]).forEach((item: string) => {
          newActiveFilters[group][item] = false;
        });
      });
    }
    setActiveFilters(newActiveFilters);

    if (organizations) {
      let newFilteredOrganizations: IPartialOrganization[] = [];
      organizations.forEach((org: IPartialOrganization) => {
        //перебираем все организации
        let orgCatGroups: { [group: string]: boolean } = {};
        Object.keys(categories).forEach((group) => (orgCatGroups[group] = false));
        // перебираем группы одной организации
        Object.keys(org.categories).forEach((group: string) => {
          //перебираем категориии одной группы одной организации
          if (
            org.categories[group].length === 0 &&
            Object.keys(activeFilters[group]).every((item: string) => activeFilters[group][item] === false)
          ) {
            orgCatGroups[group] = true;
          } else {
            org.categories[group].forEach((category: string) => {
              if (
                // если все фильтры в категории false или в activeFilters организация true
                Object.keys(activeFilters[group]).every((item: string) => activeFilters[group][item] === false) ||
                activeFilters[group][category] === true
              ) {
                orgCatGroups[group] = true;
              }
            });
          }
        });
        if (
          Object.keys(orgCatGroups).every((item: string) => orgCatGroups[item] === true) &&
          !newFilteredOrganizations.includes(org)
        ) {
          newFilteredOrganizations.push(org);
        }
      });
      setFilteredOrganizations(newFilteredOrganizations);
    }
  };

  const getColor = (category: string) => {
    for (let group in categories) {
      for (let cat in categories[group]) {
        if (categories[group][cat]._id === category) {
          return categories[group][cat].color;
        }
      }
    }
  };

  const getName = (category: string) => {
    for (let group in categories) {
      for (let cat in categories[group]) {
        if (categories[group][cat]._id === category) {
          return categories[group][cat].name;
        }
      }
    }
  };

  return (
    <div className={'h-fit flex flex-col w-full md:border-2 md:rounded-[40px] md:p-10 p-5' + theme.redBorder}>
      <div className={'um-filter text-3xl font-medium pb-2'}>Фильтр:</div>
      <div className="flex md:flex-row flex-col w-full justify-between">
        {Object.keys(activeFilters).map((group: string) => (
          <div key={group} className="">
            <h3
              className={'um-groupname font-medium text-2xl border-b-2 w-fit my-3' + theme.primary + theme.redBorder2}
            >
              {group}
            </h3>
            <ul
              className={
                Object.keys(categories).length === 1 ? 'text-lg font-medium grid md:grid-cols-2' : 'text-lg font-medium'
              }
              title={group}
            >
              {Object.keys(activeFilters[group]).map((category: string) => (
                <li
                  key={category}
                  className={
                    !activeFilters[group][category]
                      ? 'cursor-pointer mt-1 me-5' + theme.primary
                      : 'cursor-pointer mt-1 me-5'
                  }
                >
                  <label className="flex flex-row">
                    <input
                      type="checkbox"
                      className="w-0 h-0"
                      title={getName(category)}
                      value={getName(category)}
                      name={getName(category)}
                      checked={activeFilters[group][category]}
                      onChange={() => {}}
                      aria-label={getName(category)}
                      onClick={() => filterChange(category, group)}
                    />

                    <span
                      className="text-left flex flex-row cursor-pointer font-medium text-xl"
                      onDoubleClick={() => filterChange(category, group, true)}
                    >
                      <div
                        className={'w-6 h-6 rounded-[4px] me-2 mt-1 border border-[#141B34] p-1'}
                        style={{ backgroundColor: getColor(category) ? getColor(category) : 'transparent' }}
                      >
                        {activeFilters[group][category] ? <CheckedSVG /> : null}
                      </div>
                      {getName(category)}
                    </span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <button
        className={
          'font-semibold px-5 py-[10px] mt-5 cursor-pointer w-fit h-fit self-end rounded-full' +
          theme.active +
          theme.hover
        }
        onClick={() => filterChange()}
      >
        Сбросить фильтр
      </button>
    </div>
  );
};

export default Filter;

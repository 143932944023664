import React from "react";

const ArrowLeftSVG = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 6H2M2 6L7.09804 1M2 6L7.09804 11" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default ArrowLeftSVG;

import React from "react";

const ArrowRightSVG = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 6H11M11 6L5.90196 1M11 6L5.90196 11" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default ArrowRightSVG;

import React from "react";

interface IMenuAboutSVG {
  color: string;
}

const MenuAboutSVG = ({ color }: IMenuAboutSVG) => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5833 8.75C16.5833 11.5114 14.3446 13.75 11.5833 13.75C8.82183 13.75 6.58325 11.5114 6.58325 8.75C6.58325 5.98857 8.82183 3.75 11.5833 3.75C14.3446 3.75 16.5833 5.98857 16.5833 8.75Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M14.2665 26.25H5.33325C3.95254 26.25 2.83325 25.1307 2.83325 23.75C2.83325 20.2982 5.63148 17.5 9.08325 17.5H14.0833C14.3641 17.5 14.6406 17.5185 14.9116 17.5544"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2029 25.318L25.4172 27.5056M24.4776 22.2732C24.4776 24.6332 22.5649 26.5464 20.2055 26.5464C17.846 26.5464 15.9332 24.6332 15.9332 22.2732C15.9332 19.9132 17.846 18 20.2055 18C22.5649 18 24.4776 19.9132 24.4776 22.2732Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MenuAboutSVG;

import React from "react";
import { useData } from "../../hooks/useContext";
import { engToRus } from "../../utils/engToRus";
import { useHistory } from "react-router-dom";
import { IUseData } from "../../interfaces";
import MenuAboutSVG from "../SVG/menuSVG/menuAbout";
import MenuLocationsSVG from "../SVG/menuSVG/menuLocations";
import MenuMapSVG from "../SVG/menuSVG/menuMap";
import MenuOrganizationsSVG from "../SVG/menuSVG/menuOrganizations";
import MenuPersonsSVG from "../SVG/menuSVG/menuPersons";

interface INavbarLgItem {
  header: string;
}

const NavbarLgItem = ({ header }: INavbarLgItem) => {
  const { page, setPage, theme } = useData() as IUseData;
  const history = useHistory();

  const getSVG = () => {
    switch (header) {
      case "map":
        return <MenuMapSVG color={page === header ? "#ffffff" : "#141B34"} />;
      case "organizations":
        return <MenuOrganizationsSVG color={page === header ? "#ffffff" : "#141B34"} />;
      case "locations":
        return <MenuLocationsSVG color={page === header ? "#ffffff" : "#141B34"} />;
      case "persons":
        return <MenuPersonsSVG color={page === header ? "#ffffff" : "#141B34"} />;
      case "about":
        return <MenuAboutSVG color={page === header ? "#ffffff" : "#141B34"} />;
    }
  };

  return (
    <button
      className={
        page === header
          ? "cursor-pointer px-10 py-[10px] rounded-[40px] flex mt-4" + theme.active
          : "cursor-pointer px-10 py-[10px] rounded-[40px] flex mt-4" + theme.primary + theme.hover
      }
      onClick={() => {
        setPage(header);
        history.push("/" + header);
      }}
      title={"Перейти на страницу - " + engToRus(header)}
    >
      <div className="self-center me-1">{getSVG()}</div>
      <p className="text-2xl p-2 text-center">{engToRus(header)}</p>
    </button>
  );
};

export default NavbarLgItem;

export const CONFIG = {
	IS_SERVER: true,
	API_URL: "https://access.lbspb.ru/api/",
	SHOW_PERSONS: false,
	SHOW_LOCATIONS: true,
	BLUE_OR_GREEN_DESIGN: true,
	MAP_INDIVIDUAL_PLACEMARKS: false,
	MAP_GENERATOR: false,

	MAIN_PAGE: {
	HEADER: 'Доступность учреждений культуры Санкт-Петербурга',
    HEADER1: 'Доступность учреждений',
    HEADER2: 'культуры Санкт-Петербурга',
	  },

	  PAGES_CARDS: {
		MAP_PAGE: {
		  HEADER: 'Карта',
		  TEXT: 'На интерактивной карте Санкт-Петербурга представлены учреждения культуры нашего города с описанием уровня доступности их физических пространств, ресурсов и коллекций для людей с различными проблемами здоровья.',
		},
		ABOUT_PAGE: {
		  HEADER: 'О проекте',
		  TEXT: 'Данный проект, реализуемый Санкт-Петербургской государственной специальной центральной библиотекой для слепых и слабовидящих, представляет собой систематизированные материалы по доступности учреждений в Санкт-Петербурге.',
		},
		ORGANIZATIONS_PAGE: {
		  HEADER: 'Организации',
		  TEXT: 'На этой странице проекта в алфавитном порядке представлены организации культуры Санкт-Петербурга, при переходе по ссылкам на которые можно получить детальную информацию об уровне доступности учреждения для различных категорий пользователей с пробелами здоровья.',
		},
		LOCATIONS_PAGE: {
		  HEADER: 'Адреса',
		  TEXT: 'Адреса учреждений культуры Санкт-Петербурга представлены в виде алфавитного списка в качестве дополнительного источника для поиска нужной информации. При переходе по ссылке доступна полная информация об уровне доступности учреждения для пользователей с проблемами здоровья.',
		},
		PERSONS_PAGE: {
		  HEADER: 'Люди',
		  TEXT: 'Описание раздела Люди Описание раздела Люди Описание раздела Люди Описание раздела Люди Описание раздела Люди Описание раздела Люди Описание раздела Люди Описание раздела Люди Описание раздела Люди Описание раздела Люди Описание раздела Люди ',
		},
	  },

	ABOUT_PAGE: [""],
	
	ORGANIZATION_PAGE: {
		INFO:"Мероприятия",
		LOCATIONS: "Адрес",
		CATEGORIES: "",
		ALT_NAMES: "Другие названия",
		PERSONS: "Связи с людьми",
		ORGANIZATIONS: "Связи с другими организациями",
		LINKS: "Контакты"
	},

	PERSON_PAGE: {
		INFO: "Справка",
		LINKS:"Читаем по теме",
		PERSONS: "Связи с людьми",
		ORGANIZATIONS: "Связи с организациями",
	},

	LOCATION_PAGE: {
		INFO: "Доступность",
		DATES: "Даты постройки",
		ALT_NAMES: "Другие названия",
		PERSONS: "Архитекторы",
		LINKS: "Читаем по теме",
		ORGANIZATIONS: "В здании находятся"
	},

	CONTACTS:{
		ADDRESS: {
			TEXT:"Санкт-Петербург, ул.Стрельнинская, дом 11",
			LINK: "https://yandex.ru/maps/-/CDFfZ87-"
		},
		PHONE: {
			TEXT:"(812) 417-52-42",
			LINK:"tel:+78124175242"
		},
		EMAIL: {
			TEXT:"kvochkina@gscbs.ru",
			LINK:"mailto:kvochkina@gscbs.ru"
		},
		WEBSITE: {
			TEXT:"www.gbs.spb.ru",
			LINK:"https://www.gbs.spb.ru"
		}
	}
}
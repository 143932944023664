export function paginate<T>(list: T[], currentPage: number, pageSize: number): T[] {
  return list.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize);
}

export function slicer(pagesList: number[], currentPage: number): any {
  if (pagesList.length <= 8) {
    return pagesList;
  } else {
    if (pagesList.length - currentPage <= 4) {
      return pagesList.slice(pagesList.length - 8, pagesList.length);
    }
    if (currentPage <= 4) {
      return pagesList.slice(0, 8);
    }
    if (currentPage > 4 && pagesList.length - currentPage > 4) {
      return pagesList.slice(currentPage - 4, currentPage + 4);
    }
  }
}

import React from "react";

interface IMenuLocationsSVG {
  color: string;
}

const MenuLocationsSVG = ({ color }: IMenuLocationsSVG) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.0221 26.7088C16.4801 27.2163 15.7555 27.5 15.0014 27.5C14.2472 27.5 13.5228 27.2163 12.9806 26.7088C8.01628 22.0325 1.36345 16.8086 4.60784 9.22458C6.36204 5.12395 10.5729 2.5 15.0014 2.5C19.4299 2.5 23.6408 5.12395 25.395 9.22458C28.6353 16.7991 21.9987 22.0486 17.0221 26.7088Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M19.375 13.75C19.375 16.1662 17.4162 18.125 15 18.125C12.5838 18.125 10.625 16.1662 10.625 13.75C10.625 11.3338 12.5838 9.375 15 9.375C17.4162 9.375 19.375 11.3338 19.375 13.75Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default MenuLocationsSVG;

import React from 'react';
import { useData } from '../hooks/useContext';
import { IUseData } from '../interfaces';

import AboutSVG from './SVG/big/about';
import AboutPersonsSVG from './SVG/big/aboutPersons';
import LocationsSVG from './SVG/big/locations';
import LocationsPersonsSVG from './SVG/big/locationsPersons';
import MapSVG from './SVG/big/map';
import MapPersonsSVG from './SVG/big/mapPersons';
import OrganizationsSVG from './SVG/big/organizations';
import OrganizationsPersonsSVG from './SVG/big/organizationsPersons';
import PersonsSVG from './SVG/big/persons';

import { CONFIG } from '../config';

const PageCard = () => {
  const { page, theme } = useData() as IUseData;

  const getHeader = () => {
    switch (page) {
      case 'map':
        return CONFIG.PAGES_CARDS.MAP_PAGE.HEADER;
      case 'organizations':
        return CONFIG.PAGES_CARDS.ORGANIZATIONS_PAGE.HEADER;
      case 'locations':
        return CONFIG.PAGES_CARDS.LOCATIONS_PAGE.HEADER;
      case 'persons':
        return CONFIG.PAGES_CARDS.PERSONS_PAGE.HEADER;
      case 'about':
        return CONFIG.PAGES_CARDS.ABOUT_PAGE.HEADER;
    }
  };

  const getText = () => {
    switch (page) {
      case 'map':
        return CONFIG.PAGES_CARDS.MAP_PAGE.TEXT;
      case 'organizations':
        return CONFIG.PAGES_CARDS.ORGANIZATIONS_PAGE.TEXT;
      case 'locations':
        return CONFIG.PAGES_CARDS.LOCATIONS_PAGE.TEXT;
      case 'persons':
        return CONFIG.PAGES_CARDS.PERSONS_PAGE.TEXT;
      case 'about':
        return CONFIG.PAGES_CARDS.ABOUT_PAGE.TEXT;
    }
  };

  const getSVG = () => {
    if (CONFIG.BLUE_OR_GREEN_DESIGN) {
      switch (page) {
        case 'map':
          return <MapSVG />;
        case 'organizations':
          return <OrganizationsSVG />;
        case 'locations':
          return <LocationsSVG />;
        case 'about':
          return <AboutSVG />;
      }
    } else {
      switch (page) {
        case 'map':
          return <MapPersonsSVG />;
        case 'organizations':
          return <OrganizationsPersonsSVG />;
        case 'locations':
          return <LocationsPersonsSVG />;
        case 'persons':
          return <PersonsSVG />;
        case 'about':
          return <AboutPersonsSVG />;
      }
    }
  };

  return (
    <div className={'w-full flex flex-row rounded-[40px] justify-between mt-10' + theme.active}>
      <div className="p-10">
        <div className="text-white text-5xl">{getHeader()}</div>
        <div className="text-white mt-5">{getText()}</div>
      </div>
      <div className="hidden md:block self-end pe-10">{getSVG()}</div>
    </div>
  );
};

export default PageCard;

import React from "react";

const LocationsSVG = () => {
  return (
    <svg width="284" height="214" viewBox="0 0 284 214" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_971)">
        <path
          d="M36.58 220.92V190.5H44.31V183.17H56.38V149.75H68.46V157.08H75.22V178.68H91.64V166.86H99.36V177.46H120.14V163.19H149.14V183.19H157.36V172.59H155.42V170.14H171.36V172.14H167.5V175.81H180V166.03H185.31V135.48H198.31V147.3H208V114.3H218.62V168.9H229.73V182.35H237.46V189.28H252.46V220.92"
          stroke="white"
          strokeMiterlimit="10"
        />
        <path d="M59.02 159.11V157.5H60.63V159.11H59.02Z" fill="#141B34" stroke="white" />
        <path d="M64.5 159.11V157.5H66.0776L66.1023 159.11H64.5Z" fill="#141B34" stroke="white" />
        <path d="M59.02 164.5V162.93H60.63V164.5H59.02Z" fill="#141B34" stroke="white" />
        <path d="M64.5 164.5V162.93H66.0777L66.1022 164.5H64.5Z" fill="#141B34" stroke="white" />
        <path d="M70.33 164.5V162.93H71.94V164.5H70.33Z" fill="#141B34" stroke="white" />
        <path d="M70.33 169.979V168.369H71.94V169.979H70.33Z" fill="#141B34" stroke="white" />
        <path d="M70.33 175.42V173.81H71.94V175.42H70.33Z" fill="#141B34" stroke="white" />
        <path d="M70.33 180.86V179.25H71.94V180.86H70.33Z" fill="#141B34" stroke="white" />
        <path d="M70.33 186.299V184.689H71.94V186.299H70.33Z" fill="#141B34" stroke="white" />
        <path d="M70.33 191.74V190.13H71.94V191.74H70.33Z" fill="#141B34" stroke="white" />
        <path d="M59.02 169.979V168.369H60.63V169.979H59.02Z" fill="#141B34" stroke="white" />
        <path d="M64.5 169.979V168.369H66.0776L66.1023 169.979H64.5Z" fill="#141B34" stroke="white" />
        <path d="M59.02 175.42V173.81H60.63V175.42H59.02Z" fill="#141B34" stroke="white" />
        <path d="M64.5 175.42V173.81H66.0776L66.1023 175.42H64.5Z" fill="#141B34" stroke="white" />
        <path d="M59.02 180.86V179.25H60.63V180.86H59.02Z" fill="#141B34" stroke="white" />
        <path d="M64.5 180.86V179.25H66.0776L66.1023 180.86H64.5Z" fill="#141B34" stroke="white" />
        <path d="M59.02 186.299V184.689H60.63V186.299H59.02Z" fill="#141B34" stroke="white" />
        <path d="M47.05 189.349V187.739H48.66V189.349H47.05Z" fill="#141B34" stroke="white" />
        <path d="M84.7 183.5V181.86H86.31V183.5H84.7Z" fill="#141B34" stroke="white" />
        <path d="M89.92 183.5V181.86H91.5V183.5H89.92Z" fill="#141B34" stroke="white" />
        <path d="M210.27 119.499V117.869H211.88V119.499H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 119.499V117.869H215.142L215.118 119.499H213.5Z" fill="#141B34" stroke="white" />
        <path d="M210.27 124.929V123.319H211.88V124.929H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 124.929V123.319H215.142L215.118 124.929H213.5Z" fill="#141B34" stroke="white" />
        <path d="M210.27 130.37V128.76H211.88V130.37H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 130.37V128.76H215.142L215.118 130.37H213.5Z" fill="#141B34" stroke="white" />
        <path d="M210.27 135.809V134.199H211.88V135.809H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 135.809V134.199H215.142L215.118 135.809H213.5Z" fill="#141B34" stroke="white" />
        <path d="M210.27 141.25V139.64H211.88V141.25H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 141.25V139.64H215.142L215.118 141.25H213.5Z" fill="#141B34" stroke="white" />
        <path d="M210.27 146.689V145.079H211.88V146.689H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 146.689V145.079H215.142L215.118 146.689H213.5Z" fill="#141B34" stroke="white" />
        <path d="M210.27 152.11V150.5H211.88V152.11H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 152.11V150.5H215.142L215.118 152.11H213.5Z" fill="#141B34" stroke="white" />
        <path d="M210.27 157.57V155.96H211.88V157.57H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 157.57V155.96H215.142L215.118 157.57H213.5Z" fill="#141B34" stroke="white" />
        <path d="M210.27 163.009V161.399H211.88V163.009H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 163.009V161.399H215.142L215.118 163.009H213.5Z" fill="#141B34" stroke="white" />
        <path d="M188.07 163.009V161.399H189.69V163.009H188.07Z" fill="#141B34" stroke="white" />
        <path d="M193.73 163.009V161.399H195.34V163.009H193.73Z" fill="#141B34" stroke="white" />
        <path d="M199.39 163.009V161.399H201V163.009H199.39Z" fill="#141B34" stroke="white" />
        <path d="M188.07 174.769V173.159H189.69V174.769H188.07Z" fill="#141B34" stroke="white" />
        <path d="M193.73 174.769V173.159H195.34V174.769H193.73Z" fill="#141B34" stroke="white" />
        <path d="M199.39 174.769V173.159H201V174.769H199.39Z" fill="#141B34" stroke="white" />
        <path d="M188.07 186.5V184.9H189.69V186.5H188.07Z" fill="#141B34" stroke="white" />
        <path d="M193.73 186.5V184.9H195.34V186.5H193.73Z" fill="#141B34" stroke="white" />
        <path d="M199.39 186.5V184.9H201V186.5H199.39Z" fill="#141B34" stroke="white" />
        <path d="M210.27 168.5V166.85H211.88V168.5H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 168.5V166.85H215.142L215.117 168.5H213.5Z" fill="#141B34" stroke="white" />
        <path d="M210.27 173.889V172.279H211.88V173.889H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 173.889V172.279H215.142L215.118 173.889H213.5Z" fill="#141B34" stroke="white" />
        <path d="M210.27 179.339V177.729H211.88V179.339H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 179.339V177.729H215.142L215.118 179.339H213.5Z" fill="#141B34" stroke="white" />
        <path d="M218.98 173.889V172.279H220.59V173.889H218.98Z" fill="#141B34" stroke="white" />
        <path d="M225.08 173.889V172.279H226.69V173.889H225.08Z" fill="#141B34" stroke="white" />
        <path d="M218.98 179.11V177.5H220.59V179.11H218.98Z" fill="#141B34" stroke="white" />
        <path d="M225.08 179.11V177.5H226.69V179.11H225.08Z" fill="#141B34" stroke="white" />
        <path d="M218.98 184.339V182.729H220.59V184.339H218.98Z" fill="#141B34" stroke="white" />
        <path d="M225.08 184.339V182.729H226.69V184.339H225.08Z" fill="#141B34" stroke="white" />
        <path d="M218.98 189.569V187.949H220.59V189.569H218.98Z" fill="#141B34" stroke="white" />
        <path d="M225.08 189.569V187.949H226.69V189.569H225.08Z" fill="#141B34" stroke="white" />
        <path d="M210.27 184.78V183.17H211.88V184.78H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 184.78V183.17H215.142L215.118 184.78H213.5Z" fill="#141B34" stroke="white" />
        <path d="M210.27 190.22V188.61H211.88V190.22H210.27Z" fill="#141B34" stroke="white" />
        <path d="M213.5 190.22V188.61H215.142L215.118 190.22H213.5Z" fill="#141B34" stroke="white" />
        <path d="M64.5 186.299V184.689H66.0776L66.1023 186.299H64.5Z" fill="#141B34" stroke="white" />
        <path d="M59.02 191.74V190.13H60.63V191.74H59.02Z" fill="#141B34" stroke="white" />
        <path d="M64.5 191.74V190.13H66.0776L66.1023 191.74H64.5Z" fill="#141B34" stroke="white" />
        <path d="M59.02 197.179V195.569H60.63V197.179H59.02Z" fill="#141B34" stroke="white" />
        <path d="M64.5 197.179V195.569H66.0776L66.1023 197.179H64.5Z" fill="#141B34" stroke="white" />
        <path d="M59.02 202.62V201.01H60.63V202.62H59.02Z" fill="#141B34" stroke="white" />
        <path d="M64.5 202.62V201.01H66.0776L66.1023 202.62H64.5Z" fill="#141B34" stroke="white" />
        <path d="M204.11 202.15V212.92H182.35V191.51H204.11V200.23" stroke="white" strokeWidth="0.57" strokeMiterlimit="10" />
        <path d="M244.16 209.66V212.92H222.4V201.6H226.89" stroke="white" strokeWidth="0.57" strokeMiterlimit="10" />
        <path d="M229.54 201.6H244.16V207.9" stroke="white" strokeWidth="0.57" strokeMiterlimit="10" />
        <path d="M83.75 201.6H99.22V212.92H77.45V201.6H81.69" stroke="white" strokeWidth="0.57" strokeMiterlimit="10" />
        <path d="M49.7 201.6H51.77V212.92H43.72V201.6H48.08" stroke="white" strokeWidth="0.57" strokeMiterlimit="10" />
        <path d="M195 191.51H191.52V212.92H195V191.51Z" stroke="white" strokeWidth="0.57" strokeMiterlimit="10" />
        <path d="M167.92 194.42H164.51V188.76H176V194.42H171" stroke="white" strokeWidth="0.57" strokeMiterlimit="10" />
        <path d="M106.83 184.84H93.56V189.63H106.83V184.84Z" stroke="white" strokeWidth="0.57" strokeMiterlimit="10" />
        <path d="M101.17 184.84H99.87V212.92H101.17V184.84Z" stroke="white" strokeWidth="0.57" strokeMiterlimit="10" />
        <path
          d="M141.9 5.30957C122.481 5.30957 103.857 13.0238 90.1257 26.7552C76.3942 40.4866 68.68 59.1104 68.68 78.5296C68.68 115.05 136.22 201.13 136.22 201.13H141.9C141.9 201.13 215.12 119 215.12 78.5296C215.12 59.1104 207.406 40.4866 193.674 26.7552C179.943 13.0238 161.319 5.30957 141.9 5.30957ZM141.9 114.31C133.009 114.318 124.433 111.019 117.841 105.054C111.248 99.0889 107.11 90.8848 106.231 82.0376C105.353 73.1903 107.797 64.3327 113.088 57.1879C118.379 50.043 126.139 45.122 134.858 43.382C143.577 41.6421 152.631 43.2077 160.259 47.7743C167.888 52.3409 173.545 59.5819 176.129 68.0886C178.714 76.5953 178.042 85.7594 174.244 93.798C170.446 101.837 163.793 108.175 155.58 111.58C151.242 113.372 146.594 114.293 141.9 114.29V114.31Z"
          fill="#141B34"
          stroke="#141B34"
          strokeWidth="0.57"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M136.22 5.30957C116.801 5.30957 98.1771 13.0238 84.4456 26.7552C70.7142 40.4866 63 59.1104 63 78.5296C63 118.96 136.22 201.13 136.22 201.13C136.22 201.13 209.44 119 209.44 78.5296C209.443 68.9134 207.551 59.391 203.872 50.5064C200.193 41.6217 194.8 33.549 188 26.7494C181.201 19.9497 173.128 14.5565 164.243 10.8778C155.359 7.19904 145.836 5.30695 136.22 5.30957ZM136.22 114.31C127.947 114.31 119.93 111.442 113.534 106.194C107.139 100.947 102.76 93.6437 101.146 85.5298C99.5307 77.416 100.779 68.9932 104.678 61.6966C108.577 54.3999 114.885 48.6808 122.528 45.5137C130.171 42.3466 138.675 41.9274 146.592 44.3276C154.509 46.7277 161.35 51.7987 165.947 58.6765C170.545 65.5543 172.615 73.8135 171.806 82.0468C170.997 90.2802 167.358 97.9783 161.51 103.83C158.188 107.149 154.245 109.781 149.906 111.576C145.566 113.371 140.916 114.293 136.22 114.29V114.31Z"
          fill="#D2DAFA"
          stroke="#141B34"
          strokeWidth="0.57"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_971">
          <rect width="284" height="214" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocationsSVG;
